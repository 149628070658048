import { SET_USER, SET_ACCOUNT } from './types';

export default {
  /**
   * Set logged in user
   */
  [SET_USER](state, user) {
    state.user = user;
  },

  [SET_ACCOUNT](state, account) {
    state.account = account;
  },

  setFrillWidgetUnreadCount(state, frillWidgetUnreadCount) {
    state.frillWidgetUnreadCount = frillWidgetUnreadCount;
  },

  setDropMenuOpen(state, dropMenuOpen) {
    state.dropMenuOpen = dropMenuOpen;
  },

  setFromLink(state, fromLink) {
    state.fromLink = fromLink;
  },

  clearFromLink(state) {
    state.fromLink = null;
  },
};
