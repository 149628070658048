import merge from 'deepmerge';
import base from './base';

/**
 * Production environment configuration
 */
export default merge.all([base, {
  app: {
    baseUrl: 'https://app.controlla.io',
  },

  api: {
    baseUrl: 'https://api.controlla.io',
  },

  sentry: {
    dsn: `https://66cbd7c299bc404db77f9e0711b5bd8f@o912788.ingest.sentry.io/5850132`,
  },

  engage: {
    publicKey: '6e88718f079a07874bd5e8ab1cb47a17',
  },

  stripe: {
    publicKey: 'pk_live_btxWQxUIC2PKF9KjBj15qdYX00COR8AmZo',
  },
}]);
