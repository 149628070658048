<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderWorkspace',

  computed: {
    ...mapState({
      accountName: state => state.session?.account?.name,
    }),
  },
};
</script>

<template>
  <div class="ContentWrap">
    <div class="HeaderBar">
      <div class="SectionTitleAndTabs Container">
        <menu class="PageHeaderTabs">
          <router-link class="PageHeader-tab--first" to="/workspace/dashboard">
            <i class="Icon-Header">space_dashboard</i>
            {{$t('header-workspace_overview.dashboard')}}
          </router-link>
          <router-link class="PageHeader-tab" to="/workspace/services"><i
            class="Icon-Header">autorenew</i>{{$t('header-workspace_overview.contracts')}}
          </router-link>
          <router-link class="PageHeader-tab" to="/workspace/assets"><i
            class="Icon-Header">widgets</i>{{ $t('header-workspace_overview.assets')}}</router-link>
          <router-link class="PageHeader-tab" to="/workspace/offerings">
            <i class="Icon-Header">local_offer</i>
            {{$t('header-workspace_overview.offerings')}}
          </router-link>
        </menu>
        <div class="AccountMeta--wrap">
          <div class="AccountTitle">{{accountName}}</div>
        </div>
      </div>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>
