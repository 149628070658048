import http from '@/services/http';

export default {
  upcomingInvoice(account) {
    return http
      .get('/billing/upcomingInvoice', account);
  },

  paymentIntents(account) {
    return http
      .get('/billing/paymentIntents', account);
  },
};
