import vueModal from 'vue-js-modal';
import modal from '@/services/modal';

const Plugin = {
  install(Vue, options) {

    //First, install the vue modal plugin and pass through the options
    Vue.use(vueModal, options);

    //Get defaults from options
    const { defaults } = options;

    //Configure service
    modal.setDefaults(defaults);
    modal.setService(Vue.prototype.$modal);

    //Overwrite service now
    Vue.$modal = modal;
  },
};

export default Plugin;
