import http from '@/services/http';
import Offering from '@/models/offering.model';

export default {
  query(filter) {
    return http
      .get('/offering', filter)
      .then(data => Offering.createFrom(data, null, 'offerings'));
  },

  list(filter) {
    return http
      .get('/offering/list', filter)
      .then(offerings => Offering.createFrom(offerings));
  },

  summary(filter) {
    return http
      .get('/offering/summary', filter);
  },

  findById(id) {
    return http
      .get(`/offering/${id}`)
      .then(data => Offering.createFrom(data));
  },

  create(data) {
    return http
      .post(`/offering`, data);
  },

  update(id, data) {
    return http
      .put(`/offering/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/offering/${id}`);
  },
};
