<script>

import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      subscriptionStatus: state => state.session?.account?.subscriptionStatus,
    }),
  },
};
</script>

<template>
  <div class="Page-Menu">
    <router-link class="PageMenuItem--first" to="/account">
      {{$t('account_account-menu.menu_overview')}}
    </router-link>
    <router-link class="PageMenuItem" to="/account/billing">
      {{$t('account_account-menu.menu_billing')}}
    </router-link>
    <router-link
      class="PageMenuItem"
      to="/account/billing-history">
      {{$t('account_account-menu.menu_billing_history')}}
    </router-link>
  </div>
</template>
