<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm">
      <div class="PortalForm-Content">
        <div class="FormLogoWrap">
          <img
            class="Sponsor-logo Sponsor-logoIcon"
            src="@/assets/logo.svg"
            alt="Controlla"
          >
        </div>
        <h1>{{$t('forgot_password.forgot_password')}}</h1>
        <div v-if="isSent">
          <div class="Section">
            {{$t('forgot_password.email_potentially_sent')}}
          </div>
          <div class="Section">
            <div class="SpacerL"></div>
            <router-link class="Button--primary" to="/login">{{$t('forgot_password.back_to_login')}}</router-link>
            <div class="SpacerS"></div>
          </div>
        </div>
        <div v-else>
          <div>{{$t('forgot_password.enter_email_address_reason')}}</div>
          <div class="Group">
            <input
              v-model="email"
              type="text"
              class="Input-100"
              :placeholder="$t('forgot_password.email_address')"
            >
            <div class="InputHint" v-if="$v.email.$error">
              <div v-if="!$v.email.required">{{ $t('login_login.email_required') }}</div>
              <div v-if="!$v.email.email">{{ $t('login_login.invalid_email') }}</div>
            </div>
          </div>
          <div class="Group">
            <button
              class="Button--forgotPassword"
              @click="request()"
              :disabled="isSending"
            >
              {{$t('forgot_password.reset_button')}}
            </button>
          </div>
        </div>
      </div>
      <div class="PortalForm-Links">
        <router-link to="/login">{{$t('forgot_password.back_to_login')}}</router-link> |
        <router-link to="/register">{{$t('forgot_password.create_account')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import UserApi from '@/api/user.api';

export default {
  data() {
    return {
      email: '',
      isSending: false,
      isSent: false,
    };
  },

  validations: {
    email: {
      required,
      email,
    },
  },

  methods: {
    async request() {
      const { $v, email } = this;

      $v.$touch();
      if ($v.$invalid) {
        return;
      }

      this.isSending = true;

      await UserApi
        .requestPasswordResetEmail(email)
        .then(() => this.isSent = true)
        .finally(() => this.isSending = false);
    },
  },
};
</script>
