/**
 * Dependencies
 */
import billingOptions from '@/constants/billing-options';

/**
 * Billing options filter
 */
export default function(value) {

  //Find corresponding item
  const item = billingOptions.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
