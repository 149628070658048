<script>
import IntegrationApi from '@/api/integration.api';
import IntegrationTypes from '@/constants/integration-types';

export default {
  name: 'IntegrationsSearch',

  data() {
    return {
      integrations: [],
      map: {},
      isLoading: false,
    };
  },

  computed: {
    available() {
      return Object.keys(IntegrationTypes)
        .filter(key => !this.isConnected(key))
        .map(key => ({
          type: key,
          name: IntegrationTypes[key],
        }));
    },

    integrationLogos() {
      return this.available.reduce((A, { type }) => {
        let source = null;
        const extensions = ['svg', 'png', 'jpg', 'gif'];

        extensions.forEach(ext => {
          try {
            source = require(`@/assets/images/integrations/${type}.${ext}`);
          }
          catch (err) {
            if (err.code !== 'MODULE_NOT_FOUND') {
              console.error('got error', err.code, type, ext);
              throw (err);
            }
          }
        });
        A[type] = source;
        return A;
      }, {});
    },
  },

  created() {
    this.loadIntegrations();
  },

  methods: {
    async loadIntegrations() {
      this.isLoading = true;
      this.integrations = await IntegrationApi
        .query()
        .finally(() => this.isLoading = false);
      this.createIntegrationsMap();
    },

    createIntegrationsMap() {
      this.map = this.integrations.reduce((map, integration) => {
        map[integration.type] = integration;
        return map;
      }, {});
    },

    isConnected(type) {
      const { map } = this;
      return (map[type] && map[type].isConnected);
    },

    goTo(type) {
      this.$router.push({ path: `/integrations/${type}` });
    },
  },
};

</script>

<template>
  <div class="Page">
    <div class="PageContents">
      <div class="Container">
        <div v-if="isLoading">
          <spinner class="Spinner--general" />
        </div>

        <div v-if="!isLoading" class="Integrations Integrations-current">
          <h1 class="Title">{{$t('integration.all')}}</h1>
          <div class="Blocks-Integrations-Wrap">
            <div class="Block-Integration" v-for="integration in available" :key="integration.type"
              @click="goTo(integration.type)">
              <div class="Block-Header">
                <div class="Block-Title-Text">
                  <img v-if="integrationLogos[integration.type]" class="Block-image"
                    :src="integrationLogos[integration.type]" alt="">
                  {{integration.name}}
                </div>
              </div>
              <div class="Block-Content">
                <p>{{ $t(`integration.${integration.type}.teaser`) }}</p>
                <button class="Button--block">{{ $t("integration.view_details") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>