<script>
import { mapState } from 'vuex';
import ModalConfirm from '@/components/shared/modal-confirm';

export default {
  props: {
    experience: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),
  },

  methods: {
    hideOnboarding() {
      const onConfirm = async () => {
        this.user.experiences[this.experience] = false;
        await this.user.updateOwn(this.user);
      };

      this.$modal.show(
        ModalConfirm,
        {
          _modalTitle: this.$t('shared_overview-onboarding.hide_guide_title'),
          _modalMessage: this.$t('shared_overview-onboarding.hide_guide_message'),
          _buttonText: this.$t('shared_overview-onboarding.hide_guide_button'),
          onConfirm,
        },
        { maxWidth: 600 },
      );
    },
  },
};

</script>

<template>
  <div v-if="user.experiences[experience]" class="Section-Onboarding">
    <div @click="hideOnboarding" class="Click-hide--Section-Onboarding"><i class="Icon-Hide">close</i></div>

    <slot />
  </div>
</template>
