<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/shared/modal-edit';
import ModelSearch from '@/components/shared/model-search';
import ContactApi from '@/api/contact.api';
import Contact from '@/models/contact.model';
import cfg from '@/services/cfg';

export default {
  name: 'CreditCardEdit',

  components: { ModalEdit, ModelSearch },

  data() {
    return {
      ContactApi,
      types: [],
      model: {
        contact: null,
        card: null,
        nameOnCard: null,
        automaticPaymentEnabled: false,
      },
      original: {},
      isSaving: false,
      wantsToChangeCard: false,
      cardError: null,
    };
  },

  props: {
    item: { type: Object },
    contacts: { type: Array },
    onSave: { type: Function },
    onRemove: { type: Function },
    isEdit: { type: Boolean },
  },

  validations: {
    model: {
      contact: { required },
      nameOnCard: { required },
    },
  },

  computed: {
    hasCard() {
      return !!this.model.card;
    },

    isDisabledConfirm() {
      return _.isEqual(this.model, this.original);
    },
  },

  created() {
    //Create model subset
    this.model = this.item.extract(Object.keys(this.model));
    this.original = _.cloneDeep(this.model);
  },

  mounted() {
    this.setupStripeService();
  },

  methods: {
    selectContact($event) {
      if ($event.option && $event.option.createNew) {
        const newContact = new Contact();
        newContact.name = $event.value;
        newContact.type = 'person';
        return this.model.contact = newContact;
      }
      this.model.contact = $event.value;
    },

    setupStripeService() {
      //Load service for global public key
      this.stripe = this.$stripe.service(cfg.stripe.publicKey);

      //Mount card element on next tick, unless we already have a card
      if (!this.hasCard) {
        this.$nextTick(() => {
          this.mountCardElement();
        });
      }
    },

    /**
     * Change existing card
     */
    changeCard() {
      this.wantsToChangeCard = true;

      //Mount card element on next tick
      this.$nextTick(() => {
        this.mountCardElement();
      });
    },

    mountCardElement() {
      //Wrap to capture errors as card errors
      try {
        //Create card element
        this.card = this.stripe
          .elements()
          .create('card');

        this.card.addEventListener('change', ({ error }) => {
          this.cardError = error ? error.message : '';
        });

        this.card.mount(this.$refs.card);
      }
      catch (error) {
        this.cardError = error.message;
      }
    },

    async onClickSave(model) {
      //Wrap in try request
      if (this.hasCard && !this.wantsToChangeCard) {
        return this.onSave(model);
      }
      try {
        const { card } = this;
        const { token, error } = await this.stripe.createToken(card);

        if (error) {
          throw error;
        }
        if (this.cardError || !token) {
          return;
        }
        //Append token to model
        model.card = token.card;
        model.token = token;
        return this.onSave(model);
      }
      catch (error) {
        //Ignore actual exceptions
        if (error instanceof Error) {
          return;
        }

        //Set card error
        this.cardError = error.message;
        return Promise.reject(error);
      }
      finally {
        this.isSaving = false;
        this.wantsToChangeCard = false;
      }
    },
  },
};
</script>

<template>
  <modal-edit singular="credit card" :model="model" :validation="$v" :is-edit="isEdit" :on-save="onClickSave"
    OFF:on-remove="onRemove" @close="$emit('close')" :isDisabledSave="$v.$invalid"
    :isDisabledConfirm="isDisabledConfirm">
    <div class="Form">
      <div class="Group">
        <div class="Modal--item">
          <label :for="$htmlID('contact')" class="Label">{{$t('credit-cards_modals_edit.client')}}</label>
          <model-search :id="$htmlID('contact')" :model="model.contact" :api="ContactApi"
            @change="selectContact($event)" />
          <div class="InputHint" v-if="$v.model.contact.$error">
            <div v-if="!$v.model.contact.required">{{ $t('credit-cards_modals_edit.contact_required') }}</div>
          </div>
        </div>
      </div>
      <div class="Group Card-Group" :class="{ 'Group--error': $v.model.nameOnCard.$error }">
        <div class="Modal--item">
          <label :for="$htmlID('nameOnCard')" class="Label">{{ $t('credit-cards_modals_edit.card_details') }}</label>
          <input :id="$htmlID('nameOnCard')" class="Input-100 " type="text"
            :placeholder="$t('credit-cards_modals_edit.name_on_card')" v-model.trim="model.nameOnCard">
          <div class="InputHint" v-if="$v.model.nameOnCard.$error">
            <div v-if="!$v.model.nameOnCard.required">{{ $t('credit-cards_modals_edit.name_required') }}</div>
          </div>
        </div>
      </div>
      <div class="Modal--item" v-if="hasCard && !wantsToChangeCard">
        <div class="Card--infoIndent">
          {{ model.card.brand }}
          ***{{ model.card.last4 }}
          <small>
            | <a @click="changeCard">{{ $t('credit-cards_modals_edit.change_card') }}</a>
          </small>
        </div>
      </div>
      <div class="Modal--item" v-if="!hasCard || wantsToChangeCard">
        <div v-if="hasCard" class="Label Label--inline">{{ $t('credit-cards_modals_edit.new_card') }}:</div>
        <div class="Input-100 ">
          <div ref="card"></div>
        </div>
        <div class="InputHint" v-if="cardError">
          <div>{{ cardError }}</div>
        </div>
      </div>
      <div class="Modal--item">
        <check-box :model="model.automaticPaymentEnabled" @change="x => model.automaticPaymentEnabled = x.value">
          {{ $t('credit-cards_modals_edit.client_authorized') }}
        </check-box>
      </div>
    </div>
  </modal-edit>
</template>
