<script>

// This file was made by DFM with autopilot

export default {
  props: ['value'],
  computed: {
    formattedValue() {
      if (!this.value) {
        return '';
      }
      let dollars = Math.floor(this.value);
      let cents = ((this.value - dollars).toFixed(2)).substring(2);
      return `<span class="dollars">$${dollars}</span><span class="cents">.${cents}</span>`;
    },
  }
}

</script>

<template>
  <span v-html="formattedValue"></span>
</template>
