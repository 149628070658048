import merge from 'deepmerge';
import base from './base';

/**
 * Development environment configuration
 */
export default merge.all([base, {

  app: {
    baseUrl: 'http://localhost:8080',
    // baseUrl: 'https://controlla.local:8080',
  },

  api: {
    baseUrl: 'http://localhost:8081/api',
    // baseUrl: 'https://controlla.local:8443/api',
  },

  stripe: {
    publicKey: `pk_test_QH5RfVUSwOPoL0BWilG2WVJn00jBfc0T13`,
  },

  engage: {
    publicKey: '6e88718f079a07874bd5e8ab1cb47a17',
  },

  //Error handling
  errors: {
    log: true,
  },
}]);
