import http from '@/services/http';
import Contract from '@/models/contract.model';

export default {
  query(filter) {
    return http
      .get('/contract', filter)
      .then(data => Contract.createFrom(data, null, 'contracts'));
  },

  list(filter) {
    return http
      .get('/contract/list', filter)
      .then(contracts => Contract.createFrom(contracts));
  },

  summary(filter) {
    return http
      .get('/contract/summary', filter);
  },

  findById(id) {
    return http
      .get(`/contract/${id}`)
      .then(data => Contract.createFrom(data));
  },

  create(data) {
    return http
      .post('/contract', data);
  },

  update(id, data) {
    return http
      .put(`/contract/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/contract/${id}`);
  },

  archive(id) {
    return http
      .post(`/contract/${id}/archive`);
  },

  unArchive(id) {
    return http
      .post(`/contract/${id}/unarchive`);
  },
};
