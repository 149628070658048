
//Basic
export const BACKSPACE = 8;
export const TAB = 9;
export const CLEAR = 12;
export const ENTER = 13;
export const ESC = 27;
export const SPACE = 32;

//Navigation
export const PAGEUP = 33;
export const PAGEDOWN = 34;
export const END = 35;
export const HOME = 36;

//Arrows
export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;

//Insert/delete
export const INSERT = 45;
export const DELETE = 46;

//Modifiers
export const SHIFT = 16;
export const CTRL = 17;
export const ALT = 18;
export const BREAK = 19;
export const CAPSLOCK = 20;
export const CMDLEFT = 91;
export const CMDRIGHT = 93;

//Special characters
export const COMMA = 188;
export const COLON = 190;
export const DASH = 189;
export const EQUALS = 187;
export const QUOTE = 222;
export const BACK_TICK = 192;
export const AT = 50;

//Combined character keycode
export const COMBINED = 229;

//Numeric keys (character codes)
export const NUMERIC = [
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57, //0-9
];

//Alpha keys (character codes)
export const ALPHA = [
  65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, //a-m
  78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, //n-z
];

//Control keys
export const CONTROL = [
  8, 9, 12, 13, 27, //backspace, tab, clear, enter, escape
  35, 36, 45, 46, //end, home, insert, delete
  37, 38, 39, 40, //left, up, right, down
];

/**
 * Helper to check if a keyboard event was for a control key
 */
export function isControl(event, allowed) {
  const codes = mergeCodes(CONTROL, allowed);
  return (codes.indexOf(event.keyCode) >= 0 && event.charCode === 0);
}

/**
 * Helper to check if a keyboard event was for numeric input
 */
export function isNumeric(event, allowed) {
  const codes = mergeCodes(NUMERIC, allowed);
  return (codes.indexOf(event.which) >= 0);
}

/**
 * Helper to check if a keyboard event was for alpha input
 */
export function isAlpha(event, allowed) {
  const codes = mergeCodes(ALPHA, allowed);
  return (codes.indexOf(event.which) >= 0);
}

/**
 * Helper to check if a keyboard event was for alphanumeric input
 */
export function isAlphaNumeric(event, allowed) {
  const codes = mergeCodes(ALPHA, NUMERIC, allowed);
  return (codes.indexOf(event.which) >= 0);
}

/**
 * Internal helper to merge codes
 */
export function mergeCodes(...args) {
  return args.reduce((merged, arg) => {
    if (!arg) {
      return merged;
    }
    return merged.concat(Array.isArray(arg) ? arg : [arg]);
  });
}
