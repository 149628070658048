export const WEEKS = 'weeks';
export const TWO_WEEKS = 'two_weeks';
export const MONTHS = 'months';
export const QUARTERS = 'quarters';
export const YEARS = 'years';

export default [
  // {
  //   value: WEEKS,
  //   label: `Weekly`,
  // },
  // {
  //   value: TWO_WEEKS,
  //   label: `2 weekly`,
  // },
  {
    value: MONTHS,
    label: `Monthly`,
  },
  // {
  //   value: QUARTERS,
  //   label: `Quarterly`,
  // },
  {
    value: YEARS,
    label: `Yearly`,
  },
];
