export const NEVER = 'never';
export const END_DATE = 'endDate';
export const NUM_CYCLES = 'numCycles';

export default [
  {
    value: NEVER,
    label: `Never`,
  },
  // {
  //   value: END_DATE,
  //   label: `On a set date`,
  // },
  {
    value: NUM_CYCLES,
    label: `After # of cycles`,
  },
];