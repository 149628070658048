<script>
import { mapState } from 'vuex';
import AppHeader from './header/header';
import AppFooter from './footer/footer';

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  computed: {
    ...mapState({
      main_menu_open: state => state.session?.user?.experiences?.main_menu_open,
      account: state => state.session.account,
      isLoaded: state => state.page.isLoaded,
    }),
  },

  created() {
    // close any modal on back button
    window.addEventListener('popstate', () => {
      this.$modal.hideAll();
    });
  },

  metaInfo() {
    const capitaliseWords = str => str.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    const title = this.$route?.meta?.i18n?.pageTitle 
      ? `${this.$t(this.$route.meta.i18n.pageTitle)} | Controlla` 
      : this.$route?.name 
        ? capitaliseWords(this.$route.name) + ' | Controlla' 
        : 'Controlla';
    return { title };
  },

  methods: {
    /**
     * Set currency globally
     */
    setCurrency() {
      //Get currency data and set in filter
      const { symbol } = this.account.currency;
      let currencyConfig = this.$currency.getConfig();
      currencyConfig.symbol = symbol;
      this.$currency.setConfig(currencyConfig);
    },
  },
};
</script>

<template>
  <div>
    <div class="AppLoader" v-if="!isLoaded">
      <img src="../../assets/controlla-icon-gray.svg" class="Spinner--controlla" />
    </div>
    <div :class="{
      AppContent: true,
      'Menu-Open': main_menu_open,
      'Menu-Closed': !main_menu_open,
    }">
      <app-header />
      <div class="MainContent">
        <router-view />
        <app-footer />
      </div>
      <notices-container />
    </div>
  </div>
</template>

<style lang="scss">

.AppContent {
  display: flex;
  width: 100%;
  height: 100vh;
  display: flex;
}

.authenticated .AppContent {
  align-items: normal;
}

.MainContent {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.ContentWrap {}

.AppLoader {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $colorWhite;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
