
/**
 * Mutations for the Page state
 */
export default {
  setPageTitle(state, title) {
    state.title = title;
  },

  clearPageTitle(state) {
    state.title = '';
  },

  setPageFilter(state, filter) {
    state.filter = filter;
  },

  clearPageFilter(state) {
    state.filter = '';
  },

  /**
   * Set loaded state
   */
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded;
  },
};
