import recurrenceIntervals from '@/constants/recurrence-intervals';

/**
 * Recurrence interval filter
 */
export default function(value) {

  //Find corresponding item
  const item = recurrenceIntervals.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
