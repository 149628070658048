<template>
  <div class="AppLoader">
    <img src="../../assets/controlla-icon-gray.svg" class="Spinner--controlla" />
  </div>
</template>

<script>
import auth from '@/services/auth';

export default {
  name: 'OAuthCallback',

  beforeRouteEnter(to, from, next) {

    //Get query params
    const {
      access_token: accessToken,
      action,
      redirect,
    } = to.query;

    //Got access token, login
    if (accessToken) {

      //Connecting existing user? Go to profile
      if (action === 'connect') {
        //TODO
      }

      if (redirect === 'undefined') {
        auth.loginWithToken(accessToken);
      }
      else {
        auth.loginWithToken(accessToken, redirect);
      }
      return next();
    }

    //Action given, profile data from oAuth registration flow
    if (action === 'register') {
      const profile = to.query;
      return next({name: 'register', params: {profile}});
    }

    //All other cases, go back to login state
    next({name: 'login'});
  },
};
</script>
