export const DRAFT = 'draft';
export const CONFIRMED = 'confirmed';
export const ON_HOLD = 'onHold';

export default [
  {
    value: DRAFT,
    label: `Draft`,
  },
  {
    value: CONFIRMED,
    label: `Confirmed`,
  },
  {
    value: ON_HOLD,
    label: `On hold`,
  },
];
