
/**
 * Setup page data
 */
export const setup = async ({commit}, data) => {

  //Get data
  const {title, filter} = data;

  //Commit
  commit('setPageTitle', title);
  commit('setPageFilter', filter);
};

/**
 * Clear page data
 */
export const clear = async ({ commit }) => {

  //Clear data
  commit('clearPageTitle');
  commit('clearPageFilter');
};

/**
 * Flag as loaded
 */
export const loaded = async ({ commit }, isLoaded) => {
  commit('setLoaded', isLoaded);
};
