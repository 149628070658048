<script>
import ModalEdit from '@/components/shared/modal-edit';
import {required, email} from 'vuelidate/lib/validators';

export default {
  components: {
    ModalEdit,
  },

  data() {
    return {
      model: {
        role: null,
        email: null,
        accept_user_charge: false,
      },
    };
  },

  props: {
    user: { type: Object },
    onSave: { type: Function },
    RoleTypes: { type: Array },
    freeUsersLimitReached: { type: Boolean },
  },

  validations: {
    model: {
      role: { required },
      email: { required, email },
      accept_user_charge: { required },
    },
  },
};
</script>

<template>
  <modal-edit
    singular="user"
    :model="model"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <label class="Label">{{$t('user_invite.role')}}</label>
      <select-box
        :model="model.role"
        :options="RoleTypes"
        @change="model.role = $event.value"
      />
      <div class="InputHint" v-if="$v.model.role.$error">
        <div v-if="!$v.model.role.required">{{$t('user_invite.role_is_required')}}</div>
      </div>

      <div class="Group" :class="{'Group--error': $v.model.email.$error}">
        <label :for="$htmlID('email')" class="Label">{{$t('user_invite.email')}}</label>
        <input
          :id="$htmlID('email')"
          class="Input-100"
          type="email"
          v-model.trim="model.email"
        >
        <div class="InputHint" v-if="$v.model.email.$error">
          <div v-if="!$v.model.email.required">{{$t('user_invite.email_address_is_required')}}</div>
          <div v-if="!$v.model.email.email">{{$t('user_invite.invalid_email_address')}}</div>
        </div>
      </div>

      <div
        v-if="freeUsersLimitReached"
        class="Group"
        :class="{'Group--error': $v.model.accept_user_charge.$error}
      ">
        <div class="Message-Info">
          <div class="Confirmation-Check--wrap">
            <input
              v-model="model.accept_user_charge"
              type="checkbox"
              class="Input-Checkbox OfferingsCheckBox"
              value="accept_user_charge"
              id="accept_user_charge"
            />
            <div>
              <label class="Label-Checkbox" for="accept_user_charge">
                {{$t('user_invite.confirm_payment_for_user')}}
              </label>
            </div>
          </div>
        </div>
        <div class="InputHint" v-if="$v.model.accept_user_charge.$error">
          <div v-if="$v.model.accept_user_charge.required">{{$t('user_invite.accept_user_charge')}}</div>
        </div>
      </div>
    </div>
  </modal-edit>
</template>

<style lang="scss">
  .Confirmation-Check--wrap {
    display: flex;
    gap: $gapXL;
  }

</style>