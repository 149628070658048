<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import AssetApi from '@/api/asset.api';
import ContractApi from '@/api/contract.api';
import ModalView from '@/components/shared/modal-view';
import { ORGANISATION } from '@/constants/contact-types';
import calcInclAmount from '@/helpers/calc-incl-amount';
import InvoiceList from '@/components/shared/invoice-list';

export default {
  components: { ModalView, InvoiceList },

  props: {
    contact: { type: Object },
    onEdit: { type: Function },
    onArchive: { type: Function },
  },

  data() {
    return {
      ORGANISATION,
      assets: [],
      assetsVisibility: [],
      contracts: [],
      numMonthlyContracts: null,
      numYearlyContracts: null,
      totalMonthlyContractsAmount: null,
      totalYearlyContractsAmount: null,
      totalYearlyandMonthlyContractsAmount: null,
      contractsLoading: false,
      assetsLoading: false,
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),

    nextCycleCountdown: function() {
      const nextDate = moment(this.contract.nextInvoiceDate);
      const nextDatePlusOne = nextDate.add(1, 'day');
      const now = moment();
      const diff = nextDatePlusOne.diff(now, 'days');
      return diff === 0 ? 'Today' :
        diff === 1 ? 'Tomorrow' :
          'in ' + diff + ' days';
    },

    nextCycleDate: function() {
      const nextCycleDate = moment(this.contract.nextInvoiceDate);
      return nextCycleDate;
    },

    singularInterval: function() {
      return this.$root.$options.filters.inflect(this.contract.recurrence.interval, 'singularize');
    },

    totalValueInclTax: function() {
      return calcInclAmount(this.contract.totalValue, this.account.taxPercentage);
    },
  },

  async created() {
    const contact = this.contact.id;

    if (!contact) {
      return;
    }

    this.contractsLoading = true;
    this.assetsLoading = true;
    AssetApi
      .query({ contact })
      .then(({ assets }) => {
        this.assets = assets;
        this.assetsLoading = false;
        this.assetsVisibility = assets.map(() => false);
      });
    ContractApi
      .query({ contact, status: 'active' })
      .then(({ contracts }) => {
        this.contracts = contracts;
        this.numMonthlyContracts = contracts.filter(contract => contract.recurrence.interval === 'months').length;
        this.numYearlyContracts = contracts.filter(contract => contract.recurrence.interval === 'years').length;
        this.totalMonthlyContractsAmount = contracts
          .filter(contract => contract.recurrence.interval === 'months')
          .reduce((total, contract) => total + contract.totalValue, 0);
        this.totalYearlyContractsAmount = contracts
          .filter(contract => contract.recurrence.interval === 'years')
          .reduce((total, contract) => total + contract.totalValue, 0);
        this.totalYearlyandMonthlyContractsAmount = (this.totalMonthlyContractsAmount * 12) + this.totalYearlyContractsAmount;
        this.contractsLoading = false;
      });
  },
};
</script>

<template>
  <modal-view :item="contact" :singular="contact.name"
    :byline="contact.type === ORGANISATION ? this.$t('directory_modals_client-view.organization') : this.$t('directory_modals_client-view.person')"
    :icon="contact.type === ORGANISATION ? 'groups' : 'person'" :on-edit="contact.archived ? null : onEdit"
    :iconFilled="true"
    :saveable="false" @close="$emit('close')">
    <tabs :options="{ useUrlFragment: false }">
      <tab name='Overview'>
        <div class="Section">
          <div class="Modal-col--one">
            <div v-if="contact.type === ORGANISATION">
              <div class="Modal--item">
                <label class="Label">{{ $t('directory_modals_client-view.billing_contact_name') }}</label>
                <div  v-if="(contact.contactPerson)" class="Modal-Value">
                  {{ contact.contactPerson }}
                </div>
                <span v-else class="Modal-Value--exception">
                  <i class="Icon-Base--warning">error_outline</i>
                  <a @click="onEdit">{{$t('directory_modals_client-view.add_billing_contact')}}</a>
                </span>
              </div>
            </div>
            <div v-else>
              <div class="Modal--item">
                <label class="Label">{{ $t('directory_modals_client-view.billing_contact_name') }}</label>
                <div class="Modal-Value">
                  {{ contact.name }}
                </div>
              </div>
            </div>
            <div class="Modal--item">
              <label class="Label">{{ $t('directory_modals_client-view.billing_email') }}</label>
              <div v-if="(contact.email)" class="Modal-Value">{{ contact.email }}</div>
              <div v-else class="Modal-Value--exception">
                <i class="Icon-Base--warning">error_outline</i>
                <a @click="onEdit">{{$t('directory_modals_client-view.add_billing_email')}}</a>
              </div>
            </div>
          </div>

          <div class="Modal-col--one" v-if="contact.creditCard">
              <div class="Modal--item">
                <label class="Label">{{ $t('directory_modals_client-view.credit_card') }}</label>
                <div class="Modal--value">
                  **** {{ contact.creditCard.card.last4 }}
                  exp {{contact.creditCard.card.exp_month}}/{{contact.creditCard.card.exp_year.toString().substr(-2)}}
                </div>
              </div>
          </div>

          <div class="SpacerXL" />

          <div class="Client-Stat--wrap">
            <div class="Client-Stat">
              <span :class="{ 'Client-Stat--notSet': contact.assetCount === 0 }">
                {{ contact.assetCount }}
              </span>
              <span class="Label--caps">{{$tc('global.asset_plural',contact.assetCount)}}</span> 
            </div>
            <div class="Client-Stat">
              <span :class="{'Client-Stat--notSet': contact.contractCount === 0 }">
                {{ contact.contractCount }}
              </span>
              <span class="Label--caps">{{$tc('global.service_plural',contact.contractCount)}}</span> 
            </div>
            <div class="Client-Stat">
              <span v-if="contractsLoading || contact.contractCount === 0" class="Value-Placeholder">...</span>
              <currency-styler v-else :value="totalYearlyandMonthlyContractsAmount"></currency-styler>
              <span class="Label"> {{ $t('directory_modals_client-view.annualized_revenue') }}</span>
            </div>
            <div class="Client-Stat">
              <span v-if="contractsLoading || contact.contractCount === 0" class="Value-Placeholder">...</span>
              <currency-styler v-else :value="totalYearlyandMonthlyContractsAmount / 12"></currency-styler>
              <span class="Label"> {{ $t('directory_modals_client-view.average_monthly') }}</span>
            </div>
          </div>
          </div>

          <div class="SpacerL"></div>
          <div v-if="numYearlyContracts || numMonthlyContracts" class="Modal-Panel Client-Position">
            <div v-if="numYearlyContracts" class="Tally-Stat Tally-Stat--exists">
              <span class="Tally-Number">{{ numYearlyContracts }}</span>
              {{$tc('directory_modals_client-view.yearly_services_return_plural', numYearlyContracts ) }}
              <span class="Tally-Number"><currency-styler :value="totalYearlyContractsAmount"></currency-styler> + {{$t('global.tax')}}</span> {{$t('directory_modals_client-view.per_year')}}
            </div>
            <div v-if="numMonthlyContracts" class="Tally-Stat Tally-Stat--exists"> 
              <span class="Tally-Number">{{ numMonthlyContracts }}</span>
              {{$tc('directory_modals_client-view.monthly_services_return_plural', numMonthlyContracts ) }}
              <span class="Tally-Number">{{ totalMonthlyContractsAmount | currency }} + {{$t('global.tax')}}</span> {{$t('directory_modals_client-view.per_month')}}
            </div>
          </div>
      </tab>

      <tab :name='`Services <span class="TabCount--neutral">${contact.contractCount || 0}</span>`'>
        <div class="Section">
          <div class="LabelButton-Wrap">
            <!-- <label class="Tally-Stat Tally-Stat--exists" v-if="(contact.contractCount)">
              {{ $t('directory_modals_client-view.client_has') }}
              <span class="Tally-Number">{{ contact.contractCount}} </span>  <span>{{ $tc('global.service_plural', contact.contractCount) }}</span>
            </label>
            <label class="Label" v-else>{{ $t('directory_modals_client-view.clients_services') }}</label> -->

            <modal-link
              v-if="account.readyForContracts"
              class="Button--cta Button--small"
              :to="`/workspace/services/add?clientId=${contact.id}&clientName=${contact.name}`"
              :from="`/directory/clients/view/${contact.id}`"
              fromName="client"
            >
              {{ $t('directory_modals_client-view.add_contract') }}
            </modal-link>
          </div>
          <div class="SpacerL"></div>
          <div class="SpacerL"></div>
          <spinner v-if="contractsLoading" />
          <div v-else-if="contracts.length > 0">
            <div v-for="(contract, index) in contracts" :key="index" class="Row-0">
              <div class="Modal-Panel">
                <div class="ItemM">
                  <div class="Item-Icon">
                    <icons-services :period="contract.recurrence?.interval" />
                  </div>
                  <div class="Item-TextWrap">
                    <modal-link class="Item-Headline" :to="`/workspace/services/view/${contract.id}`"
                      :from="`/directory/clients/view/${contact.id}`" fromName="client">{{ contract.name }}
                    </modal-link>
                    <div class="Description">
                      {{ contract.recurrence?.interval | recurrenceInterval }}, {{ contract.totalValue | currency }} + {{$t('global.tax')}}
                    </div>
                  </div>
                  <div class="Panel-ContractRevenue">
                    <div>{{ contract.totalValue | currency }} + {{$t('global.tax')}}</div>
                    <div class="Description">{{ contract.recurrence.interval | recurrenceInterval }}</div>
                  </div>
                </div>
                  <div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="!contracts.length">
            <div class="Row-0">
              <div class="Modal-Panel">
                <div class="ModalList--noItems">{{ $t('directory_modals_client-view.no_contracts') }} </div>
              </div>
            </div>
          </div>
        </div>
      </tab>

      <tab :name='`Assets <span class="TabCount--neutral">${contact.assetCount || 0}</span>`'>
        <div class="Section">
          <div class="LabelButton-Wrap">
            <!-- <label class="Tally-Stat Tally-Stat--exists" v-if="(contact.assetCount)">
              {{ $t('directory_modals_client-view.client_has') }}
              <span class="Tally-Number" v-if="(contact.assetCount > 1)">{{ contact.assetCount }}</span> {{ $tc('global.asset_plural', contact.assetCount ) }}
            </label>
            <label class="Label" v-else>{{ $t('directory_modals_client-view.clients_assets') }}</label> -->
            <modal-link
              class="Button--cta Button--small"
              :to="`/workspace/assets/add?clientId=${contact.id}&clientName=${contact.name}`"
              :from="`/directory/clients/view/${contact.id}`"
              fromName="client"
            >
              {{ $t('directory_modals_client-view.add_asset') }}
            </modal-link>
          </div>
          <div class="SpacerL"></div>
          <div class="SpacerL"></div>
          <spinner v-if="assetsLoading" />
          <div v-else-if="assets.length > 0" >
            <div class="Assets-Grid">
              <div v-for="(asset, index) in assets" :key="index" class="Row-0">
                <div class="Modal-Panel--small">
                  <div class="Panel-Header">
                    <div class="ItemM">
                      <icon class="Item-Icon" :name="asset.type.icon || asset.type.name || asset.type.icon" />
                      <div class="Item-TextWrap">
                        <modal-link class="Item-Headline" :to="`/workspace/assets/view/${asset.id}`" :from="`/directory/clients/view/${contact.id}`" fromName="client">
                          {{ asset.name }}
                        </modal-link>
                        <div class="Description">
                          {{ asset.type.name }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <span class="Inactive-Value" v-if="(asset.contracts.length == 0)">{{
                        $t('directory_modals_client-view.0_contract') }}
                      </span>
                      <span class="Active-Value"  v-else-if="asset.contracts.length >= 1">
                        <div v-if="!assetsVisibility[index]">{{ asset.contracts.length }} <span>{{ $tc('global.service_plural', asset.contracts.length )}}</span></div>
                        <div v-else>{{ asset.contracts.length }} <span>{{ $tc('global.service_plural', asset.contracts.length ) }}</span></div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="!assets.length">
            <div class="Row-0">
              <div class="Modal-Panel">
                <div class="ModalList--noItems">{{ $t('directory_modals_client-view.no_assets') }}</div>
              </div>
            </div>
          </div>
        </div>
      </tab>

      <tab name="Invoices">
        <div class="Section">
          <InvoiceList
            :query="{
              contact: contact.id,
              sort: '-number',
            }"
            :modalFromLink="`/workspace/clients/view/${contact.id}`"
          />
        </div>
      </tab>
    </tabs>
  </modal-view>
</template>

<style lang="scss" >

.Modal-Panel.Client-Position {
  display: flex;
  padding: $spacingXL;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacingM;
}

.Client-Stat--wrap {
  display: grid;
  grid-template-columns: repeat(2, 0.75fr) repeat(2, 1.5fr);
  gap: $spacingL;
}

.Client-Stat {
  @include app-panel;
  padding: $spacingS $spacingL $spacingXS;
  background: $colorBgInfo;
  font-size: $fontSizeXL;
  font-weight: $fontWeightBold;
  text-align: center;
  .Client-Stat--notSet {
    color: $colorValueNotSet;
  }
  .Label,
  .Label--caps {
    text-align: center;
  }
  .cents {
    font-size: 75%;
  }
}

.Active-Value {
  font-weight: $fontWeightMedium;
}

.Inactive-Value {
  color: $colorValueNotSet;
}

.Assets-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: $spacingXL;
}

.Panel-ContractRevenue {
  margin-left: auto;
  .Description {
    text-align: right;
  }
}

.PanelTitle-Link {
  font-size: $fontSizeS;
  margin-left: $spacingM;
  align-self: center;
  font-weight: $fontWeightNormal;
  white-space: nowrap;

  a {
    white-space: nowrap;
  }
}
</style>
