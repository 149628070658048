import moment from 'moment';
import BaseModel from './base.model';
import InvoiceApi from '@/api/invoice.api';
import Contact from './contact.model';
import Payment from './payment.model';
import {DRAFT, SENT, PAID, VOID} from '@/constants/invoice-statuses';

export default class Invoice extends BaseModel {

  getModelName() {
    return 'Invoice';
  }

  /**
   * Status flags
   */
  get isDraft() {
    return (this.status === DRAFT);
  }
  get isSent() {
    return (this.status === SENT);
  }
  get isPaid() {
    return (this.status === PAID);
  }
  get isVoid() {
    return (this.status === VOID);
  }

  /**
   * Overdue flag
   */
  get isOverdue() {
    return moment().isAfter(this.dueDate);
  }

  /**
   * From JSON conversion
   */
  fromJSON(json) {

    //Call parent method
    super.fromJSON(json);

    //Convert models
    this.convertToModel('contact', Contact);
    this.convertToModel('payment', Payment);

    return this;
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    //Create
    return InvoiceApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    return InvoiceApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;

    return InvoiceApi
      .remove(id);
  }

  send() {

    //Get ID
    const {id} = this;

    //Mark as paid
    return InvoiceApi
      .send(id)
      .then(data => this.fromJSON(data));
  }

  markPaid(data) {
    const {id} = this;

    return InvoiceApi
      .markPaid(id, data)
      .then(data => this.fromJSON(data));
  }

  /**
   * Pay invoice
   */
  pay(data) {
    const {id} = this;
    const {method} = data;

    return InvoiceApi
      .pay(id, method, data)
      .then(data => {

        //Re-load self with new data
        this.fromJSON(data.invoice);

        //Pass on data
        return data;
      });
  }
}
