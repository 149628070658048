import ClientError from './client';

/**
 * Not authorized error
 */
export default class NotAuthorizedError extends ClientError {
  constructor(message, data) {

    //Parent constructor
    super(message, data, 403);
  }
}
