/**
 * Dependencies
 */
import contractStatuses from '@/constants/contract-statuses';

export default function(value) {

  //Find corresponding item
  const item = contractStatuses.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
