<script>

export default {
  name: 'ConfirmModal',

  data() {
    return {
      isError: false,
      isRemoving: false,
    };
  },

  props: {
    onConfirm: { type: Function },
    _modalTitle: {
      type: String,
      // default: () => this.$t('shared_modal-confirm.confirmation_title'),
    },
    _modalMessage: {
      type: String,
      // default: () => this.$t('shared_modal-confirm.confirmation_message'),
    },
    _buttonText: {
      type: String,
      // default: () => this.$t('shared_modal-confirm.confirmation_button'),
    },
    _buttonType: {
      type: String,
      default: 'primary',
    },
  },

  methods: {
    remove() {
      //Toggle flags
      this.isRemoving = true;
      this.isError = false;

      //Call handler
      this
        .onConfirm()
        .then(() => this.$emit('close'));
    },
  },
};
</script>

<template>
  <div class="Modal--dialog">
    <div class="Modal-Header">
      <h1>{{_modalTitle}}</h1>
      <i
        class="Icon-ModalsClose"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <slot />
      <div v-if="_modalMessage" class="ModalMessage"><span v-html="_modalMessage"></span></div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isRemoving"
        :label-confirm="_buttonText"
        :type-confirm="_buttonType"
        @cancel="$emit('close')"
        @confirm="remove"
      >
      </button-bar>
    </div>
  </div>
</template>
