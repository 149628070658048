import Vue from 'vue';
import Engage from '@engage_so/js';
import cfg from '@/services/cfg';

Engage.init(cfg.engage.publicKey);

Vue.mixin({
  beforeCreate() {
    this.$engage = Engage;
  }
});

export default Engage;
