
/**
 * Mark matching text with HTML tags
 */
export default function(text, match, tag = 'strong') {
  let regex;
  if (typeof match === 'object') {
    regex = match;
  }
  else {
    regex = new RegExp('(?:^|\\b)(' + match + ')', 'i');
  }
  return String(text).replace(regex, '<' + tag + '>$1</' + tag + '>');
}
