<script>
import PaymentApi from '@/api/payment.api';
import OverviewOnboarding from '@/components/shared/overview-onboarding.vue';
import PaymentView from './modals/view';

export default {
  components: { OverviewOnboarding },

  data() {
    return {
      //Pagination
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,

      filter: null,

      //Data
      payments: [],

      //Flags
      isLoading: false,
    };
  },

  computed: {
    hasItems() {
      return this.payments.length > 0;
    },
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  async created() {
    this.setupFilter();
    this.setupPage();
    await this.loadPayments();
    this.processAction();
  },

  methods: {
    setupFilter() {
      this.filter = this.$filter.get('payments');
      this.filter.setDefaults({
        search: '',
        sort: 'status',
        isActive: true,
      });

      this.filter.loadValues(this.$route.query);

      this.filter.onChange(() => {
        this.loadPage(1);
      });
    },

    setupPage() {
      const { filter } = this;
      this.$store.dispatch('page/setup', { filter });
    },

    view(payment) {
      const { account } = this;

      this.$modal.show(
        PaymentView,
        { account, item: payment},
        { maxWidth: 1000 },
        {
          opened: () => this.$router.push({ name: 'payments', params: { action: 'view', id: payment.id }}),
          closed: () => this.$router.replace({ name: 'payments' }),
        }
      );
    },

    loadPage(page) {
      this.page = page;
      this.loadPayments(true);
    },

    async loadPayments() {
      this.isLoading = true;
      const filter = this.makeFilter();

      await PaymentApi
        .query(filter)
        .then((data) => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    makeFilter(extra) {
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processData(data) {
      const { meta, payments } = data;
      this.payments = payments;
      this.totalItems = meta.total;
    },

    processAction() {
      const { action, id } = this.$route.params;

      if (action === 'view') {
        const payment = this.payments.find(p => p.id === id);
        this.view(payment);
      }
    },
  },
};
</script>

<template>
  <div class="Page">
    <page-meta
      type="payment.title"
      
      :filter="filter"
      :total-items="totalItems"
      :showAddButton="false"
      :num-pages="numPages"
      :page="page"
      :load-page="loadPage"
      >
      <template slot="settings">
        <setting-options>
          <li>
            <router-link to="/workspace/assets/assetTypes"><i class="Icon-Base">widgets</i>{{$t('shared_settings-options.asset_types')}}</router-link>
          </li>
          <li>
            <router-link to="/integrations"><i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}</router-link>
          </li>
          <hr />
          <li>
            <a :href="$t('shared_settings-options.help_payments_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_payments')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>
    </page-meta>

    <div class="PageContents">
      <div class="Container">
        <div class="Table">
          <overview-onboarding experience="payments_overview_onboarding">
            <div class="Section-Onboarding--Img">
              <div class="Spacer2XL"></div>
              <i class="Icon-Base">toll</i>
              <!-- <img src="@/assets/images/onboard/toll.svg" alt="Payments Onboarding"> -->
            </div>
            <div class="Section-Onboarding--Content">
              <h2 class="OnboardingTitle">{{$t('onboarding_payments.payments_title')}}</h2>
              <div class="OnboardingIntro">
                {{$t('onboarding_payments.payments_intro')}}
              </div>
              <p>
                <router-link to="/integrations/stripe">{{$t('onboarding_payments.connect_stripe')}}</router-link>
                {{$t('onboarding_payments.in_order_to')}}
              </p>
              <i18n path="onboarding_payments.read_more.text" tag="p" class="Section-Onboarding--Guidelink">
                <template v-slot:url>
                  <a :href="$t('onboarding_payments.read_more.link')" target="_blank"
                    :title="$t('onboarding_payments.read_more.title')">
                    {{$t('onboarding_payments.read_more.urltext')}}</a><i class="Icon-External">launch</i>
                </template>
              </i18n>
            </div>
          </overview-onboarding>

          <div class="Table-header">
            <div class="Table-col--M">
              <sort :sort="filter.sort" field="name" @toggle="filter.update('sort', $event.sort)">
                {{$t('global.client')}}
              </sort>
            </div>
            <div class="Table-col--S">{{$t('payment.amountPaid')}}</div>
            <div class="Table-col--S">
              <sort :sort="filter.sort" field="payment.status" @toggle="filter.update('sort', $event.sort)">
                {{$t('payment.status')}}</sort>
            </div>
            <div class="Table-col--S">{{$t('payment.method')}}</div>
            <div class="Table-col--S">{{$t('payment.date')}}</div>
            <div class="Table-col--L">{{$t('payment.reference')}}</div>
          </div>

          <div v-if="isLoading && !hasItems">
            <spinner class="Spinner--general" />
          </div>
          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{$t('global.table_no_results') }}
          </div>

          <div v-if="hasItems">
            <div v-for="payment in payments" :key="payment.id" class="Table-row">
              <div class="Table-col--M Table-col--primary Item-Headline" @click="view(payment)">
                <span class="">
                  {{payment.contact ? payment.contact.name : undefined}}
                </span>
              </div>
              <div class="Table-col--S">{{payment.amountPaid | currency}}</div>
              <div class="Table-col--S">
                <span v-if="payment.status === 'paid'" class="">
                  <i class="Icon-neutral">done</i> {{$t('payment.payment_ok')}}
                </span>
                <span v-if="payment.status === 'failed'">
                  <i class="Icon Pill--danger">close</i> {{$t('payment.failed')}}
                </span>
              </div>
              <div class="Table-col--S">
                <img v-if="payment.method == 'stripe'" src="@/assets/images/integrations/stripe.png" title="Stripe Logo"
                  alt="Webflow Website" class="PaymentIcon" />
                <i v-if="payment.method == 'bankTransfer'" class="Icon-Base">back_hand</i>
                <span v-if="payment.method == 'bankTransfer'" class="">{{$t('payment.manual')}}</span>
                <span v-if="payment.method == 'stripe'" class="">Stripe</span>
              </div>
              <div class="Table-col--S">{{payment.date | moment('ll')}}</div>
              <div class="Table-col--L">{{payment.reference}}</div>
            </div>
          </div>

          <pagination :show-all="totalItems < 500" :page="page" class="Pagination--lower" :num-pages="numPages"
            @change="loadPage($event.page)"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
