<script>
import { mapState } from 'vuex';
import BillingApi from '@/api/billing.api';
import AccountMenu from './account-menu';

export default {
  name: 'AccountBilling',

  components: { AccountMenu },

  data() {
    return {
      upcomingInvoice: null,
      paymentIntents: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),
  },

  async created() {
    this.isLoading = true;
    await this.loadUpcomingInvoice();
    await this.loadPaymentHistory();
    this.isLoading = false;
  },

  methods: {
    async loadUpcomingInvoice() {
      if (this.account.subscriptionStatus === 'active') {
        await BillingApi.upcomingInvoice()
          .then(upcomingInvoice => this.upcomingInvoice = upcomingInvoice);
      }
    },

    async loadPaymentHistory() {
      if (this.account.subscriptionStatus === 'active') {
        await BillingApi.paymentIntents()
          .then(paymentIntents => this.paymentIntents = paymentIntents);
      }
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--sidemenu">
      <div class="Menu--col">
        <account-menu />
      </div>

      <div class="Content--mediumWidth">
        <h1 class="PageTitle">{{$t("account_billing-history.title")}}</h1>

        <div v-if="isLoading">
          <spinner class="Spinner--general" />
        </div>

        <div v-else>
          <div class="GroupSet" v-if="upcomingInvoice">
            <div class="Label Label--inline">{{ $t("account_billing-history.upcoming_charges") }}</div>
            <p v-if="upcomingInvoice.ending_balance">
              {{$t("account_billing-history.credit_balance_of")}} {{
                -upcomingInvoice.ending_balance / 100 | currency
              }}
              {{ upcomingInvoice.currency.toUpperCase() }}
            </p>
            <p v-else>
              {{$t("account_billing-history.next_charge")}}
              {{ upcomingInvoice.amount_remaining / 100 | currency }}
              {{ upcomingInvoice.currency.toUpperCase() }} {{$t("global.on")}}
              <span class="nobr">{{
                (upcomingInvoice.next_payment_attempt * 1000)
                  | moment("D MMMM YYYY")
              }}</span
              >.
            </p>
          </div>

          <div class="GroupSet" v-if="paymentIntents?.length > 0">

            <div class="TablePlain PaymentHistory">
              <div v-for="intent in paymentIntents" :key="intent.id" class="Table-row">
                  <div class="Table-col--S date">
                    {{ (intent.created * 1000) | moment("D MMM YYYY") }}
                  </div>
                  <div class="Table-col--S amount">
                    ${{ intent.amount_received / 100 }}
                    {{ intent.currency.toUpperCase() }}
                  </div>
                  <div class="Table-col--S notes">
                    {{ intent.description }} {{ intent.status }}
                    {{ intent.cancellation_reason }}
                  </div>
              </div>
            </div>
          </div>

          <div v-if="paymentIntents?.length === 0 && !upcomingInvoice">
            No subscription yet
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.Card--infoIndent {
  padding-left: $spacingM;
  padding-top: 0.25rem;
  margin-top: 0.25rem;
  &:not(:first-child) {
    margin-top: 0;
  }
}

.nobr {
  white-space: nowrap;
}

.PaymentHistory {
  margin-top: $spacingM;
  list-style: none;
  li {
    margin-bottom: $spacingM;
    display: flex;
    .date {
      width: 7rem;
    }
    .amount {
      width: 7rem;
    }
  }
}

.TrialNotice {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: $fontSizeXL;
}
</style>
