import http from '@/services/http';
import Country from '@/models/country.model';

export default {
  query(filter) {
    return http
      .get('/country', filter)
      .then(countries => Country.createFrom(countries));
  },

  findByCode(code) {
    return http
      .get(`/country/${code}`)
      .then(data => Country.createFrom(data));
  },
};
