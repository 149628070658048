<script>
export default {
  data() {
    return {
      isBusy: false,
      isVisible: false,
    };
  },

  mounted() {
    this.boundClickHandler = this.clickHandler.bind(this);
    document.addEventListener('click', this.boundClickHandler);
  },

  destroyed() {
    document.removeEventListener('click', this.boundClickHandler);
  },

  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },

    clickHandler(event) {
      const { trigger } = this.$refs;
      const { target } = event;
      if (target !== trigger && target.parentNode !== trigger) {
        this.isVisible = false;
      }
    },
  },
};
</script>

<template>
  <div class="ContextMenu">
    <spinner class="Spinner--general" v-if="isBusy"></spinner>
    <div class="ContextMenu-trigger" :class="{ 'is-open': isVisible }" @click="toggle" v-if="!isBusy" ref="trigger">
      <i class="Icon-More">more_vert</i>
    </div>
    <menu class="ContextMenu-content" v-if="isVisible">
      <slot />
    </menu>
  </div>
</template>

<style lang="scss">
.ContextMenu {
  position: relative;
  /* for some reason this is required to make the context menu work */
}

.ContextMenu-trigger {
  color: $colorText;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  &.is-open,
  &:hover {
    background: $colorBlackFaded3;
    cursor: pointer;
    .Icon-More {
      color: $colorText;
    }
  }
}

.PageMeta {
  .ContextMenu {
    position: relative;
    right: -10px;
  }

  .ContextMenu-content {
    left: -6px;
    width: 250px;
    padding: $spacingS 0;
    top: 30px;
    a {
      .Icon-Base {
        font-size: 22px;
      }
    }
  }
  .ContextMenu-trigger {
    background: $colorBlackFaded3;
    &:hover {
      background: $colorBlackFaded2;
    }
  }
  .ContextMenu-trigger .Icon-More {
    color: $colorText;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    font-size: 25px;
    margin-right: 0;
  }
}

.Table .ContextMenu-trigger .Icon-More {
  padding: 2px;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.ContextMenu-ModalEdit {
  margin-right: auto;
}

.ContextMenu-content {
  background-color: $colorLightestGray;
  box-shadow: 0px $spacingS $spacingL 0px rgba(0, 0, 0, 0.1);
  border-radius: $borderRadiusS;
  border: 1px solid $colorBordersNormal;
  margin-top: $spacingS;
  padding: $spacingS 0;
  position: absolute;
  z-index: 100;
  top: 100%;
  font-size: $fontSizeS;

  a {
    display: flex;
    gap: $gapL;
    margin: 0;
    padding:$spacingS $spacingXL;
    color: inherit;
    text-decoration: none;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .Icon-Base {
      color: $colorTextFaded1;
    }

    &:hover {
      background-color: $colorBlackFaded3;
    }

    &:hover .Icon-Base {
      color: $colorText;
    }
  }
}

.Contract-Edit--ContextMenu .ContextMenu-content {
  top: 80%;
  right: 0;
}

.ContextMenu-item--danger {
  a {
    color: $colorTextDanger;
  }
}

.ContextMenu-item--warning {
  a {
    color: $colorTextWarning;
  }
}
</style>
