import BaseModel from './base.model';
import { PENDING, FAILED, CANCELLED, UNKNOWN, PAID } from '@/constants/payment-statuses';

export default class Payment extends BaseModel {

  getModelName() {
    return 'Payment';
  }

  /**
   * Status flags
   */
  get isPending() {
    return (this.status === PENDING);
  }
  get isFailed() {
    return (this.status === FAILED);
  }
  get isPaid() {
    return (this.status === PAID);
  }
  get isCancelled() {
    return (this.status === CANCELLED);
  }
  get isUnknown() {
    return (this.status === UNKNOWN);
  }
}
