import http from '@/services/http';
import Notification from '@/models/notification.model';

export default {
  query(filter) {
    return http
      .get('/notification', filter)
      .then(data => Notification.createFrom(data, null, 'notifications'));
  },

  list(filter) {
    return http
      .get('/notification/list', filter)
      .then(notifications => Notification.createFrom(notifications));
  },

  patch(id, property, value) {
    return http
      .patch(`/notification/${id}/${property}`, {
        [property]: value,
      });
  },
};
