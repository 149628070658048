<template>
  <div
    class="InputWrapper is-clickable"
    @click="showDatePicker"
  >
    <input
      readonly
      :disabled="isDisabled"
      :placeholder="dateFormat"
      class="Input-100 Input--date"
      type="text"
      v-model="dateFormatted"
    >
    <span class="Input-icon">
      <i class="Icon Icon-Date">date_range</i>
    </span>
  </div>
</template>

<script>
import ModalDatePicker from './modal-date-picker';
import { dateFormatStandard } from '@/constants/date-formats';

export default {
  data() {
    return {
      dateFormatted: '',
    };
  },

  props: {
    date: { type: [Object, String] },
    minDate: { type: Object },
    maxDate: { type: Object },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    autoValidate: {
      type: Boolean,
      default: true,
    },
    dateFormat: {
      type: String,
      default: dateFormatStandard,
    },
    startOf: {
      type: String,
      default: '',
    },
    endOf: {
      type: String,
      default: '',
    },
  },

  created() {
    this.onChanges();
  },

  watch: {
    date() {
      this.onChanges();
    },
    minDate() {
      this.onChanges();
    },
    maxDate() {
      this.onChanges();
    },
    dateFormat() {
      this.onChanges();
    },
  },

  methods: {
    onChanges() {

      //Always must have a date format
      if (!this.dateFormat) {
        this.dateFormat = dateFormatStandard;
      }

      //Validate min/max dates
      if (this.date && this.autoValidate) {
        if (this.minDate && this.date.isBefore(this.minDate)) {
          const date = this.minDate.clone();
          this.setNewDate(date);
        }
        if (this.maxDate && this.date.isAfter(this.maxDate)) {
          const date = this.maxDate.clone();
          this.setNewDate(date);
        }
      }

      //Set formatted date
      // MHJB checking to see if this.date.format is truthy is a hacky workaround for the bug
      // of the mysterious setting of this.date to a string after clicking 'mark paid'
      this.dateFormatted = this.date && this.date.format ? this.date.format(this.dateFormat) : '';
    },

    setNewDate(date) {
      this.$emit('change', { date });
    },

    showDatePicker() {

      //Not when disabled
      if (this.isDisabled) {
        return;
      }

      //Get data and create change handler
      const { date, canClear, minDate, maxDate, startOf, endOf } = this;
      const onChange = date => {

        //Modify if needed
        if (date !== null) {
          if (startOf) {
            date.startOf(startOf);
          }
          else if (endOf) {
            date.endOf(endOf);
          }
        }

        //Emit change upward
        this.setNewDate(date);
      };

      //Open editing modal
      this.$modal.show(
        ModalDatePicker,
        { date, canClear, minDate, maxDate, onChange },
        { width: 300 },
      );
    },
  },
};
</script>
