import http from '@/services/http';
import Asset from '@/models/asset.model';

export default {
  query(filter) {
    return http
      .get('/asset', filter)
      .then(data => Asset.createFrom(data, null, 'assets'));
  },

  list(filter) {
    return http
      .get('/asset/list', filter)
      .then(items => Asset.createFrom(items));
  },

  summary(filter) {
    return http
      .get('/asset/summary', filter);
  },

  findById(id) {
    return http
      .get(`/asset/${id}`)
      .then(data => Asset.createFrom(data));
  },

  create(data) {
    return http
      .post('/asset', data);
  },

  update(id, data) {
    return http
      .put(`/asset/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/asset/${id}`);
  },
};
