<script>
import { Tabs as VTabs } from 'vue-tabs-component';

export default {
  name: 'Tab-Wrap',

  inheritAttrs: false,

  data() {
    return {
      hasMultiple: false,
    };
  },

  components: { VTabs },

  mounted() {
    if (!this.$children.length) {
      return;
    }
    this.hasMultiple = this.$children.length > 1;
    this.$children[0].isActive = true;
  },

  methods: {
    /** Select first tab */
    reset() {
      this.$children[0].selectTab(this.$children[0].$children[0].hash);
    },
  },
};
</script>

<template>
  <div class="Tabs">
    <template v-if="hasMultiple">
      <v-tabs v-bind="$attrs">
        <slot />
      </v-tabs>
    </template>
    <template v-else>
      <div class="tabs-component" v-bind="$attrs">
        <div class="tabs-component-panels">
          <slot />
        </div>
      </div>
    </template>
  </div>
</template>
