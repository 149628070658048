import invoiceStatuses from '@/constants/invoice-statuses';

export default function(value) {
  const item = invoiceStatuses.find(item => item.value === value);

  if (!item) {
    return '';
  }

  return item.label;
}
