/**
 * Dependencies
 */
import projectStatuses from '@/constants/project-statuses';

/**
 * Project status filter
 */
export default function(value) {

  //Find corresponding item
  const item = projectStatuses.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
