import http from '@/services/http';
import CreditCard from '@/models/credit-card.model';

export default {
  query(filter) {
    return http
      .get('/credit-card', filter)
      .then(data => CreditCard.createFrom(data, null, 'creditCards'));
  },

  list(filter) {
    return http
      .get('/credit-card/list', filter)
      .then(creditCards => CreditCard.createFrom(creditCards));
  },

  summary(filter) {
    return http
      .get('/credit-card/summary', filter);
  },

  findById(id) {
    return http
      .get(`/credit-card/${id}`)
      .then(data => CreditCard.createFrom(data));
  },

  create(data) {
    return http
      .post(`/credit-card`, data);
  },

  update(id, data) {
    return http
      .put(`/credit-card/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/credit-card/${id}`);
  },
};
