<script>
import { isEqual, cloneDeep } from 'lodash';
import { required, requiredIf } from 'vuelidate/lib/validators';
import AssetApi from '@/api/asset.api';
import OfferingApi from '@/api/offering.api';
import ContactApi from '@/api/contact.api';
import { END_DATE, NUM_CYCLES } from '@/constants/recurrence-endings';
import RecurrenceEndings from '@/constants/recurrence-endings';
import RecurrenceIntervals from '@/constants/recurrence-intervals';
import ModalEdit from '@/components/shared/modal-edit';
import ModelSearch from '@/components/shared/model-search';
import moment from 'moment';

export default {
  components: { ModelSearch, ModalEdit },

  data() {
    return {
      AssetApi,
      assets: null,
      offerings: [],
      ContactApi,
      model: {
        contact: null,
        name: null,
        recurrence: {
          interval: null,
          ends: null,
          startDate: null,
          endDate: null,
          numCycles: null,
        },
        offering: null,
        asset: null,
        assetName: null,
        price: null,
        description: null,
      },
      noAssetContactWarning: false,
      original: {},
      recurrenceIntervals: [],
      recurrenceEndings: RecurrenceEndings,
      moment,
      isDisabledClientChange: false,
      isDisabledAssetChange: false,
    };
  },

  props: {
    contract: { type: Object },
    onSave: { type: Function },
    isEdit: { type: Boolean },
  },

  validations: {
    model: {
      contact: { required },
      price: { required },
      offering: { required },
      recurrence: {
        startDate: { required },
        endDate: {
          required: requiredIf(function(recurrence) {
            return (recurrence && recurrence.ends === END_DATE);
          }),
        },
        numCycles: {
          required: requiredIf(function(recurrence) {
            return (recurrence && recurrence.ends === NUM_CYCLES);
          }),
        },
        interval: { required },
      },
    },
  },

  computed: {
    isDisabledConfirm() {
      const notChanged = isEqual(this.model, this.original);
      return notChanged;
    },

    offeringRecurrenceIntervals() {
      const offering = this.model.offering;

      if (!offering) {
        return [];
      }

      const recurrencesAvailable = ['months', 'years']
        .filter(interval => offering.invoicePrices?.[interval]);

      return RecurrenceIntervals.map(interval => ({
        ...interval,
        disabled: !recurrencesAvailable.includes(interval.value),
      }));
    },
  },

  async created() {
    if (this.isEdit ||
        this.contract?.contact?.id) {
      this.isDisabledClientChange = true;
    }
    if (this.isEdit ||
        this.contract?.items?.[0]?.asset) {
      this.isDisabledAssetChange = true;
    }

    //Create model subset
    this.model = this.contract.extract(Object.keys(this.model));

    await this.loadAssets();
    await this.loadOfferings();

    // Contract model allows for an array of items, but our present form uses just one
    if (this.contract.items) {
      this.model.price = this.contract.items[0].price;
      this.model.description = this.contract.items[0].description;
      this.model.asset = this.contract.items[0].asset;

      const offering = this.offerings.find(({ id }) => id === this.contract.items[0].offering?.id);
      if (offering) {
        this.onChangedOffering(offering)
      }
    }

    // Populate contact so that we can see if a credit card is enabled
    if (this.model.contact) {
      this.model.contact = await this.ContactApi.findById(this.model.contact.id);
    }

    this.setDefaults();
    this.original = cloneDeep(this.model);
  },

  methods: {
    addItem(offering) {
      if (!offering) {
        return;
      }

      const item = {
        offering,
        price: offering.invoicePrices[this.model.recurrence.interval],
        description: offering.description,
      };

      this.model.items ? this.model.items.push(item) : this.model.items = [item];
    },

    removeItem(itemIndex) {
      const removeByIndex = (arr, index) => arr.filter((v, i) => i !== index);
      this.model.items = removeByIndex(this.model.items, itemIndex);
    },

    setDefaults() {
      if (!this.model.interval) {
        this.model.interval = RecurrenceIntervals[0].value;
      }
      if (!this.model.recurrence.startDate) {
        this.$set(this.model.recurrence, 'startDate', moment());
      }
    },

    onChangedAsset(asset) {
      this.model.asset = asset.id;
      this.model.assetName = asset.name;
      this.noAssetContactWarning = !asset.contact;
    },

    onChangedOffering(offering) {
      this.model.offering = offering;

      const intervals = Object.keys(offering.invoicePrices);
      const nonNullIntervals = intervals.filter(interval => offering.invoicePrices[interval]);

      if (nonNullIntervals.length === 1) {
        this.model.recurrence.interval = nonNullIntervals[0];
      }

      this.model.price = offering.invoicePrices[this.model.recurrence.interval];
      this.model.description = offering.description;
    },

    onChangedContact(contact) {
      this.model.contact = contact;
    },

    async loadAssets() {
      const res = await AssetApi.query();
      this.assets = res.assets;
    },

    async loadOfferings() {
      await OfferingApi.query()
        .then(({ offerings }) => this.offerings = offerings);
    },

    // Assets with no contact or with the selected contact
    filteredAssets() {
      return this.assets
        .map(asset => ({
          ...asset,
          longName: `${asset.name} (${asset.type.name})`,
          hasContact: !!asset.contact,
        }))
        .filter(({ contact }) =>
          !contact ||
          this.model.contact && contact.id === this.model.contact.id
        )
        .sort((a, b) => b.hasContact - a.hasContact);
    },

    changeInterval(event) {
      this.model.recurrence.interval = event.value;
      this.model.price = this.model.offering.invoicePrices[this.model.recurrence.interval];
    },

    changeDate(event) {
      this.$set(this.model.recurrence, 'startDate', event.date);
    },
  },
};
</script>

<template>
  <modal-edit
    singular="service"
    :model="model"
    :validation="$v"
    :is-edit="isEdit"
    :on-save="onSave"
    :is-disabled-confirm="isDisabledConfirm"
    @close="$emit('close')"
  >
    <div class="Form">
      <!-- <div class="Group" :class="{ 'Group--error': $v.model.name.$error }">
            <div class="Group-Single">
              <label class="Label">{{ $t('contracts_modals_edit.contract_name') }}</label>
              <input class="Input-100" type="text" v-model="model.name"
                :placeholder="$t('contracts_modals_edit.contract_name_placeholder')">
              <div class="InputHint" v-if="$v.model.name.$error">
                <div v-if="!$v.model.name.required">{{ $t('contracts_modals_edit.contract_name_required') }}</div>
              </div>
            </div>
          </div>
      -->

      <div class="Group" :class="{ 'Group--error': $v.model.contact.$error }">
        <label class="Label">{{ $t('contracts_modals_edit.client') }}</label>
        <model-search
          :disabled="isDisabledClientChange"
          :disabledLabel="isEdit ? 
            $t('contracts_modals_edit.cant_change_client') :
            $t('contracts_modals_edit.remove_items_change_client')
          "
          :min-length="1"
          :model="model.contact"
          :api="ContactApi"
          :placeholder="$t('contracts_modals_edit.client_lookup')" @change="onChangedContact($event.value)"
        />
        <div class="InputHint" v-if="$v.model.contact.$error">
          <div v-if="!$v.model.contact.required">{{ $t('contracts_modals_edit.client_required') }}</div>
        </div>
      </div>

      <div class="Group">
        <div class="Modal--field-S">
          <label class="Label">{{$t('contracts_modals_edit.offering')}}</label>
        </div>
        <select-box
          class="Contract-Offering--selector"
          :options="offerings"
          :asObject="true"
          label-by="name"
          track-by="id"
          :nullLabel="$t('contracts_modals_edit.select_offering')"
          @change="onChangedOffering($event.value)"
          :model="model.offering"
        />
        <div class="InputHint" v-if="$v.model.offering.$error">
          <div v-if="!$v.model.offering.required">{{ $t('contracts_modals_edit.offering_required') }}</div>
        </div>
      </div>

      <div class="Group">
        <div class="Modal--field-S">
          <label class="Label">{{ $t('contracts_modals_edit.related_asset') }}</label>
        </div>
        <div class="Modal-Value">
          <div class="Select-LoadingBg">
            <select-box
              class="SelectBox SelectBox--contractAsset"
              v-if="assets"
              :model="model.asset"
              :options="filteredAssets()"
              label-by="longName"
              special-display="asset"
              :asObject="true"
              track-by="_id"
              @change="onChangedAsset($event.value)"
              :editable="!isDisabledAssetChange"
            />
            <div class="InputNotice" v-if="noAssetContactWarning">
              <i class="Icon-Base">info</i>{{ $t('contracts_modals_edit.asset_owner_will_be_assigned') }}
            </div>
          </div>
        </div>
      </div>

      <div class="Group-Cols">
        <div class="Group-Col">
          <label class="Label">{{ $t('contracts_modals_edit.repeats') }}</label>
          <select-box
            :model="model.recurrence.interval"
            :options="offeringRecurrenceIntervals"
            @change="changeInterval"
            :disabled-option-label="$t('contracts_modals_edit.offerings_period_notset')"
            :editable="model.offering != null"
          />
          <div class="InputHint" v-if="$v.model.recurrence.interval.$error">
            <div v-if="!$v.model.recurrence.interval.required">{{ $t('contracts_modals_edit.interval_required') }}</div>
          </div>

        </div>

        <!-- repeat for each of recurrenceIntervals -->
        <div class="Group-Col">
          <label class="Label">{{ $t('contracts_modals_edit.price_excl_tax') }}</label>
          <div class="Modal-Value">
            <CurrencyInput
              class="Input-100 Input--price"
              min="0"
              step="10"
              v-model="model.price"
            />
          </div>
        </div>
      </div>

      <div class="Group-Cols">
        <div class="Group-Col" :class="{ 'Group--error': $v.model.recurrence.startDate.$error }">
          <label class="Label">{{ $t('contracts_modals_edit.start_date') }}</label>
          <date-picker
            :date="model.recurrence.startDate"
            @change="changeDate"
          />
          <!-- @change="model.recurrence.startDate = $event.date" -->
          <div class="InputHint" v-if="$v.model.recurrence.startDate.$error">
            <div v-if="!$v.model.recurrence.startDate.required">{{ $t('contracts_modals_edit.start_date_required') }}</div>
          </div>
        </div>
        <div class="Group-Col">
          <label class="Label">{{ $t('contracts_modals_edit.ends') }}</label>
          <select-box
            :model="model.recurrence.ends"
            :options="recurrenceEndings"
            @change="model.recurrence.ends = $event.value"
          />
          <div class="Number-of-cycles" v-if="model.recurrence.ends === 'numCycles'">
            <input class="Input-XS" type="number" min="1" step="1" placeholder="#"
              v-model="model.recurrence.numCycles" />
          </div>
        </div>
        <div class="Group-Col" v-if="model.recurrence.ends === 'endDate'"
          :class="{ 'Group--error': $v.model.recurrence.endDate.$error }">
          <label class="Label">{{ $t('contracts_modals_edit.end_date') }}</label>
          <date-picker :date="model.recurrence.endDate" :can-clear="true"
            @change="model.recurrence.endDate = $event.date" />
          <div class="InputHint" v-if="$v.model.recurrence.endDate.$error">
            <div v-if="!$v.model.recurrence.endDate.required">{{ $t('contracts_modals_edit.end_date_required') }}</div>
          </div>
        </div>
      </div>

      <div class="Group">
        <label class="Label">{{ $t('contracts_modals_edit.invoice_description') }}</label>
        <div class="">
          <textarea :id="$htmlID('description')" class="Input-100" v-model="model.description" />
        </div>
      </div>

      <div v-if="model.contact?.creditCard?.automaticPaymentEnabled" class="InputNotice">
        <i class="Icon-Base">smart_toy</i>{{ $t('contracts_modals_edit.card_payments_enabled_auto') }}
      </div>
      <div v-else-if="model.contact?.creditCard" class="InputNotice">
        <i class="Icon-Base">ads_click</i>{{ $t('contracts_modals_edit.card_payments_enabled_manual') }}
      </div>

    </div>
  </modal-edit>
</template>

<style scoped lang="scss">
.Contract--edit-title {
  font-size: $fontSizeL;
}

.Contract-Offering--selector {
  width: 100%;
}

.Cancel-Bar {
  margin: $spacingL 0 0 0;
  display: flex;
  justify-content: right;
}

.SelectBox--contractAsset {
  width: 100%;
}

.Modal--new {
  padding-bottom: $spacingM;
}

.Number-of-cycles {
  margin-top: $spacingM;
}

.ContractItem-Button {
  display: flex;
  justify-content: end;
  margin-top: $spacingXL;
}
</style>
