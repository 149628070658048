
/**
 * Format plugin exposes template-level filters for programmatic use
 * Usage: e.g.
 *
 *   const displayAmount = this.$format('currency', 123.45);
 */
const Plugin = {

  /**
   * Install plugin
   */
  install(Vue/*, options*/) {

    Vue.prototype.$format = (type, ...args) => {
      const fn = Vue.filter(type);
      if (fn) {
        return fn(...args);
      }
      return args.length && args[0];
    };

  },
};

export default Plugin;
