<script>
export default {
  data() {
    return {
      isVisible: false,
      myId: this.uuid,
    };
  },

  watch: {
    currentOn() {
      this.isVisible = this.uuid === this.currentOn;
    },
  },

  props: {
    onClick: { type: Function },
    currentOn: { type: String },
  },

  mounted() {
    this.boundClickHandler = this.clickHandler;
    document.addEventListener('click', this.boundClickHandler);
  },

  destroyed() {
    document.removeEventListener('click', this.boundClickHandler);
  },

  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
      this.onClick(this.isVisible ? this.uuid : '');
      this.$store.dispatch('session/setDropMenuOpen', this.isVisible);
    },

    hide() {
      this.isVisible = false;
    },

    clickHandler(event) {
      const { dropMenuContent } = this.$refs;
      const { target } = event;
      if (target !== dropMenuContent && !dropMenuContent.contains(target)) {
        this.hide();
        this.$store.dispatch('session/setDropMenuOpen', false);
      }
    },
  },
};
</script>

<template>
  <div class="DropMenu">
    <div class="DropMenu-trigger" @click.stop="toggle()">
      <slot name="trigger" v-bind:isVisible="isVisible" />
    </div>
    <div class="DropMenu-content DropMenu-content--Admin" v-show="isVisible" ref="dropMenuContent">
      <slot name="Content--mediumWidth" />
    </div>
  </div>
</template>

<style lang="scss">
.DropMenu-trigger {
  cursor: pointer;
}

.DropMenu-content {
  z-index: 800;
}

.Menu-item--notification .DropMenu-content {
  position: absolute;
  bottom: 48px;
  left: 215px;
  background-color: $colorLightestGray;
  box-shadow: 0px $spacingS  $spacingL 0px rgba(0, 0, 0, 0.5);
  border-radius: $borderRadiusM;
  margin-top: $spacingS;
  font-size: $fontSizeS;
}

.Menu-item--adminMenu .DropMenu-content {
  position: absolute;
  bottom: 26px;
  left: 215px;
  background-color: $colorLightestGray;
  box-shadow: 0px $spacingS  $spacingL 0px rgba(0, 0, 0, 0.5);
  border-radius: $borderRadiusM;
  margin-top: $spacingS;
  font-size: $fontSizeS;
}

.Menu-item--notification .DropMenu-content,
.Menu-item--adminMenu .DropMenu-content {
  &a {

    &:hover,
    &.router-link-exact-active {
      background: $colorBlackFaded3;
    }
  }

  .router-link-exact-active {
    background: $colorBlackFaded3;

    .Icon-Base {
      color: $colorText;
    }
  }
}

.Menu-Closed .DropMenu-content {
  left: 5rem;
}

.DropMenu-title {
  border-radius: $borderRadiusM $borderRadiusM 0 0;
  padding: $spacingM $spacingM $spacingM 0;
  display: flex;
  flex-direction: column;

  .UserName {
    font-size: $fontSizeM;
    font-weight: $fontWeightBold;
  }
  .UserRole {
    color: $colorTextFaded1;
    margin-top: 2px;
    font-size: $fontSizeXS;
    font-weight: $fontWeightNormal;
  }
}

a.DropMenu-item {
  padding: $spacingS $spacingXS $spacingS 0.75rem;
  margin: $spacingXS 0;
  display: flex;
  align-items: center;
  gap:  $spacingM;
  text-decoration: none;
  color: $colorText;
  font-size: $fontSizeS;

  .Icon-Base {
    font-size: 1.3rem;
  }

  &:hover {
    background: $colorBlackFaded3;
    color: $colorText;
  }

  &:hover .Icon-Base {
    color: $colorText;
  }
}

.DropMenu-item--footer {
  border-top: 1px dashed $colorBordersNormal;
}

.Menu-item--adminMenu .DropMenu-content--Admin {
  width: 256px;
}

.Menu-item--notification .DropMenu-content--Notifications {
  width: 368px;
}

.UserLinks-item.UserHelp .DropMenu-trigger .Icon--withCount:after {
  background-color: #0a60e3;
  width: .7rem;
  height: .7rem;
}
</style>
