<template>
  <div class="Group-wrapper Group">
    <slot />
  </div>
</template>

<script>
export default {
};
</script>
