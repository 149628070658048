<script>
import ModalView from '@/components/shared/modal-view';

export default {
  components: { ModalView },

  props: {
    account: { type: Object },
    item: { type: Object },
  },
};
</script>

<template>
  <modal-view
    singular="Payment"
    icon="payments"
    :item="item"
    :editable="false"
    :saveable="false"
    @close="$emit('close')"
  >
    <div class="Flex-row--between">
      <div class="col">
        <div class="Modal--item">
          <label class="Label">Invoice #</label>
          <modal-link
            :to="`/billing/invoices/view/${item.invoice.id}`"
            :from="`/billing/payments/view/${item.id}`" fromName="payment">
            {{item.invoice.number}}
          </modal-link>
        </div>
        <div class="Modal--item">
          <label class="Label">{{$t('global.client')}}</label>
          <div>{{item.contact ? item.contact.name : '–'}}</div>
        </div>
        <div class="Modal--item">
          <label class="Label">Amount</label>
          <div class="Modal-Value">{{item.amountToPay | currency}}</div>
        </div>
        <div class="Modal--item">
          <label class="Label">Status</label>
          <div class="Modal-Value">{{item.status}}</div>
        </div>
        <div class="Modal--item">
          <label class="Label">Date</label>
          <div class="Modal-Value">{{item.date}}</div>
        </div>
      </div>
    </div>
  </modal-view>
</template>