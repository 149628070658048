
/**
 * Define values
 */
export const STRIPE = 'stripe';
export const CASH = 'cash';
export const CHEQUE = 'cheque';
export const EFTPOS = 'eftpos';
export const BANK_TRANSFER = 'bankTransfer';
export const DIRECT_DEBIT = 'directDebit';
export const OTHER = 'other';

/**
 * Define payment methods
 */
const paymentMethods = [
  {
    value: STRIPE,
    label: `Credit/debit card`,
    isOnline: true,
  },
  {
    value: BANK_TRANSFER,
    label: `Bank transfer`,
  },
  {
    value: CASH,
    label: `Cash`,
  },
  {
    value: CHEQUE,
    label: `Cheque`,
  },
  {
    value: DIRECT_DEBIT,
    label: `Direct debit`,
  },
  {
    value: EFTPOS,
    label: `EFTPOS`,
  },
  {
    value: OTHER,
    label: `Other`,
  },
];

/**
 * Default export
 */
export default paymentMethods;

/**
 * Online and offline payment methods
 */
export const online = paymentMethods.filter(method => !method.isOnline);
export const offline = paymentMethods.filter(method => !method.isOnline);
