<template>
  <div class="Modal">
    <div class="Modal-Header">
      <h1>{{$t('global.select_date')}}</h1>
      <i
        class="Icon-ModalsClose"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody ModalBody--datePicker">
      <div class="Calendar">
        <div class="Calendar-header">
          <span
            class="Calendar-headerButton"
            :class="{'is-disabled': !canBrowsePrevMonth}"
            @click="prevMonth"
          >
            <i class="Icon-Chevron">chevron_left</i>
          </span>
          <span class="Calendar-headerTitle">{{title}}</span>
          <span
            class="Calendar-headerButton"
            :class="{'is-disabled': !canBrowseNextMonth}"
            @click="nextMonth"
          >
            <i class="Icon-Chevron">chevron_right</i>
          </span>
        </div>
        <ul class="Calendar-list Calendar-list--weekdays">
          <li
            v-for="weekday in weekdays"
            :key="weekday"
          >
            {{weekday}}
          </li>
        </ul>
        <ol class="Calendar-list Calendar-list--days">
          <li
            @click="selectDate(day.date)"
            v-for="day in days"
            :key="day.id"
          >
            <span
              :class="{'is-date': !!day.date, 'is-disabled': day.isDisabled, 'is-today': day.isToday, 'is-selected': isSelected(day.date)}"
            >
              {{day.label}}
            </span>
          </li>
        </ol>
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :show-confirm="false"
        @cancel="$emit('close')"
      >
        <button
          class="Button--small Button--muted"
          v-if="canSetToday"
          @click="today"
        >
          <i class="Icon-Base">event</i>
          Today
        </button>
        <button
          class="Button--small Button--muted"
          v-if="canClear && date"
          @click="clear"
        >
          <i class="Icon-Base">close</i>
          {{$t('global.clear')}}
        </button>
      </button-bar>
    </div>
  </div>
</template>

<script>
import moment from '@/services/moment';

const defaultTitleFormat = 'MMMM YYYY';

export default {
  data() {
    return {
      canBrowsePrevMonth: false,
      canBrowseNextMonth: false,
      canSetToday: false,
      weekdays: moment.wkDaysShort(),
      title: '',
      days: [],
    };
  },
  props: {
    date: { type: Object },
    minDate: { type: Object },
    maxDate: { type: Object },
    titleFormat: {
      type: String,
      default: defaultTitleFormat,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    date() {
      this.update();
    },
    minDate() {
      this.update();
    },
    maxDate() {
      this.update();
    },
  },

  created() {
    this.determineMonth();
    this.update();
  },

  methods: {
    update() {
      //Process
      this.checkCanSetToday();
      this.checkCanBrowse();
      this.setTitle();
      this.generateDays();
    },

    determineMonth() {
      const monthDate = this.date || this.minDate || moment();
      this.month = monthDate.clone().startOf('month');
    },

    /**
     * Set the month title
     */
    setTitle() {
      this.title = this.month.format(this.titleFormat || defaultTitleFormat);
    },

    /**
     * Check can set today
     */
    checkCanSetToday() {
      //Set can set today
      this.canSetToday = (
        (!this.minDate || moment().isSameOrAfter(this.minDate, 'day')) &&
        (!this.maxDate || moment().isSameOrBefore(this.maxDate, 'day'))
      );
    },

    /**
     * Check if we can browse to next/prev months
     */
    checkCanBrowse() {
      //Check can browse
      this.canBrowseNextMonth = (
        !this.maxDate || (this.maxDate && this.month &&
        this.month.isBefore(this.maxDate, 'month'))
      );
      this.canBrowsePrevMonth = (
        !this.minDate || (this.minDate && this.month &&
        this.month.isAfter(this.minDate, 'month'))
      );
    },

    /**
     * Generate the days
     */
    generateDays() {

      //Initialize days
      this.days = [];

      //Get number of days and weekday of the first day
      const today = moment();
      const period = this.month.format('MMYYYY');
      const noDays = this.month.daysInMonth();
      const firstDay = this.month.isoWeekday();

      //Filler days of previous month
      for (let n = 1; n < firstDay; n++) {
        this.days.push({
          id: period + '0' + n,
          label: '',
        });
      }

      //Actual days
      let date = this.month.clone().subtract(1, 'day');
      for (let d = 1; d <= noDays; d++) {

        //Increment to next day and create day object
        date.add(1, 'day');
        let day = {
          id: period + d,
          date: date.clone(),
          label: d,
          isDisabled: false,
          isToday: today.isSame(date, 'day'),
        };

        //Check if disabled
        if (this.minDate && date.isBefore(this.minDate, 'day')) {
          day.isDisabled = true;
        }
        if (this.maxDate && date.isAfter(this.maxDate, 'day')) {
          day.isDisabled = true;
        }

        //Add day object
        this.days.push(day);
      }

      //Get weekday of last day
      let lastDay = date.isoWeekday();

      //Filler days of next month
      for (let m = lastDay + 1; m <= 7; m++) {
        this.days.push({
          id: period + '4' + m,
          label: '',
        });
      }
    },

    selectDate(date, close = true) {
      if (!date) {
        return;
      }

      //Check if possible
      if (this.minDate && date.isBefore(this.minDate, 'day')) {
        return;
      }
      if (this.maxDate && date.isAfter(this.maxDate, 'day')) {
        return;
      }

      this.onChange(date);

      if (close) {
        this.$emit('close');
      }
    },

    /**
     * Check if a date is the selected date
     */
    isSelected(date) {
      if (!date || !this.date) {
        return false;
      }
      return this.date.isSame(date, 'day');
    },

    /**
     * Set today
     */
    today() {
      const date = moment().startOf('minute');
      this.selectDate(date);
    },

    clear() {
      this.onChange(null);
      this.$emit('close');
    },

    nextMonth() {
      if (!this.canBrowseNextMonth) {
        return;
      }
      this.month.add(1, 'month');
      this.update();
    },

    prevMonth() {
      if (!this.canBrowsePrevMonth) {
        return;
      }
      this.month.subtract(1, 'month');
      this.update();
    },
  },
};
</script>

<style lang="scss">

//Calendar variables
$calendarCellSize: 2rem;
$calendarCellMargin: .2rem;

//Modal
.ModalBody--datePicker {
  padding-bottom: 0;
}

//Calendar
.Calendar {
  background: $colorWhite;
  margin-left: auto;
  margin-right: auto;
  @include breakPointSmall {
    right: 0;
    width: 7 * ($calendarCellSize + (2 * $calendarCellMargin)) + .125rem;
  }
}
.Calendar-header {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Calendar-headerTitle {
  flex: 1;
  font-weight: $fontWeightBold;
}
.Calendar-headerButton {
  cursor: pointer;
  width: $calendarCellSize;
  height: $calendarCellSize;
  line-height: $calendarCellSize;
  text-align: center;
  &.is-disabled {
    cursor: default;
    color: $colorTextFaded3;
  }
}
.Calendar-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  li {
    float: left;
    width: calc(100% / 7 - (2 * #{$calendarCellMargin}));
    max-width: $calendarCellSize;
    margin: $calendarCellMargin;
    text-align: center;
    &:nth-child(7n+1) {
      clear: left;
    }
  }
}
.Calendar-list--weekdays {
  font-size: $fontSizeS;
  color: $colorTextFaded2;
  border-bottom: 1px solid $colorLightestGray;
  li {
    height: $lineHeightL;
    line-height: $lineHeightL;
  }
}
.Calendar-list--days {
  li {
    position: relative;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.is-date {
      cursor: pointer;
      &:hover:not(.is-selected):not(.is-disabled) {
        background: $colorActiveTabs;
      }
    }
    &.is-disabled {
      cursor: default;
      color: $colorTextFaded3;
    }
    &.is-today:not(.is-disabled) {
      background: $colorActiveTabs;
      font-weight: $fontWeightBold;
    }
    &.is-selected {
      background: $colorPrimary !important;
      color: $colorWhite !important;
    }
  }
  @include breakPointMedium {
    min-height: 6 * ($calendarCellSize + 2 * $calendarCellMargin);
  }
}
</style>
