<script>
import DashListAssets from './dashboard-items/dashlist-assets.vue';
import DashListRevenue from './dashboard-items/dashlist-revenue.vue';

export default {
  components: {
    DashListAssets, DashListRevenue,
  },
};
</script>

<template>
  <div class="Dash--col Dash--col-2">
    <dash-list-assets />
    <dash-list-revenue />
  </div>
</template>
