export const PERSON = 'person';
export const ORGANISATION = 'organisation';

export default [
  {
    value: PERSON,
    label: `Person`,
  },
  {
    value: ORGANISATION,
    label: `Organisation`,
  },
];
