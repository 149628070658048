import Vue from 'vue';
import Router from 'vue-router';

//Guards
import authentication from '@/guards/authentication';

//Components
import AccountOverview from '@/components/account/overview';
import AccountBilling from '@/components/account/billing';
import AccountBillingHistory from '@/components/account/billing-history';
import DirectoryOverview from '@/components/directory/clients';
import ContractsOverview from '@/components/contracts/overview';
import DirectoryUsers from '@/components/directory/users';
import CreditCardsOverview from '@/components/credit-cards/overview';
import Dashboard from '@/components/dashboard/dashboard';
import ErrorRoute from '@/components/error/error';
import ForgotPassword from '@/components/portal-forms/login/forgot-password';
import HeaderIntegrations from '@/components/header-integrations/overview';
import DirectoryHeader from '@/components/directory/header';
import HeaderAdmin from '@/components/header-admin/overview';
import HeaderBilling from '@/components/header-billing/overview';
import HeaderWorkspace from '@/components/header-workspace/overview';
import Integration from '@/components/integrations/integration';
import IntegrationsOverview from '@/components/integrations/overview';
import IntegrationsSearch from '@/components/integrations/search';
import InvitedUser from '@/components/portal-forms/register/invited-user';
import InvoicesOverview from '@/components/invoices/overview';
import Login from '@/components/portal-forms/login/login';
import NotFoundRoute from '@/components/error/not-found';
import OAuthCallback from '@/components/oauth/callback';
import PaymentsOverview from '@/components/payments/overview';
import PayInvoice from '@/components/portal-forms/pay-invoice/pay-invoice';
import StandaloneInvoice from '@/components/portal-forms/invoice/invoice';
import ProfileOverview from '@/components/profile/profile';
import Register from '@/components/portal-forms/register/register';
import ResetPassword from '@/components/portal-forms/reset-password/reset-password';
import SettingsOverview from '@/components/settings/overview';
import SettingsInvoice from '@/components/settings/settings-cashflow-invoices';
import SettingsTax from '@/components/settings/settings-cashflow-tax';
import Offerings from '@/components/offerings/overview';
import AssetsOverview from '@/components/assets/overview';

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: HeaderWorkspace,
      children: [
        {
          path: '',
          redirect: { name: 'dashboard' },
        },
      ],
      meta: { 
        auth: true,
        i18n: {
          pageTitle: 'workspace.title'
        },
      },
    },
    {
      path: '/account',
      component: HeaderAdmin,
      children: [
        {
          path: '',
          name: 'account',
          component: AccountOverview,
          meta: {
            i18n: {
              pageTitle: 'account_overview.title'
            },
          }
        },
        {
          path: 'billing',
          name: 'Billing',
          component: AccountBilling,
          meta: {
            i18n: {
              pageTitle: 'account_billing.title'
            }
          }
        },
        {
          path: 'billing-history',
          name: 'Billing history',
          component: AccountBillingHistory,
          meta: {
            i18n: {
              pageTitle: 'account_billing-history.title'
            }
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: ProfileOverview,
          meta: {
            auth: true,
            i18n: {
              pageTitle: 'profile_profile.title'
            }
          },
        },
      ],
      meta: { auth: true },
    },
    {
      path: '/billing',
      component: HeaderBilling,
      children: [
        {
          path: '',
          redirect: { name: 'invoices' },
        },
        {
          path: 'invoices',
          name: 'invoices',
          component: InvoicesOverview,
          meta: {
            i18n: {
              pageTitle: 'invoices_overview.title'
            }
          },
          children: [{ path: ':action?/:id?', component: InvoicesOverview }],
        },
        {
          path: 'credit-cards',
          name: 'credit cards',
          component: CreditCardsOverview,
          meta: {
            i18n: {
              pageTitle: 'credit-cards_overview.title'
            }
          },
          children: [{ path: ':action?/:id?', component: CreditCardsOverview }],
        },
        {
          path: 'payments',
          name: 'payments',
          component: PaymentsOverview,
          meta: {
            i18n: {
              pageTitle: 'payment.title'
            }
          },
          children: [{ path: ':action?/:id?', component: PaymentsOverview }],
        },
      ],
      meta: { auth: true },
    },
    {
      path: '/integrations',
      component: HeaderIntegrations,
      children: [
        {
          path: '',
          redirect: { name: 'integrations' },
        },
        {
          path: 'installed',
          name: 'integrations',
          component: IntegrationsOverview,
          meta: {
            auth: true,
            i18n: {
              pageTitle: 'integration.title'
            }
          }
        },
        {
          path: 'search',
          name: 'explore apps',
          component: IntegrationsSearch,
          meta: {
            auth: true,
            i18n: {
              pageTitle: 'header-integrations_overview.marketplace'
            }
          }
        },
        {
          path: ':type?',
          name: 'integration',
          component: Integration,
          meta: {
            auth: true,
            i18n: {
              pageTitle: 'integration.title', // Default title if no integration type specified
              isDynamic: true
            }
          }
        }
      ],
      meta: { auth: true },
    },
    {
      path: '/settings',
      component: HeaderAdmin,
      children: [
        {
          path: '',
          redirect: { name: 'settings' },
        },
        {
          path: 'overview',
          name: 'settings',
          component: SettingsOverview,
          meta: {
            i18n: {
              pageTitle: 'settings-overview.title'
            }
          }
        },
        {
          path: 'tax',
          name: 'Tax settings',
          component: SettingsTax,
          meta: {
            i18n: {
              pageTitle: 'settings-settings_cashflow_tax.title'
            }
          }
        },
        {
          path: 'invoices',
          name: 'Invoice settings',
          component: SettingsInvoice,
          meta: {
            i18n: {
              pageTitle: 'account_invoice-settings.title'
            }
          }
        },
      ],
      meta: { auth: true },
    },
    {
      path: '/directory',
      component: DirectoryHeader,
      children: [{
        path: '',
        name: 'directory',
        redirect: { name: 'clients' },
      }, {
        path: 'clients',
        component: DirectoryOverview,
        meta: {
          i18n: {
            pageTitle: 'directory_clients.filter.type.all'
          }
        },
        children: [
          {
            path: ':action?/:id?',
            name: 'clients',
            component: DirectoryOverview,
          },
        ],
      },
      {
        path: 'users',
        component: DirectoryUsers,
        meta: {
          i18n: {
            pageTitle: 'users.staff'
          }
        },
        children: [{
          path: ':action?/:id?',
          name: 'users',
          component: DirectoryUsers,
        }],
      }],
      meta: { auth: true },
    },
    {
      path: '/workspace',
      component: HeaderWorkspace,
      children: [
        {
          path: '',
          redirect: { name: 'dashboard' },
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            i18n: {
              pageTitle: 'workspace.title'
            }
          }
        },
        {
          path: 'services',
          name: 'workspace',
          component: ContractsOverview,
          meta: {
            i18n: {
              pageTitle: 'contracts_overview.title'
            }
          },
          children: [{
            path: ':action?/:id?',
            name: 'services',
            component: ContractsOverview,
          }],
        },
        {
          path: 'assets',
          name: 'Assets',
          component: AssetsOverview,
          meta: {
            i18n: {
              pageTitle: 'assets_overview.title'
            }
          },
          children: [{
            path: ':action?/:id?',
            name: 'assets',
            component: AssetsOverview,
          }],
        },
        {
          path: 'offerings',
          name: 'workspace5',
          component: Offerings,
          meta: {
            i18n: {
              pageTitle: 'offerings_overview.title'
            }
          },
          children: [{
            path: ':action?/:id?',
            name: 'offerings',
            component: Offerings,
          }],
        },
      ],
      meta: { auth: true },
    },
    // Auth routes
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        i18n: {
          pageTitle: 'login_login.title'
        }
      }
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
      meta: {
        i18n: {
          pageTitle: 'forgot_password.forgot_password'
        }
      }
    },
    {
      path: '/reset-password/:accessToken',
      name: 'resetPassword',
      component: ResetPassword,
      props: true,
      meta: {
        i18n: {
          pageTitle: 'portal-forms_reset-password_reset-password.page_title'
        }
      }
    },
    {
      path: '/register/:plan?',
      name: 'register',
      component: Register,
      props: true,
      meta: {
        i18n: {
          pageTitle: 'portal-forms_register_register.page_title'
        }
      }
    },
    {
      path: '/oauth/callback',
      name: 'oAuthCallback',
      component: OAuthCallback,
      props: true,
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorRoute,
      props: true,
    },
    {
      path: '/not-found',
      name: 'notFound',
      component: NotFoundRoute,
    },
    {
      path: '*',
      redirect: '/not-found',
    },
  ],
});

router.pushAsync = function(route) {
  return new Promise((resolve, reject) => {
    router.push(route, resolve, reject);
  });
};

/**
 * Guards
 */
router.beforeEach(authentication);

export default router;
