/**
 * Define values
 */
export const PENDING = 'pending';
export const FAILED = 'failed';
export const CANCELLED = 'completed';
export const UNKNOWN = 'unknown';
export const PAID = 'paid';

/**
 * Define array
 */
export default [
  {
    value: PENDING,
    label: `Pending`,
  },
  {
    value: FAILED,
    label: `Failed`,
  },
  {
    value: CANCELLED,
    label: `Cancelled`,
  },
  {
    value: UNKNOWN,
    label: `Unknown`,
  },
  {
    value: PAID,
    label: `Paid`,
  },
];
