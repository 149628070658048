import http from '@/services/http';
import Payment from '@/models/payment.model';

export default {
  query(filter) {
    return http
      .get('/payment', filter)
      .then(data => Payment.createFrom(data, null, 'payments'));
  },

  list(filter) {
    return http
      .get('/paymnet/list', filter)
      .then(payments => Payment.createFrom(payments));
  },

  summary(filter) {
    return http
      .get('/payment/summary', filter);
  },

  findById(id) {
    return http
      .get(`/payment/${id}`)
      .then(data => Payment.createFrom(data));
  },

  create(data) {
    return http
      .post('/payment', data);
  },

  update(id, data) {
    return http
      .put(`/payment/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/payment/${id}`);
  },
};
