<script>

export default {
  name: 'PaginationComponent',

  props: {
    page: {
      type: Number,
      default: 1,
    },
    numPages: {
      type: Number,
      default: 0,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
    },
  },

  computed: {
    pages() {

      //Initialize
      const pages = [];

      // DFM created this with copilot
      if (this.numPages) {
        for (let i = 1; i <= this.numPages; i++) {
          if (i <= 8 || i > this.numPages - 3) {
            pages.push(i);
          } else if (i === 9) {
            pages.push('...');
          }
        }
      }

      // ORIGINAL CODE
      //Create pages array
      // if (this.numPages) {
      //   for (let i = 1; i <= this.numPages; i++) {
      //     pages.push(i);
      //   }
      // }

      //Add all option if allowed
      // if (this.showAll) {
      //   pages.push('All');
      // }

      //Return pages
      return pages;
    },
    isFirstPage() {
      return (this.page === 1);
    },
    isLastPage() {
      return (this.page === this.numPages);
    },
  },
  watch: {
    page() {
      this.onChanges();
    },
    numPages() {
      this.onChanges();
    },
  },

  methods: {
    onChanges() {

      //Reset page if invalid
      if (this.page > this.numPages || this.page < 1) {
        this.setPage(1);
      }
    },

    /**
     * Set specific page
     */
    setPage(page) {
      this.$emit('change', { page });
    },

    /**
     * Next page
     */
    nextPage() {
      if (this.page < this.numPages) {
        this.setPage(this.page + 1);
      }
    },

    /**
     * Previous page
     */
    previousPage() {
      if (this.page > 1) {
        this.setPage(this.page - 1);
      }
    },
  },
};
</script>

<template>
  <div class="Pagination" :class="location" v-if="numPages > 1">
    <span>Page</span>
    <select-box class="Pagination-selectBox" :model="page" :options="pages" @change="setPage($event.value)">
    </select-box>
    <span class="Pagination-number">of {{ numPages }} &nbsp;</span>
    <a @click="previousPage" :disabled="isFirstPage"><i class="Icon-Chevron">chevron_left</i></a>
    <a @click="nextPage" :disabled="isLastPage"><i class="Icon-Chevron">chevron_right</i></a>
  </div>
</template>

<style lang="scss">
.Pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: $fontSizeS;
  margin-right: $spacingM;
  &.Pagination--lower {
    margin-top: $spacingM;
  }
  .Input-100 {
    font-size: $fontSizeM;
    padding: $spacingS $spacingL;
  }
}

a[disabled] {
  color: $colorTextFaded3;
  cursor: default;

  .Icon-Chevron {
    color: $colorTextFaded3;
  }
}

a .Icon-Chevron {
  color: $colorTextFaded2;
}

a:hover .Icon-Chevron {
  color: $colorText;
}

a[disabled]:hover .Icon-Chevron {
  color: $colorTextFaded3;
}

.Icon-Chevron {
  vertical-align: -10px;
  font-size: 35px;
}

Input {
  width: 100%;
  padding: $spacingM $spacingL;
}

.Pagination-selectBox {
  margin: 0 $spacingS;

  .InputWrapper {
    width: 70px;
  }
}

.Pagination-number {
  margin-right: $spacingS;
}
</style>
