import http from '@/services/http';
import User from '@/models/user.model';

export default {

  query(filter) {
    return http
      .get('/user', filter)
      .then(data => User.createFrom(data, null, 'users'));
  },

  /**
	 * Get data for logged in user
	 */
  me() {
    return http
      .get('/user/me')
      .then(data => User.createFrom(data));
  },

  register(user, account, profile) {
    return http
      .post('/user/register', {user, account, profile});
  },

  summary(filter) {
    return http
      .get('/user/summary', filter);
  },

  findById(id) {
    return http
      .get(`/user/${id}`)
      .then(data => User.createFrom(data));
  },

  updateOwn(data) {
    return http
      .put(`/user/me`, data);
  },

  acceptInvite(data) {
    return http
      .put(`/user/acceptInvite`, data);
  },

  create(data) {
    return http
      .post('/user', data);
  },

  invite(data) {
    return http
      .post('/user/invite', data);
  },

  remove(id) {
    return http
      .delete(`/user/${id}`);
  },

  update(id, data) {
    return http
      .put(`/user/${id}`, data);
  },

  requestPasswordResetEmail(email) {
    return http
      .post(`/user/password/reset`, {email});
  },

  changePassword(password) {
    return http
      .put(`/user/password`, {password});
  },
};
