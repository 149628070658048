class ModalService {
  setDefaults(defaults = {}) {
    this.defaults = defaults;
  }

  setService(service) {
    this.service = service;
  }

  show(modal, props, params, events) {

    //Merge defaults into params
    params = Object.assign({}, this.defaults, params || {});

    //Call underlying service
    this.currentModal = modal;

    this.service.show(modal, props, params, events);
  }

  hide(name, params) {
    this.service.hide(name || this.currentModal, params);
  }

  toggle(name, params) {
    this.service.toggle(name, params);
  }
}

/**
 * Export singleton instance
 */
export default new ModalService();
