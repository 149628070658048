<template>
  <input
    ref="inputRef"
    type="text"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';
import { getCurrentInstance, watch } from 'vue'

export default {
  name: 'CurrencyInput',

  props: {
    options: Object,
    value: Number,
  },

  setup(props) {
    const { proxy } = getCurrentInstance();
    const currency = proxy.$store.getters['session/account'].currency.code;

    const {
      inputRef,
      formattedValue,
      setValue,
    } = useCurrencyInput({
      currency,
      currencyDisplay: 'narrowSymbol',
    })

    watch(() => props.value, (value) => {
      setValue(value);
    });

    return { inputRef, formattedValue };
  },
}
</script>
