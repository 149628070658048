<script>

import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      account: state => state.session.account,
      user: state => state.session.user,
    }),

    subscriptionStatus() {
      return this.account?.subscriptionStatus;
    },

    trialEndDate() {
      return this.account?.trialEndDate;
    },
  },
};
</script>

<template>
  <div v-if="user && user.role=='Admin' && subscriptionStatus!=='active'" class="PanelSection">
      <div class="Message-Warning" v-if="subscriptionStatus=='trial'">
        <div class="Click-hide--Subscribe">
          <router-link to="/account/billing">
            <i class="Icon-Hide">close</i>
          </router-link>
        </div>
        <div class="Message-Header--compact">
          <i class="Icon-Base--warning">error_outline</i>
          <div class="Message-Title">
            <div class="Message-Emphasis">
            <span>{{$t('dashboard_subscription-status.trial_ends')}}</span> {{trialEndDate | moment('from')}}
            <span> – </span> <router-link to="/account/billing">{{$t('dashboard_subscription-status.select_plan')}}</router-link>
          </div>
        </div>
      </div>
      <div class="SubMessage">{{$t('dashboard_subscription-status.trial_submessage')}}</div>
      </div>
      <div class="Message-Danger" v-if="subscriptionStatus=='trial_expired'">
        <div class="Click-hide--Subscribe">
          <router-link to="/account/billing">
            <i class="Icon-Hide">close</i>
          </router-link>
        </div>
        <div class="Message-Header--compact ">
          <i class="Icon-Base--danger">error_outline</i>
          <div class="Message-Title">
            <div class="Message-Emphasis">
              {{$t('dashboard_subscription-status.trial_ended')}}
              <span> – </span>
              <router-link to="/account/billing">{{$t('dashboard_subscription-status.select_plan')}}</router-link>
            </div>
          </div>
        </div>
        <div class="SubMessage">{{$t('dashboard_subscription-status.trial_ended_submessage')}}</div>
      </div>
      <div class="Message-Warning" v-if="subscriptionStatus=='card_expiring'">
        <div class="Message-Header--compact ">
          <i class="Icon-Base--warning">error_outline</i>
          <div class="Message-Title">
            <div class="Message-Emphasis">
              {{$t('dashboard_subscription-status.credit_card_expiring')}}
              <span> – </span>
              <router-link to="/account/billing">{{$t('dashboard_subscription-status.update_card')}}</router-link>
            </div>
          </div>
        </div>
        <div class="SubMessage">{{$t('dashboard_subscription-status.expiring_submessage')}}</div>
      </div>
      <div class="Message-Danger" v-if="subscriptionStatus=='card_expired'">
        <div class="Message-Header--compact ">
          <i class="Icon-Base--danger">error_outline</i>
          <div class="Message-Title">
            <div class="Message-Emphasis">
              {{$t('dashboard_subscription-status.credit_card_expired')}}
              <span> – </span>
              <router-link to="/account/billing">{{$t('dashboard_subscription-status.update_card')}}</router-link>
            </div>
          </div>
        </div>
        <div class="SubMessage">{{$t('dashboard_subscription-status.exipired_submessage')}}</div>
      </div>
      <div class="Message-Danger" v-if="subscriptionStatus=='payment_failed'">
        <div class="Message-Header--compact ">
          <i class="Icon-Base--danger">error_outline</i>
          <div class="Message-Title">
            <div class="Message-Emphasis">
              {{$t('dashboard_subscription-status.payment_failed')}}
              <span> – </span>
              <router-link to="/account/billing">{{$t('dashboard_subscription-status.update_card')}}</router-link>
            </div>
          </div>
        </div>
        <div class="SubMessage">{{$t('dashboard_subscription-status.payment_failed_submessage')}}</div>
      </div>
    </div>
</template>
