<script>
import UserApi from '@/api/user.api';
import Password from 'vue-password-strength-meter';
import { required, requiredUnless, minLength } from 'vuelidate/lib/validators';
import NotAuthenticatedError from '@/errors/not-authenticated';

export default {
  props: {
    accessToken: String,
  },

  components: { Password },

  data() {
    return {
      user: {
        name: '',
        // email: '',
        password: '',
      },
      account: {
        name: '',
      },
      isRegistering: false,
      isLoggingIn: false,
      provider: null,
      error: null,
    };
  },

  computed: {
    isGoogleSignUp() {
      return this.profile && this.profile.email;
    },
  },

  validations: {
    user: {
      name: {
        required,
      },
      // email: {
      //   required,
      //   email,
      // },
      password: {
        required: requiredUnless(function() {
          return this.isGoogleSignUp;
        }),
        minLength: minLength(10),
      },
    },
  },

  async created() {
    this.$auth.setToken(this.accessToken);
    this.user.email = this.$auth.token.payload.email;
    this.account.name = this.$auth.token.payload.accountName;

    // this.applyProfile();
  },

  methods: {
    applyProfile() {
      const { profile } = this;
      if (!profile) {
        this.redirectIfPossible();
        return;
      }

      //Get profile data
      const { firstName, lastName, email } = profile;
      const name = `${firstName} ${lastName}`;

      Object.assign(this.user, { name, email });
    },

    async accept() {
      //Validate form
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const { user, profile } = this;
      user.inviteAccepted = true;
      this.isRegistering = true;
      let auth;

      try {
        // Registration endpoint returns access token if logged in
        // MHJB: I don't think we need 'profile' here
        auth = await UserApi.acceptInvite(user, profile);
      }
      catch (error) {
        if (error instanceof NotAuthenticatedError) {
          return this.isErrorTokenExpired = true;
        }
        else {
          this.error = 'errors.' + error.message;
          setTimeout(() => {
            this.error = null;
          }, 5000);
        }
      }
      finally {
        this.isRegistering = false;
        this.$auth.loginWithToken(auth.access_token);
      }
    },

    async loginWithOAuth(provider) {
      const id = this.$auth.token.payload.userId;
      this.provider = provider;
      this.isLoggingIn = true;
      await this.$auth.loginWithOAuth(provider, 'connect', { id });
    },

    /**
     * Redirect to Login if account already registered
     */
    redirectIfPossible() {
      const { error } = this.$route.query;

      if (error && error === 'exists') {
        return this.$router.push({ name: 'login', params: this.$route.query });
      }
    },
  },
};
</script>

<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm--userInviteConfirm">
      <h1>{{$t('portal-forms_register_invited-user.user_confirmation')}} <span
          class="Account-Name">{{account.name}}</span></h1>

      <div class="SpacerM"></div>

      <div class="SpacerM"></div>

      <div v-if="!profile" class="spanner">
        <button class="Button--oauth" @click="loginWithOAuth('google')" :disabled="isLoggingIn">
          <i class="IconProvider"><img src="../../../assets/images/oauth/google.png" alt=""></i>
          {{$t('portal-forms_register_invited-user.sign_up_with_google')}}
          <spinner v-if="provider === 'google'" />
        </button>
      </div>

      <div class="SpacerTextHr">
        <hr />
        <span>{{$t('portal-forms_register_invited-user.or')}}</span>
        <hr />
      </div>

      <div class="Group" :class="{'Group--error': $v.user.name.$error}">
        <input v-model="user.name" type="text" class="Input-100"
          :placeholder="$t('portal-forms_register_invited-user.your_name')">
        <div class="InputHint" v-if="$v.user.name.$error">
          <div v-if="!$v.user.name.required">{{$t('portal-forms_register_invited-user.your_name_is_required')}}</div>
        </div>
      </div>

      <div class="Group" v-if="!profile" :class="{'Group--error': $v.user.password.$error}">
      <password
        v-model="user.password"
        :secureLength="10"
        :badge="false"
        :placeholder="$t('portal-forms_register_invited-user.password')"
      />
      <div class="InputHint" v-if="$v.user.password.$error">
        <div v-if="!$v.user.password.required">{{$t('portal-forms_register_invited-user.password_is_required')}}</div>
        <div v-if="!$v.user.password.minLength">{{$t('portal-forms_register_invited-user.password_at_least')}}</div>
      </div>
    </div>

      <p class="text-danger" v-if="isErrorTokenExpired">
        {{$t('portal-forms_register_invited-user.invite_link_expired')}}.
      </p>
      <p class="text-danger" v-if="error">
        {{$t(error)}}
      </p>

      <div class="Group">
        <button class="Button--register" @click="accept" :disabled="isRegistering">
          <i class="Icon-Lock">lock</i>{{$t('portal-forms_register_invited-user.create_account')}}
          <spinner />
        </button>
      </div>

      <!-- **** LINK DEACTIVATED UNTIL SESSION MANAGEMENT CAN BE SORTED WITH INVITE LINK ***
        <div class="Alternate-UserOption">
        {{$t('portal-forms_register_invited-user.already_have_account')}} <router-link to="/login">{{$t('portal-forms_register_invited-user.login_here')}}</router-link>
        </div>-->
    </div>
  </div>
</template>

<style scoped lang="scss">
.PortalForm--userInviteConfirm {
  padding-top: 2.5rem;

  .FormLogoWrap {
    margin-top: 0;

    img {
      width: 7rem;
    }
  }

  h1 {
    padding-left: 0;
    margin-bottom: 1rem;

    .Account-Name {
      display: block;
      font-size: $fontSize2XL;
      margin-top: $spacingM;
      word-wrap: break-word;
      hyphens: auto;
      color: $colorPrimary;
    }
  }

  .spanner {
    width: 100%;
  }
}
</style>
