
/**
 * Base configuration
 */
export default {

  app: {
    title: 'Controlla',
  },

  storage: {
    prefix: 'controlla',
  },

  auth: {
    clientId: 'controlla',
    homeRoute: 'dashboard',
    loginRoute: 'login',
  },

  sentry: {
    dsn: '',
    tags: {
      source: 'client',
    },
    ignoreErrors: [
      `QuotaExceededError: The quota has been exceeded.`,
      `timeout of 0ms exceeded`,
    ],
  },

  extremeIpLookup: {
    apiKey: 'UoYK1mJu4qFu8o8ajvsV',
  },

  //Error handling
  errors: {
    log: false,
  },
};
