<script>
export default {
  props: {
    invoicePrices: Object,
  },

  computed: {
    validInvoicePrices() {
      return Object.entries(this.invoicePrices).reduce((acc, [period, price]) => {
        if (price !== null && price !== undefined) { acc[period] = price; }
        return acc;
      }, {});
    },
  },
};
</script>

<template>
  <div class="Periods">
    <div v-for="(price, period) in validInvoicePrices" :key="period" class="Period--wrap">
      <div class="ItemS">
        <icons-services :period="period" />
      </div>
      <div class="Period-Meta">
        <div class="Period-Meta--amount">{{ price | currency }}</div>
        <div class="Period-Meta--period">{{ $t(`global.periods.${period}`) }}</div>
      </div>
    </div>
  </div>
</template>
