import merge from 'deepmerge';
import production from './production';

/**
 * Staging environment configuration
 */
export default merge.all([production, {

  app: {
    baseUrl: 'https://staging.controlla.io',
  },

  api: {
    baseUrl: 'https://controlla-staging.herokuapp.com',
  },

  stripe: {
    publicKey: `pk_test_QH5RfVUSwOPoL0BWilG2WVJn00jBfc0T13`,
  },

  engage: {
    publicKey: '6e88718f079a07874bd5e8ab1cb47a17',
  },

  errors: {
    log: true,
  },
}]);
