<script>
export default {
  data() {
    return {
      isError: false,
      isSaving: false,
      isRemoving: false,
      wantsToRemove: false,
    };
  },

  props: {
    model: { type: Object },
    validation: { type: Object },
    singular: { type: String },
    onSave: { type: Function },
    onRemove: { type: Function },
    isEdit: { type: Boolean },
    isDisabledConfirm: { type: Boolean },
  },

  methods: {
    toggleRemoveIntent() {
      this.wantsToRemove = !this.wantsToRemove;
    },

    remove() {
      this.isRemoving = true;
      this.isError = false;

      this
        .onRemove()
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isRemoving = false);
    },

    save() {
      const { model, validation } = this;

      //Validate form
      if (validation) {
        validation.$touch();
        if (validation.$invalid) {
          return;
        }
      }

      this.isSaving = true;
      this.isError = false;

      this
        .onSave(model)
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isSaving = false);
    },
  },
};
</script>

<template>
  <div class="Edit-Modal">
    <div class="Modal">
      <form @submit="$event.preventDefault();">
        <div class="Modal-Header">
          <h1 v-if="isEdit">Edit {{ singular }}</h1>
          <h1 v-if="!isEdit">Add {{ singular }}</h1>
          <div @click="$emit('close')">
            <i class="Icon-ModalsClose">close</i>
          </div>
        </div>
        <div class="ModalBody">
          <slot />
        </div>
        <div class="ModalFooter">
          <!-- Regular bar -->
          <button-bar
            v-if="!wantsToRemove"
            :is-busy="isSaving"
            label-confirm="Save"
            @cancel="$emit('close')"
            @confirm="save"
            :hasSpinnerConfirm="true"
            :is-disabled-confirm="isDisabledConfirm"
          >
            <button class="Button--muted" v-if="isEdit && onRemove" :disabled="isSaving" @click="toggleRemoveIntent">
              <i class="Icon-Delete">delete</i>
              Remove
            </button>
          </button-bar>

          <!-- Remove bar -->
          <button-bar
            v-if="wantsToRemove"
            :is-busy="isRemoving"
            label-confirm="Remove"
            type-confirm="danger"
            @cancel="toggleRemoveIntent"
            @confirm="remove">
            <p>{{ $t('shared_modal-edit.confirm_delete') }} {{ singular }}?</p>
          </button-bar>

        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
</style>
