<script>
import { mapState } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import CashflowMenu from './settings-sidemenu';

export default {
  components: { CashflowMenu },

  data() {
    return {
      model: {
        taxType: '',
        taxPercentage: '',
        taxNumber: '',
      },
      isSaving: false,
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),
  },

  created() {
      //Create model subset
    this.model = this.account.extract(Object.keys(this.model));
  },

  methods: {
    async save() {
      const { $v, account, model } = this;

      //Validate
      $v.$touch();
      if ($v.$invalid) {
        return;
      }

      this.isSaving = true;
      await account
        .updateOwn(model)
        .finally(() => {
          this.$notice.show('notices.tax_settings_saved');
          this.isSaving = false;
        });
    },
  },

  validations: {
    model: {
      taxType: { required },
      taxPercentage: {
        minValue: minValue(0),
      },
      taxNumber: { required },
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--sidemenu">
      <div class="Menu--col">
        <cashflow-menu />
      </div>
      <div class="Content--mediumWidth">
        <h1 class="PageTitle">{{$t('settings-settings_cashflow_tax.title')}}</h1>

        <div class="Group" :class="{'Group--error': $v.model.taxType.$error}">
          <div class="Label">{{$t('global.tax_type')}}</div>
          <input v-model="model.taxType" autocomplete="off" type="text" class="Input-M" :placeholder="$t('global.tax_type')">
          <div class="InputHint" v-if="$v.model.taxType.$error">
            <div v-if="!$v.model.taxType.required">{{$t('settings-settings_cashflow_tax.tax_type_required')}}</div>
          </div>
        </div>
        <div class="Group" :class="{'Group--error': $v.model.taxPercentage.$error}">
          <div class="Label">{{$t('settings-settings_cashflow_tax.tax_rate')}}</div>
          <input v-model="model.taxPercentage" type="number" min="0" max="100" step="0.01" class="Input-XS"
            placeholder="Tax percentage (%)"> %
          <div class="InputHint" v-if="$v.model.taxPercentage.$error">
            <div v-if="!$v.model.taxPercentage.minValue">{{$t('settings-settings_cashflow_tax.minimum_tax_percentage')}}
            </div>
          </div>
        </div>
        <div class="Group" :class="{'Group--error': $v.model.taxNumber.$error}">
          <div class="Label">{{$t('global.tax_number')}}</div>
          <input v-model="model.taxNumber" type="text" class="Input-M" :placeholder="$t('global.tax_number')">
          <div class="InputHint" v-if="$v.model.taxNumber.$error">
            <div v-if="!$v.model.taxNumber.required">{{$t('settings-settings_cashflow_tax.enter_tax_number')}}</div>
          </div>
        </div>
        <div class="Group Group--button">
          <button-bar :is-busy="isSaving" :show-cancel="false" label-confirm="Save" @confirm="save"></button-bar>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss">

</style>
