<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      accountName: state => state.session?.account?.name,
    }),
  },
};
</script>

<template>
  <div class="ContentWrap">
    <div class="HeaderBar">
      <div class="SectionTitleAndTabs Container">
        <menu class="PageHeaderTabs">
          <router-link class="PageHeader-tab--first" to="/billing/invoices"><i
              class="Icon-Header">receipt</i>{{$t('global.invoices')}}</router-link>
          <router-link class="PageHeader-tab" to="/billing/payments"><i
              class="Icon-Header">payments</i>{{$t('global.payments')}}</router-link>
          <router-link class="PageHeader-tab" to="/billing/credit-cards"><i
              class="Icon-Header">credit_card</i>{{$t('global.credit_cards')}}</router-link>
        </menu>
        <div class="AccountMeta--wrap">
          <div class="AccountTitle">{{ accountName }}</div>
        </div>
      </div>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>
