<template>
  <div class="Modal--dialog">
    <div class="Modal-Header">
      <h1>{{ $t('shared_modal-archive.archive') }} {{ title }}</h1>
      <i
        class="Icon-ModalsClose"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <slot>
        <div v-html="text" />
      </slot>
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isArchiving"
        :label-confirm="title"
        type-confirm="danger"
        @cancel="$emit('close')"
        @confirm="archive"
      >
      </button-bar>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isError: false,
      isArchiving: false,
    };
  },

  props: {
    title: { type: String },
    onArchive: { type: Function },
    text: { type: String },
  },

  methods: {
    archive() {

      //Toggle flags
      this.isArchiving = true;
      this.isError = false;

      //Call handler
      this
        .onArchive()
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isArchiving = false);
    },
  },
};
</script>
