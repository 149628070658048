export const ADMIN = 'admin';
export const USER = 'user';

export default [
  {
    value: ADMIN,
    label: 'Admin',
  },
  {
    value: USER,
    label: 'User',
  },
];
