/**
 * Dependencies
 */
import paymentStatuses from '@/constants/payment-statuses';

/**
 * Payment status filter
 */
export default function(value) {

  //Find corresponding item
  const item = paymentStatuses.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
