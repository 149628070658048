import AssetTypeApi from '@/api/asset-type.api';
import BaseModel from '@/models/base.model';

export default class AssetType extends BaseModel {

  constructor(data, parent) {
    super(data, parent);

    Object.defineProperty(this, '$api', {
      enumerable: false,
      get() {
        return AssetTypeApi;
      },
    });
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    //Create
    return this.$api
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    //Update
    return this.$api
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {

    //Get ID
    const {id} = this;

    //Update
    return this.$api
      .remove(id);
  }
}
