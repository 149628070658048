import axios from 'axios';
import cfg from '@/services/cfg';
import auth from '@/services/auth';
import errors from '@/services/errors';
import NetworkError from '@/errors/network';

class HttpService {
  constructor() {

    //Setup axios instance
    this.axios = axios.create({
      baseURL: cfg.api.baseUrl,
      headers: {
        post: {'Content-Type': 'application/json'},
        put: {'Content-Type': 'application/json'},
        patch: {'Content-Type': 'application/json'},
      },
    });
  }

  /**
   * Generic request wrapper
   */
  request(config = {}) {
    this.appendHeaders(config);
    this.convertToJSON(config.params);
    this.convertToJSON(config.data);

    //Perform request
    return this.axios
      .request(config)
      .then(response => response.data)
      .catch(error => this.intercept401Error(error, config))
      .catch(error => {
        if (error.message === 'Network Error') {
          throw new NetworkError();
        }

        if (!error.response) {
          throw error;
        }

        //Convert to response error
        const { response } = error;
        const ErrorClass = errors.getClass(response);

        throw new ErrorClass(response);
      });
  }

  /**
   * Get request wrapper
   */
  get(url, params, config) {
    config = Object.assign({method: 'get', url, params}, config || {});
    return this.request(config);
  }

  /**
   * Delete request wrapper
   */
  delete(url, params, config) {
    config = Object.assign({method: 'delete', url, params}, config || {});
    return this.request(config);
  }

  /**
   * Post request wrapper
   */
  post(url, data, config) {
    config = Object.assign({method: 'post', url, data}, config || {});
    return this.request(config);
  }

  /**
   * Put request wrapper
   */
  put(url, data, config) {
    config = Object.assign({method: 'put', url, data}, config || {});
    return this.request(config);
  }

  /**
   * Patch request wrapper
   */
  patch(url, data, config) {
    config = Object.assign({method: 'patch', url, data}, config || {});
    return this.request(config);
  }

  /**
   * Upload (POST multipart/form-data) request wrapper
   */
  upload(url, data, config) {
    config = Object.assign({
      method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
      url, data,
    }, config || {});
    return this.request(config);
  }

  convertToJSON(data) {

    //Only objects
    if (!data || typeof data !== 'object') {
      return;
    }

    //Copy data
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const isObject = (typeof value === 'object');
        if (value && isObject && typeof value.toJSON === 'function') {
          data[key] = value.toJSON();
        }
      }
    }
  }

  /**
   * Append headers helper
   */
  appendHeaders(config) {
    //Ensure headers set
    if (!config.headers) {
      config.headers = {};
    }

    if (!this.hasAuthHeader(config) && !config.disableAuthHeader) {
      this.appendAuthHeader(config);
    }
  }

  /**
   * Check if we have an authorization header
   */
  hasAuthHeader(config) {
    const { headers } = config;
    return (headers.Authorization || headers.authorization);
  }

  appendAuthHeader(config) {
    //Not authenticated
    if (!auth.hasToken()) {
      return;
    }

    const accessToken = auth.getToken();

    //Append to headers
    Object.assign(config.headers, {
      Authorization: `Bearer ${accessToken}`,
    });
  }

  /**
   * Retry a not authenticated request if possible
   */
  async intercept401Error(error, config) {

    //Not a 401 error or ignoring intercepts?
    const {response} = error;
    if (!response || response.status !== 401 || config.ignore401) {
      throw error;
    }

    //No access token?
    if (!auth.hasToken()) {
      throw error;
    }

    //Ignore subsequent intercepts
    config.ignore401 = true;

    //Refresh
    await auth.refresh();

    //Re-append new auth headers
    this.appendAuthHeader(config);

    //Retry request
    return this.request(config);
  }
}

/**
 * Export singleton instance
 */
export default new HttpService();
