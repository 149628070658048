<template>
  <div class="Modal">
    <div class="Modal-Header">
      <h1>{{$t('error_error.modal_header_obscure')}}</h1>
      <i
        class="Icon-ModalsClose"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">

      <!-- Generic error -->
      <div v-if="isGenericError">
        <div class="error_message">{{$t('error_error.generic_error')}}</div>
      </div>

      <!-- Network error -->
      <div v-if="isNetworkError">
        <div class="error_message">{{$t('error_error.network_error')}}</div>
      </div>

      <!-- Not found error -->
      <div v-if="isNotFoundError">
        <div class="error_message">{{$t('error_error.not_found_error')}}</div>
      </div>

      <!-- Not authorized error -->
      <div v-if="isNotAuthorizedError">
        <div class="error_message">{{$t('error_error.not_authorized_error')}}</div>
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :show-cancel="false"
        label-confirm="Close"
        type-confirm="ok"
        @confirm="$emit('close')"
      ></button-bar>
    </div>
  </div>
</template>

<script>
import {
  NetworkError,
  NotFoundError,
  NotAuthorizedError,
} from '@/errors/index';

export default {
  props: {
    error: {
      type: Error,
    },
  },

  computed: {
    isNetworkError() {
      return (this.error instanceof NetworkError);
    },
    isNotFoundError() {
      return (this.error instanceof NotFoundError);
    },
    isNotAuthorizedError() {
      return (this.error instanceof NotAuthorizedError);
    },
    isGenericError() {
      return (
        !this.isNetworkError &&
        !this.isNotFoundError &&
        !this.isNotAuthorizedError
      );
    },
  },
};
</script>
