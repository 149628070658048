<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderIntegrations',
  computed: {
    ...mapState({
      accountName: state => state.session?.account?.name,
    }),
  },
};
</script>

<template>
  <div class="ContentWrap">
    <div class="HeaderBar">
      <div class="SectionTitleAndTabs Container">
        <menu class="PageHeaderTabs">
          <router-link class="PageHeader-tab--first" to="/integrations/installed"><i class="Icon-Header">download_for_offline</i>{{$t('global.installed_apps')}}</router-link>
          <router-link class="PageHeader-tab" to="/integrations/search"><i class="Icon-Header">search</i>{{$t('header-integrations_overview.marketplace')}}</router-link>
        </menu>
        <div class="AccountMeta--wrap">
          <div class="AccountTitle">{{ accountName }}</div>
        </div>
      </div>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>
