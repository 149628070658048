export default [
  'important_devices',
  'web',
  'account_tree',
  'dataset',
  'view_timeline',
  'view_kanban',
  'hub',
  'router',
  'cell_tower',
  'wifi',
  'lan',
  'route',
  'dynamic_form',
  'dns',
  'public',
  'cloud',
  'cloud_upload',
  'html',
  'abc',
  'data_object',
  'apps',
  'account_balance_wallet',
  'paid',
  'shopping_cart',
  'shopping_bag',
  'monitor_heart',
  'insights',
  'bar_chart',
  'lock',
  'alternate_email',
  'email',
  'inventory_2',
  'folder',
  'description',
  'menu_book',
  'autorenew',
  'summarize',
  'chrome_reader_mode',
  'map',
  'business_center',
  'book',
  'content_paste',
  'stars',
  'star',
  'lightbulb',
  'rocket_launch',
  'rocket',
  'tour',
  'flag',
  'build_circle',
  'precision_manufacturing',
  'desktop_windows',
  'devices',
  'computer',
  'smartphone',
  'watch',
  'keyboard_alt',
  'mouse',
  'photo_camera',
  'speaker',
  'headphones',
  'call',
  'radio',
  'badge',
  'woman',
  'man_2',
  'face',
  'pets',
  'school',
  'sports_basketball',
  'music_note',
  'movie',
  'water_drop',
  'eco',
  'spa',
  'forest',
  'emoji_objects',
  'solar_power',
  'wind_power',
  'battery_charging_full',
  'ev_station',
  'electric_car',
  'electric_bike',
  'electric_scooter',
  'gas_meter',
  'oil_barrel',
  'flight',
  'airlines',
  'directions_boat',
  'local_shipping',
  'directions_car',
  'directions_bus',
  'directions_train',
  'airport_shuttle',
  'agriculture',
  'two_wheeler',
  'pedal_bike',
  'bike_scooter',
  'account_balance',
  'location_city',
  'domain',
  'factory',
  'storefront',
  'home',
  'other_houses',
  'villa',
  'bed',
  'chair',
  'countertops',
  'bathroom',
];
