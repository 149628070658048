<script>
import moment from 'moment';
import ModalView from '@/components/shared/modal-view';
import assetDisplayName from '@/helpers/asset-display-name';

export default {
  components: { ModalView },

  props: {
    account: { type: Object },
    invoice: { type: Object },
    onEdit: { type: Function },
  },

  data() {
    return {
      assetDisplayName,
    };
  },

  computed: {
    /**
     * Gives us a human-representation of when the invoice is due
     * e.g. "In 10 days"
     * @return {String} When invoice is due, relative to today
     */
    dueInHumanTerms() {
      let today = moment();
      let dueDate = this.invoice.dueDate;

      return dueDate.from(today);
    },

    amountPaid() {
      return (
        (this.invoice.payment?.amountPaid) ||
        0
      );
    },

    amountToPay() {
      return this.invoice.totalInclTax - this.amountPaid;
    },
  },
};
</script>

<template>
  <modal-view :item="invoice" icon="receipt" :singular="`#${invoice.number}`" byline="Invoice" :on-edit="onEdit"
    :saveable="false" @close="$emit('close')">
    <div class="Section">
      <div class="Row-M">
        <div class="Modal-col--L">
          <div class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.invoice_status') }}</label>
            <div class="Modal--status">
              <div v-if="invoice.isPaid">
                <span class="Modal--Status-ok">
                  <i class="Icon-Base">payments</i>
                  {{ $t('global.invoice.paid') }}
                </span>
              </div>
              <div v-if="!invoice.isPaid && invoice.status === 'sent'">
                <span class="Modal--Status-info">
                  {{ $t('invoices_modals_view.status_sent_and_unpaid') }}
                </span>
              </div>
              <div v-if="!invoice.isPaid && invoice.status === 'draft'">
                <span class="Modal--Status-warning">
                  <i class="Icon-Base">edit</i>
                  {{ $t('invoices_modals_view.status_draft') }}
                </span>
              </div>
              <div v-if="invoice.status === 'void'">
                <span class="Modal--Status-mute">
                  <i class="Icon-Base">block</i>
                  {{ $t('invoices_modals_view.status_void') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="Modal-col--M">
          <div class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.paid') }}</label>
            <div class="Modal-Value">{{ amountPaid | currency }}</div>
          </div>
          <div class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.remaining') }}</label>
            <div class="Modal-Value">{{ amountToPay | currency }}</div>
          </div>
        </div>
        <div class="Modal-col--M">
          <div v-if="!invoice.isPaid" class="Modal--item">
            <label class="Label Label--large" :class="{ 'text-danger': invoice.isOverdue }">{{
                $t('invoices_modals_view.due')
            }}
              {{ dueInHumanTerms }}</label>
            <div class="ContentFontLarge">{{ amountToPay | currency }}</div>
          </div>
        </div>
      </div>

      <div class="Row-M">
        <div class="Modal-col--L">
          <div class="Modal--item">
            <label class="Label">{{ $t('global.client') }}</label>
            <div class="Modal-Value">
              <modal-link :to="`/directory/clients/view/${invoice.contact._id}`"
                :from="`/billing/invoices/view/${invoice.id}`" from-name="invoice">
                {{ invoice.contact.name }}
              </modal-link>
            </div>
          </div>
        </div>

        <div class="Modal-col--M">
          <div class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.sent') }}</label>
            <div class="Modal-Value">{{ invoice.date | moment("ll") }}</div>
          </div>
        </div>

        <div class="Modal-col--M">
          <div v-if="!invoice.isPaid" class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.date_due') }}</label>
            <div class="Modal-Value" :class="{ 'text-danger': invoice.isOverdue }">
              {{ invoice.dueDate | moment("ll") }}
            </div>
          </div>
          <div v-else class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.paid') }}</label>
            <div class="Modal-Value">
              {{ invoice.paidDate | moment("ll") }}
            </div>
          </div>
        </div>
      </div>

      <hr class="Hr--modal" />
      <div class="Row-M">
        <div class="Modal-col--L">
        </div>
        <div class="Modal-col--M">
          <div class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.subtotal_no_tax') }}</label>
            <div class="Modal-Value">{{ invoice.totalExclTax | currency }}</div>
          </div>
          <div class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.tax_amount') }}</label>
            <div class="Modal-Value">{{ invoice.taxAmount | currency }}</div>
          </div>
        </div>
        <div class="Modal-col--M">
          <div class="Modal--item">
            <label class="Label">{{ $t('invoices_modals_view.total_with_tax') }}</label>
            <div class="ContentFontLarge">{{ invoice.totalInclTax | currency }}</div>
          </div>
        </div>
      </div>

    <hr class="Hr--modal" />
      <div class="Modal--item">
        <label class="Label">{{ $t('invoices_modals_view.related_contract') }}</label>
        <div class="Modal-Value">
          <modal-link :to="`/workspace/services/view/${invoice.contract.id}`"
            :from="`/billing/invoices/view/${invoice.id}`" from-name="invoice">
            {{ invoice.contract.name }}
          </modal-link>
        </div>
      </div>
    </div>

    <div class="Section">
      <div class="ModalList--plain">
        <div v-if="invoice.items && invoice.items.length > 0">
          <div class="ModalList-invoiceitem" v-for="item in invoice.items" :key="item.id">
            <div class="ModalList-topRow">
              {{ item.offering.name }} – {{ item.price | currency }}
            </div>
            <div class="Modal-Value-description">
              {{ item.description }}
            </div>
            <div v-if="item.asset" class="ModalList-bottomRow">
              {{ item.asset.name }} <span class="ModalList-assetType">({{ assetDisplayName(item.asset) }})</span>
            </div>
          </div>
        </div>
        <div v-else class="Modal-Panel">
          <div class="Row-0">
            <div class="ModalList--noItems">{{ $t('invoices_modals_view.no_items_attached') }}</div>
          </div>
        </div>
      </div>
    </div>
  </modal-view>
</template>
