import http from '@/services/http';
import AuditLog from '@/models/audit-log.model';

export default {
  query(filter) {
    return http
      .get('/auditLog', filter)
      .then(logs => AuditLog.createFrom(logs, null, 'logs'));
  },

  recent() {
    return http
      .get('/auditLog/recent')
      .then(logs => AuditLog.createFrom(logs));
  }
};
