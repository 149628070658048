'use strict';

/* Used by dashboard panels */
module.exports = async (module, api, map) => {
  const stats = [];

  const data = await api.summary();

  for (const key in map) {
    if (typeof data[key] === 'undefined') {
      continue;
    }
    const value = data[key].total || 0;
    const meta = map[key];

    const label = (typeof (meta) === 'object' && meta.label) || key;
    const query = typeof (meta) === 'object' && meta.query;
    const { status, statusIfZero } = meta;

    if (!(meta.hideIfZero && value === 0)) {
      stats.push({
        value,
        label,
        query,
        status: value ? status : statusIfZero,
      });
    }
  }

  return stats;
};
