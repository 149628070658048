import NoticesContainer from './notices-container';
import Notice from './notice';

class NoticeService {
  constructor() {

    //Set default options for notices
    this.setDefaults({
      hideAutomatically: true,
      hideOnClick: true,
      hideAfter: 5000,
      icon: '',
      hasSpinner: false,
      onClick: function() {},
    });
  }

  /**
   * Set container
   */
  setContainer(container) {
    this.container = container;
  }

  /**
   * Set defaults
   */
  setDefaults(defaults) {
    this.defaults = defaults;
  }

  /**
   * Show a notice
   */
  show(message, icon, options) {

    //Options given as second parameter?
    if (icon && typeof icon === 'object') {
      options = icon;
      icon = '';
    }

    //Create notice object
    const notice = Object.assign({message, icon}, this.defaults, options || {});

    //Auto hide?
    if (notice.hideAutomatically && notice.hideAfter) {
      setTimeout(() => {
        this.container.hide(notice);
      }, notice.hideAfter);
    }

    //Add to container
    this.container.show(notice);

    //Return API to allow closing the notice
    return {
      hide: () => {
        this.container.hide(notice);
      },
    };
  }
}

/**
 * Notice plugin
 */
const Plugin = {

  /**
   * Install plugin
   */
  install(Vue, options) {

    //Merge options
    options = Object.assign({
      componentName: 'notice',
      containerComponentName: 'noticesContainer',
    }, options || {});

    //Get data
    const {componentName, containerComponentName} = options;

    //Add instance service
    Vue.prototype.$notice = new NoticeService();

    //Register components
    Vue.component(containerComponentName, NoticesContainer);
    Vue.component(componentName, Notice);
  },
};

/**
 * Export plugin
 */
export default Plugin;
