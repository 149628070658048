<script>

import ContractApi from '@/api/contract.api';

export default {
  data() {
    return {
      stats: {
        contracts: [],
      },
      isLoading: false,
    };
  },

  computed: {
    totalRevenue() {
      return this.monthlyTotal(this.stats?.contracts?.revenue?.reduce((total, item) => total + item.daily_total, 0));
    },
  },

  async created() {
    this.isLoading = true;
    this.stats.contracts = await ContractApi.summary();
    if (this.stats.contracts.revenue) {
      this.stats.contracts.revenue.sort((a, b) => a.offering.localeCompare(b.offering));
    }
    this.isLoading = false;
  },

  methods: {
    monthlyTotal(dailyTotal) {
      return dailyTotal * 365 / 12;
    },
  },

};
</script>

<template>
  <div v-if="totalRevenue > 0" class="PanelSection">
    <div class="Dashlist-Panel--info">
      <div class="PanelHeader">
        <div class="PanelHeader-title">
          <div>{{$t('dashboard_dashboard-items_dashlist-revenue.services_revenue')}}</div>
          <div class="Description">{{$t('dashboard_dashboard-items_dashlist-revenue.excl_tax')}}</div>
        </div>
        <div class="PanelHeader-options">
        </div>
      </div>
      <div class="DashList">
        <div v-if="totalRevenue === 0" class="Dashlist-Empty">{{$t('dashboard_dashboard-items_dashlist-revenue.no_revenue')}}</div>
        <spinner v-if="isLoading" class="Dashlist-Spinner" />
        <div v-if="!isLoading && totalRevenue !== 0" class="Dashlist-Item--total" >
          <div class="Dashlist-Item--name">{{$t('dashboard_dashboard-items_dashlist-revenue.total')}}</div>
          <div class="Dashlist-Item--value" >{{totalRevenue | currency({fractionCount: 0})}}</div>
        </div>
        <div v-for="(item, index) in stats.contracts.revenue" v-bind:key="index">
          <router-link class="Dashlist-Item" :to="{
            path: '/workspace/services',
            query: { offering: item.offering }
          }">
            <div class="Dashlist-Item--name">
              {{item.offering}}
            </div>
            <div class="Dashlist-Item--value">
              {{monthlyTotal(item.daily_total) | currency({fractionCount: 0})}}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
