import http from '@/services/http';
import Integration from '@/models/integration.model';

export default {
  query(filter) {
    return http
      .get('/integration', filter)
      .then(integrations => Integration.createFrom(integrations));
  },

  findByType(type, accessToken) {
    return http
      .get(`/integration/${type}`, { accessToken })
      .then(data => Integration.createFrom(data));
  },

  update(id, data) {
    return http
      .put(`/integration/${id}`, data);
  },

  connect(type, data) {
    return http
      .post(`/integration/${type}`, data);
  },

  disconnect(type) {
    return http
      .delete(`/integration/${type}`);
  },
};
