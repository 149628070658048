<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      stripeConnected: false,
    };
  },
}
</script>

<template>
  <div class="Invoice">
    <div class="Invoice-Header">
      <h1 class="Invoice-Title">Tax Invoice from {{invoice.account.name}}</h1>
      <div class="Spacer3XL" />
    </div>
    <div class="Invoice-Meta">
        <div class="Invoice-Reciever">
        <div class="Invoice-Label">For</div>
        <div class="Invoice-Address">
          {{invoice.contact.name}}
          <div>{{invoice.contact.address}}</div>
          <div>{{invoice.contact.email}}</div>
        </div>
      </div>
      <div class="Invoice-Sender">
        <div>
          <div class="Invoice-Label">From</div>
          <div>{{invoice.account.name}}</div>
          <div>{{invoice.account.address}}</div>
          <div>{{invoice.account.email}}</div>
        </div>
        <div>
          <div class="Invoice-Label">Tax number</div>
          <div>{{invoice.account.name}}</div>
        </div>
      </div>
      <div class="Invoice-Summary">
        <div class="Invoice-Detail--Large">
          <div class="Invoice-Detail-Label">Invoice number</div>
          <div class="Invoice-Value--emphasize">{{invoice.number}}</div>
        </div>
        <div class="Invoice-Detail">
          <div class="Invoice-Detail-Label">Invoice date</div>
          <div class="Invoice-Value--emphasize">{{invoice.createdAt | moment('ll')}}</div>
        </div>
        <div>
          <hr class="Invoice-Hr" />
          <div class="Invoice-Detail--Large">
            <div class="Invoice-Detail-Label">Total due</div>
            <div class="Invoice-Value--emphasize">
              {{invoice.totalInclTax | currency }}
            </div>
          </div>
          <div class="Invoice-Detail">
            <div class="Invoice-Detail-Label">Due date</div> <span class="Invoice-Value--emphasize">{{invoice.dueDate | moment('ll')}}</span></div>
        </div>
      </div>
    </div>
    <hr class="Invoice-Hr" />

    <div v-for="item in invoice.items" class="Invoice-Item">
      <div class="Invoice-ItemInfo">
        <div class="Invoice-ItemDetails">
          <div class="Invoice-ItemName--wrap">
            {{item.offering.name}}
            <span class="Invoice-Asset">
              — {{item.asset.name}} ({{item.asset.type.name}})
            </span>
          </div>
            <div  class="Invoice-ItemDesc">
              {{item.description}}Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </div>
            <div v-if="item.description" class="Invoice-ItemDesc">
              {{item.description}}
            </div>
          </div>

        </div>
        <div class="Invoice-ItemAmount">
          <div class="Invoice-ItemTotal">
            {{item.price | currency}} excl tax
          </div>
        </div>
    </div>
    <hr class="Invoice-Hr" />
    <div class="Invoice-Totals">
      <div class="Invoice-TotalLine">
        <div class="Invoice-Detail-Label">Subtotal</div>
        <div class="Invoice-Value">{{invoice.totalExclTax | currency }}</div>
      </div>
      <div class="Invoice-TotalLine">
        <div class="Invoice-Detail-Label">Tax</div>
        <div class="Invoice-Value">{{invoice.taxAmount | currency }}</div>
      </div>
      <div class="Invoice-TotalLine">
        <div class="Invoice-Detail-Label">Paid</div>
        <div class="Invoice-Value">(*add) 0</div>
      </div>
      <div class="Invoice-TotalLine--Bordered">
        <div class="Invoice-Detail-Label">Total due</div>
        <div class="Invoice-Value--emphasize">{{invoice.totalInclTax | currency }}</div>
      </div>
    </div>
    <div class="Invoice-Footer">
      <div class="Invoice-Footer--content">
      <div v-if="invoice.account.settings.bankDetails" class="Bank-Details">
        <div class="Invoice-FooterItem"><strong>Bank Details</strong> — {{ invoice.account.settings.bankDetails }}</div>
      </div>
      <div v-if="invoice.account.settings.invoiceTerms" class="Invoice-Terms">
        <div class="Invoice-FooterItem"><strong>Invoice Terms</strong> — {{invoice.account.settings.invoiceTerms}}</div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.Invoice {
  font-size: $fontSizeS;
  padding: 50px 40px 50px;
}

.Invoice-Header {
  text-align: left;
}

.Invoice-Title {
  padding: 0;
  margin: 0;
  font-size: $fontSizeXL;
}
.Invoice-Subtitle {
  padding: 0;
  margin: 0 0 $spacing2XL;
  text-align: left;
  font-size: $fontSizeS;
  font-weight: $fontWeightNormal;
}

.Invoice-Meta {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, .75fr));
  grid-gap: $spacing2XL;
  margin-bottom: $spacing4XL;
}

.Invoice-Sender,
.Invoice-Receiver,
.Invoice-Summary {
  display: flex;
  flex-direction: column;
  grid-gap: $spacingL;
}

.Invoice-Summary {
  grid-gap: $spacingS;
}

.Invoice-Summary .Invoice-Detail,
.Invoice-Detail--Large {
  display: flex;
  justify-content: space-between;
}

.Invoice-Totals {
  display: flex;
  flex-direction: column;
  gap: $spacingXS;
  max-width: fit-content;
  min-width: 14rem;
  margin-left: auto;
}

.Invoice-TotalLine,
.Invoice-TotalLine--Bordered {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
}
.Invoice-TotalLine--Bordered {
  border-top: 1px solid $colorText;
  padding-top: $spacingS;
}

.Invoice-Label,
.Invoice-Detail-Label {
  margin-bottom: $spacingXS;
  font-weight: $fontWeightBold;
}

.Invoice-col--detail {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 24rem;
  width: 100%;
}

.Invoice-Detail--Large {
  font-size: $fontSizeL;
}

.Invoice-Value--emphasize {
  font-weight: $fontWeightBold;
}

.Invoice-DetailTotal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.Invoice-TaxSubline {
    font-size: $fontSizeXS;
    font-weight: $fontWeightNormal;
    display: block;
  }

.Invoice-Terms {
  font-size: $fontSizeXS;
}

hr.Invoice-Hr {
  margin-bottom: $spacingXL;
  border-bottom: 1px solid $colorText;
}

.Invoice-Item {
  margin-bottom: $spacing2XL;
  display: flex;

  .Invoice-ItemInfo {
    flex: 4;
    .Invoice-ItemName--wrap {
      font-weight: $fontWeightBold;
      font-size: $fontSizeM;
    }

    .Invoice-ItemDetails {
      display: flex;
      flex-direction: column;
      gap: $spacingXS;
    }

    .Invoice-ItemDesc {
      text-align: left;
    }

    .Invoice-Asset {
    }
  }
  .Invoice-ItemAmount {
    display: flex;
    flex: 1;
    gap: $spacingS;
    margin-left: auto;
    align-items: baseline;
    font-size: $fontSizeS;
    font-weight: $fontWeightBold;
    justify-content: flex-end;
    .Invoice-ItemTotal {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.Invoice-Footer {
  margin-top: 3rem;
  padding-top: $spacingXL;
  border-top: 1px solid $colorText;
}

.Invoice-Footer--content {
  display: flex;
  flex-direction: column;
  grid-gap: $spacingM;
}

@media (max-width: 768px) {
  .Invoice-Meta {
    grid-template-columns: 1fr;
  }

  .Invoice-Summary {
    order: 3;
    margin-top: 20px;
  }

  .Invoice-Item {
    display: block;
  }

  .Invoice-ItemAmount {
    margin-top: $spacingXL;

  }
}
</style>