<script>
import RecentUpdates from './dashboard-items/recent-updates';

export default {
  components: {
    RecentUpdates,
  },
};
</script>

<template>
  <div class="Dash--col Dash--col-extra">
    <recent-updates />
  </div>
</template>

<style scoped lang="scss">

.Dash--recentUpdates,
.Dash--tasksEvents {
  display: none;
  @include breakPointSmall {
    display: block;
  }
}

.Dash--recentUpdates {
  padding: $spacingS 0 $spacing4XL;
}
</style>
