<script>
import { required, minValue } from 'vuelidate/lib/validators';
import moment from 'moment-timezone';
import InvoiceStatuses from '@/constants/invoice-statuses';
import { DRAFT } from '@/constants/invoice-statuses';
import ModalEdit from '@/components/shared/modal-edit';

export default {
  name: 'EditInvoice',

  components: { ModalEdit },

  data() {
    return {
      model: {
        status: null,
        contact: null,
        number: null,
        date: null,
        dueDate: null,
        taxPercentage: null,
        totalExclTax: null,
      },
      statuses: InvoiceStatuses,
    };
  },

  props: {
    account: { type: Object },
    invoice: { type: Object },
    onSave: { type: Function },
    onRemove: { type: Function },
  },

  validations: {
    model: {
      status: { required },
      contact: { required },
      taxPercentage: {
        minValue: minValue(1),
      },
      totalExclTax: {
        required,
        minValue: minValue(1),
      },
    },
  },

  created() {
    //Create model subset
    this.model = this.invoice.extract(Object.keys(this.model));

    //Set default tax percentage
    if (this.model.taxPercentage === undefined) {
      this.model.taxPercentage = this.account.taxPercentage;
    }

    //Set default status for new invoices
    if (!this.model.status) {
      this.model.status = DRAFT;
    }

    //Set invoice date
    if (!this.model.date) {
      this.model.date = moment().startOf('day');
    }

    if (!this.model.dueDate) {
      this.model.dueDate = this.model.date.clone().add(3, 'weeks');
    }
  },
};
</script>

<template>
  <modal-edit singular="invoice" :model="model" :validation="$v" :is-edit="true" :on-save="onSave"
    OFF:on-remove="onRemove" @close="$emit('close')">
    <div class="Form">
      <div class="Group">
        <div class="Group-Cols">
          <div class="Group-Col">
            <label class="Label">
              {{$t('invoices_modals_edit.invoice_number')}}
            </label>
            <input class="Input-100" type="number" min="1" step="1" v-model="model.number" disabled
              :placeholder="$t('invoices_modals_edit.auto_generated')">
          </div>
          <div class="Group-Col">
            <label class="Label">{{$t('invoices_modals_edit.invoice_status')}}</label>
            <select-box :model="model.status" :options="statuses" @change="model.status = $event.value" />
          </div>
        </div>
      </div>
      <div class="Group">
        <div class="Group-Cols">
          <div class="Group-Col">
            <label class="Label">{{$t('invoices_modals_edit.client')}}</label>
            <input class="Input-100" disabled :value="model.contact.name" />
          </div>
        </div>
      </div>
      <div class="Group">
        <div class="Group-Cols">
          <div class="Group-Col">
            <label class="Label">{{$t('invoices_modals_edit.invoice_date')}}</label>
            <date-picker isDisabled="true" :date="model.date" :can-clear="true" @change="model.date = $event.date" />
          </div>
          <div class="Group-Col">
            <label class="Label">{{$t('invoices_modals_edit.due_date')}}</label>
            <date-picker :date="model.dueDate" :can-clear="true" @change="model.dueDate = $event.date" />
          </div>
        </div>
      </div>
      <div class="Group">
        <div class="Group-Cols">
          <div class="Group-Col">
            <label class="Label">{{$t('invoices_modals_edit.total_excl')}} ({{account.taxType}})</label>
            <input disabled class="Input-100" type="number" min="0" step="0.01" v-model="model.totalExclTax">
          </div>
        </div>
      </div>

      <div class="Group">
        <label class="Label">{{$t('invoices_modals_edit.invoiced_items')}}</label>
        <div v-if="invoice.items && invoice.items.length" class="ModalList">
          <div class="ModalList-header">
            <div class="Modal-col--M">
              <label>{{$t('invoices_modals_edit.invoiced_items_name')}}</label>
            </div>
            <div class="Modal-col--XS">
              <label>{{$t('invoices_modals_edit.invoiced_items_amount')}}</label>
            </div>
          </div>
          <div class="ModalList-Row" v-for="item in invoice.items" :key="item.id">
            <div class="Modal-col--M">
              <span>{{item.offering.name}}</span>
            </div>
            <div class="Modal-col--XS">
              <span>{{item.price | currency}}</span>
            </div>
          </div>
        </div>
        <span v-else>{{$t('invoices_modals_edit.no_items_attached')}}</span>
      </div>
    </div>
  </modal-edit>
</template>
