import { NONE } from '@/constants/recurrence-intervals';
import { NEVER, END_DATE, NUM_CYCLES } from '@/constants/recurrence-endings';

/**
 * Recurrence ends filter
 */
export default function(recurrence) {

  //No recurrence or not recurring
  if (!recurrence || recurrence.interval === NONE) {
    return '';
  }

  //Get data
  const {ends, numCycles, endDate} = recurrence;

  //Determine label
  switch (ends) {
    case END_DATE:
      return `On ${endDate.format('DD-MM-YYYY')}`;
    case NUM_CYCLES:
      return `After ${numCycles} cycles`;
    case NEVER:
    default:
      return 'Never';
  }
}
