import http from '@/services/http';
import Plan from '@/models/plan.model';

export default {
  query(filter) {
    return http
      .get('/plan', filter)
      .then(data => Plan.createFrom(data, null, 'plans'));
  },

  list(filter) {
    return http
      .get('/plan/list', filter)
      .then(plans => Plan.createFrom(plans));
  },

  findById(id) {
    return http
      .get(`/plan/${id}`)
      .then(data => Plan.createFrom(data));
  },

  create(data) {
    return http
      .post(`/plan`, data);
  },

  update(id, data) {
    return http
      .put(`/plan/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/plan/${id}`);
  },
};
