<script>
import markMatches from '@/helpers/mark-matches';
import {
  ENTER, ESC, TAB, LEFT, UP, RIGHT, DOWN,
  SHIFT, CTRL, ALT, CMDLEFT, CMDRIGHT,
} from '@/constants/key-codes';

export default {
  inheritAttrs: false,

  data() {
    return {
      isSearching: false,
      isShowingResults: false,
      isShowingAddnew: false,
      addingNewRecord: false,
      hasOptions: false,
      searchQuery: '',
      selectionIndex: -1,
      currentSearch: 0,
      lastProcessedSearch: -1,
    };
  },

  props: {
    model: {
      type: [String, Number, Object],
      default: null,
      validator: prop => (
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        typeof prop === 'object' ||
        prop === null
      ),
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: 'label',
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    asObject: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    disabledLabel: {
      type: String,
      default: '',
    },
    debounce: {
      type: Number,
      default: 250,
    },
    minLength: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: '',
    },
    bindKey: {
      type: String,
      default: 'value',
    },
    searchHandler: {
      type: Function,
      default: () => null,
    },
    newable: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.boundClickHandler = this.documentClickHandler.bind(this);
    this.body = document.getElementsByTagName('body')[0];

    //Apply global click handler
    //NOTE: applied on body, so that it can prevent global document handlers
    this.body.addEventListener('click', this.boundClickHandler);

    this.onChanges();
  },

  destroyed() {
    this.body.removeEventListener('click', this.boundClickHandler);
  },

  watch: {
    model() {
      this.onChanges();
    },
    options() {
      this.onChanges();
    },
  },

  methods: {
    onChanges() {
      const {model, options} = this;

      let option = model;
      if (this.options.length) {
        option = this.findOption(model, options);
      }

      //Determine selected label and check if we have options
      this.searchQuery = this.getLabelValue(option);
      this.hasOptions = options.length > 0;
    },

    isControlInput(event) {
      const keys = [
        UP, DOWN, LEFT, RIGHT, ENTER, ESC, TAB,
        SHIFT, CTRL, ALT, CMDLEFT, CMDRIGHT,
      ];
      return (keys.indexOf(event.keyCode) !== -1);
    },

    onKeyUp(event) {

      //Ignore control input events
      if (this.isControlInput(event)) {
        return;
      }

      if (this.addingNewRecord) {
        return this.inputNew();
      }

      this.search();
    },

    onKeyDown(event) {
      const {keyCode} = event;

      //Hide results
      if (keyCode === ESC || keyCode === TAB) {
        this.hideResults();
      }
    },

    /**
     * Pass input new value back
     */
    inputNew() {
      let inputValue;
      let option;

      if (this.searchQuery) {
        inputValue = this.searchQuery;
        option = {createNew: true};
      }
      else {
        this.addingNewRecord = false;
        this.isShowingAddnew = false;
      }
      this.$emit('change', {value: inputValue, option});
    },

    /**************************************************************************
     * Search handling
     ***/

    /**
     * Debounced wrapper for search
     */
    search() {

      //Get data
      const {debounce, minLength} = this;
      const query = (this.searchQuery || '').trim();

      //Cancel any previous pending search
      clearTimeout(this.pendingSearch);

      //Emit changes
      this.$emit('query', {query});
      this.$emit('change', { value: null, option: null });

      //Minimum length guard
      if (!query || query.length < minLength) {
        this.clearResults();
        return;
      }

      //Check debounce value
      if (!debounce) {
        return this.performSearch(query);
      }

      //Debounce the search
      return new Promise(resolve => {
        this.pendingSearch = setTimeout(() => {
          this.pendingSearch = null;
          const search = this.performSearch(query);
          resolve(search);
        }, debounce);
      });
    },

    /**
     * Perform actual search
     */
    async performSearch(query) {

      //Toggle flag
      this.isSearching = true;

      //Dispatch search
      const results = await this
        .dispatchSearch(query)
        .finally(() => this.isSearching = false);

      //Old search that came back
      if (++this.currentSearch <= this.lastProcessedSearch) {
        return;
      }

      //Store results and set last processed search
      this.results = results || [];
      this.lastProcessedSearch = this.currentSearch;
      if (!this.results.length && this.newable) {
        this.isShowingResults = true;
        return this.isShowingAddnew = true;
      }

      this.showResults();
    },

    addNew() {
      this.addingNewRecord = true;
      this.isShowingAddnew = false;
      this.isShowingResults = false;
      this.inputNew();
    },

    /**
     * Dispatch the search with the appropriate search handler
     */
    dispatchSearch(query) {

      //Given search handler
      if (this.searchHandler) {
        return this.searchHandler(query);
      }

      //Static options
      return this.searchInOptions(query);
    },

    /**
     * Search in supplied options
     */
    searchInOptions(query) {

      //Create regex and filter options that match
      const regex = new RegExp('(?:^|\\b)(' + query + ')', 'i');
      const items = this.options
        .filter(option => {
          const label = this.getLabelValue(option);
          return regex.test(label);
        });

      //Wrap in promise for uniform API
      return Promise.resolve(items);
    },

    /**************************************************************************
     * Results handling
     ***/

    showResults() {
      if (this.results.length > 0) {
        this.isShowingResults = true;
      }
    },

    hideResults() {
      this.isShowingResults = false;
    },

    toggleResults() {
      if (this.isShowingResults) {
        this.hideResults();
      }
      else {
        this.showResults();
      }
    },

    clearResults() {
      this.results = [];
      this.isShowingResults = false;
      this.isShowingAddnew = false;
    },

    selectResult(option, index) {
      this.hideResults();

      //Get the new model value and call on change handler
      const value = this.getModelValue(option, index);
      this.$emit('change', {value, option, index});
    },

    /**
     * Check if given index is the selection index
     */
    isSelection(index) {
      return (this.selectionIndex === index);
    },

    /**
     * Set the selection index
     */
    setSelection(index) {
      this.selectionIndex = index;
    },

    confirmSelection(index) {

      //Get data
      const {results, selectionIndex} = this;

      //If index not given, use current selection index
      if (typeof index === 'undefined') {
        index = selectionIndex;
      }

      //Validate index
      if (
        typeof index === 'undefined' ||
        typeof results[index] === 'undefined'
      ) {
        return;
      }

      //Get option
      const option = results[index];

      //Select result now
      this.selectResult(option, index);
    },

    /**
     * Helper to get the tracking value of an option
     */
    getTrackingValue(option, index) {
      const {trackBy, nullValue} = this;

      //Null value?
      if (option === null) {
        return nullValue;
      }

      //Tracking by index?
      if (trackBy === '$index') {
        return index;
      }

      //Non object? Track by its value
      if (typeof option !== 'object') {
        return option;
      }

      //Must have tracking property
      if (!trackBy) {
        throw new Error('Missing track-by property for select box');
      }

      //Validate property
      if (typeof option[trackBy] === 'undefined') {
        throw new Error(`Unknown property '${trackBy}' for select box tracking`);
      }

      //Return the property
      return option[trackBy];
    },

    getModelValue(option, index) {
      const {isNullable, nullValue, asObject} = this;

      //If nullable and null option given, return null value
      if (isNullable && option === null) {
        return nullValue;
      }

      //If returning as object, return the selected option
      if (asObject) {
        return option;
      }

      //Otherwise, return the tracking value of the given option
      return this.getTrackingValue(option, index);
    },

    getLabelValue(option) {
      const { labelBy, nullLabel } = this;

      //Null value?
      if (option === null || typeof option === 'undefined') {
        return nullLabel;
      }

      //Non object? Use its value
      if (typeof option !== 'object') {
        return option;
      }

      //Must have label property
      if (!labelBy) {
        throw new Error('Missing label-by property for select box');
      }

      //Validate property
      if (typeof option[labelBy] === 'undefined') {
        throw new Error(`Unknown property '${labelBy}' for select box label`);
      }

      //Return the property
      return option[labelBy];
    },

    /**
     * Get result value (marked)
     */
    getResultValue(option) {
      const {searchQuery} = this;
      const label = this.getLabelValue(option);

      //Mark matches
      return markMatches(label, searchQuery);
    },

    /**
     * Find the selected option based on the model value
     */
    findOption(model, options) {

      //Get data
      const {trackBy, nullValue} = this;

      //Nothing selected or null value selected?
      if (typeof model === 'undefined' || model === nullValue) {
        return null;
      }

      //Tracking by index?
      if (trackBy === '$index') {
        if (typeof options[model] !== 'undefined') {
          return options[model];
        }
        return null;
      }

      //Get the model value
      let modelValue = this.getTrackingValue(model, model);

      //Find matching option
      return options
        .find((option, index) => {
          let optionValue = this.getTrackingValue(option, index);
          return (modelValue === optionValue);
        });
    },

    documentClickHandler(event) {
      //Not showing options or clicked on the selectbox?
      if (!this.isShowingResults || this.$el.contains(event.target)) {
        return;
      }

      this.hideResults();
      event.preventDefault();
      event.stopPropagation();
    },
  },
};
</script>

<template>
  <div class="TypeAhead">
    <div class="InputWrapper">
      <div class="InputLookupWrapper">
        <input
          v-bind="$attrs"
          class="Input-100"
          type="text"
          v-model="searchQuery"
          :placeholder="placeholder"
          :title="isDisabled ? disabledLabel : ''"
          :disabled="isDisabled"
          @keydown="onKeyDown($event)"
          @keyup="onKeyUp($event)"
        >
        <span v-if="addingNewRecord" class="Pill-info">{{$t('modal.adding_new')}}</span>
        <spinner class="Spinner--input" v-if="isSearching"></spinner>
      </div>
    </div>
    <ul class="TypeAhead-results" v-if="isShowingResults">
      <li
        v-for="(result, index) in results"
        v-html="getResultValue(result)"
        :key="result[bindKey]"
        :class="{selected: isSelection(index)}"
        @mouseover="setSelection(index)"
        @click.prevent="confirmSelection(index)"
      ></li>
      <li v-if="newable" @click.prevent="addNew()" class="TypeAhead-addnew">{{$t('modal.add_new')}}</li>
    </ul>
  </div>
</template>

<style lang="scss">
.TypeAhead {
  position: relative;
  color: $colorPrimary;
  .Input {
    outline: none;
  }
}
.TypeAhead-results {
  @include dropdownBox;
  -webkit-box-shadow: 0px $spacingS  $spacingL 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px $spacingS  $spacingL 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0.35rem 0.35rem;
  border: 1px solid #d2d5de;
  width: 100%;
  background: white;
  li {
    @include dropdownBoxItem;
  }
  .TypeAhead-addnew {
    color: $colorPrimary;
    font-weight: $fontWeightBold;
  }
}

.InputLookupWrapper > .Pill-info {
  position: absolute;
  right: 10px;
  top: 8px;
  font-weight: $fontWeightMedium;
}
</style>
