import BaseModel from './base.model';
import ContractApi from '@/api/contract.api';
import Recurrence from './recurrence.model';
import Contact from './contact.model';
// import Project from './project.model';
import { NONE } from '@/constants/recurrence-intervals';
import { NEVER } from '@/constants/recurrence-endings';

const defaults = {
  recurrence: {
    interval: NONE,
    ends: NEVER,
    endDate: null,
  },
};

export default class Contract extends BaseModel {
  getModelName() {
    return 'Contract';
  }

  constructor(data, parent) {
    super(data, parent);

    Object.defineProperty(this, 'isRecurring', {
      get() {
        return (this.recurrence && this.recurrence.interval !== NONE);
      },
    });
  }

  fromJSON(json) {
    //Extend from defaults
    json = Object.assign({}, defaults, json || {});

    //Call parent method
    super.fromJSON(json);

    //Convert models
    this.convertToModel('contact', Contact);
    // this.convertToModel('project', Project);
    this.convertToModel('recurrence', Recurrence);

    //Return self
    return this;
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {
    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    //Create
    return ContractApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {
    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    return ContractApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;
    return ContractApi
      .remove(id);
  }

  archive() {
    const { id } = this;
    return ContractApi
      .archive(id);
  }

  unArchive() {
    const { id } = this;
    return ContractApi
      .unArchive(id);
  }
}
