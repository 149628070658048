import i18n from '@/i18n';

// export const DRAFT = 'draft';
export const SENT = 'sent';
export const PAID = 'paid';
export const VOID = 'void';

export default [
  // {
  //   value: DRAFT,
  //   label: `Draft`,
  // },
  {
    value: SENT,
    label: i18n.t('global.invoice.sent_and_unpaid'),
  },
  {
    value: PAID,
    label: i18n.t('global.invoice.paid'),
  },
  {
    value: VOID,
    label: i18n.t('global.invoice.void'),
  },
];
