
/**
 * JSON filter
 */
export default function(obj, spaces = 2) {
  if (typeof obj !== 'object' || obj === null) {
    return '';
  }
  return JSON.stringify(obj, null, spaces);
}
