/**
 * Dependencies
 */
import paymentMethods from '@/constants/payment-methods';

/**
 * Payment method filter
 */
export default function(value) {

  //Find corresponding item
  const item = paymentMethods.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
