import moment from '@/services/moment';

/**
 * Check if given string is a ISO 8601 date string,
 * Returns a moment if it is and null if it's not
 */
export function dateStringToMoment(value) {
  const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/;
  if (value.match(regex)) {
    const date = moment(value, moment.ISO_8601, true);
    if (date.isValid()) {
      return date;
    }
  }
  return null;
}


/**
 * Helper to copy a property
 */
export function copyProperty(obj, key) {
  if (Array.isArray(obj[key])) {
    const arr = obj[key];
    return arr.map((value, key) => copyProperty(arr, key));
  }
  if (obj[key] === null) {
    return null;
  }
  if (obj[key] && typeof obj[key].clone === 'function') {
    return obj[key].clone();
  }
  if (typeof obj[key] === 'object') {
    return Object.assign({}, obj[key]);
  }
  return obj[key];
}

/**
 * Base model
 */
export default class BaseModel {
  constructor(data, parent) {

    //Define parent property
    let _parent = parent;
    Object.defineProperty(this, '$parent', {
      enumerable: false,
      get() {
        return _parent;
      },
      set(parent) {
        _parent = parent;
      },
    });

    //Load data
    this.fromJSON(data);
    this.id = this.id || this._id;
  }

  /**
   * Convert a property to a model
   */
  convertToModel(key, Model) {

    //Empty
    if (typeof this[key] === 'undefined') {
      this[key] = null;
    }

    //Convert to model
    if (Model && this[key] !== null) {
      this[key] = Model.createFrom(this[key], this);
    }
  }

  /**
   * Convert a property to an array of models
   */
  convertToArray(key, Model) {

    //Not an array
    if (!Array.isArray(this[key])) {
      this[key] = [];
    }

    //Convert to models
    if (Model) {
      this[key] = Model.createFrom(this[key], this);
    }
  }

  /**
   * From JSON converter
   */
  fromJSON(json) {

    //Copy data
    if (json && typeof json === 'object') {
      for (const key in json) {
        if (json.hasOwnProperty(key)) {
          this[key] = BaseModel.valueFromJSON(json[key]);
        }
      }
    }

    //Return self
    return this;
  }

  /**
   * To JSON converter
   */
  toJSON(data) {

    //Initialize JSON
    const json = {};

    //Copy extra given data
    if (data && typeof data === 'object') {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          json[key] = BaseModel.valueToJSON(data[key]);
        }
      }
    }

    //Copy our own data, but don't overwrite given data
    for (const key in this) {
      if (this.hasOwnProperty(key) && !json.hasOwnProperty(key)) {
        json[key] = BaseModel.valueToJSON(this[key]);
      }
    }

    return json;
  }

  /**
   * Extract subset of properties
   */
  extract(keys) {
    //If string given, just return copy of one property
    if (typeof keys === 'string') {
      return copyProperty(this, keys);
    }

    //Initialize object
    const obj = {};

    //No keys given? Iterate all object keys
    if (!Array.isArray(keys) || !keys.length) {
      for (const key in this) {
        if (this.hasOwnProperty(key)) {
          obj[key] = copyProperty(this, key);
        }
      }
    }
    else {
      for (const key of keys) {
        obj[key] = copyProperty(this, key);
      }
    }

    return obj;
  }

  /**
   * Clone model
   */
  clone(stripId = false) {

    //Get model and create clone
    const Model = this.constructor;
    const clone = new Model(null, this.$parent);

    //Copy our own properties
    for (const key in this) {
      if (this.hasOwnProperty(key)) {
        if (key === 'id' && stripId) {
          continue;
        }
        clone[key] = copyProperty(this, key);
      }
    }

    return clone;
  }

  /**
   * Check if this model is the same as another (by ID)
   */
  isSame(model) {
    if (!model) {
      return false;
    }
    if (typeof model === 'string') {
      return (this.id === model);
    }
    return (this.id && model.id && this.id === model.id);
  }

  /**
   * Helper to convert a value from JSON
   */
  static valueFromJSON(value) {
    if (Array.isArray(value)) {
      return value.map(BaseModel.valueFromJSON);
    }
    else if (typeof value === 'string') {
      return dateStringToMoment(value) || value;
    }
    else if (value && typeof value === 'object') {
      if (value._isAMomentObject) {
        return value.clone();
      }
      const copy = {};
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          copy[key] = BaseModel.valueFromJSON(value[key]);
        }
      }
      return copy;
    }
    return value;
  }

  /**
   * Helper to convert a value to JSON
   */
  static valueToJSON(value) {
    if (Array.isArray(value)) {
      return value.map(BaseModel.valueToJSON);
    }
    else if (value && typeof value === 'object') {
      if (typeof value.toJSON === 'function') {
        return value.toJSON();
      }
      const copy = {};
      for (let key in value) {
        if (value.hasOwnProperty(key)) {
          copy[key] = BaseModel.valueToJSON(value[key]);
        }
      }
      return copy;
    }
    return value;
  }

  /**
   * Instantiate models from given data
   */
  static createFrom(data, parent = null, key = null) {

    //Array of data
    if (Array.isArray(data)) {
      return data.map(item => this.createFrom(item, parent, key));
    }

    //Data object with model present in specific key
    if (typeof data === 'object' && data !== null && key) {
      data[key] = this.createFrom(data[key], parent);
      return data;
    }

    //Single model
    return new this(data || {}, parent);
  }
}
