<script>
export default {
  props: {
    filter: { type: Object },
  },

  computed: {
    filterButtons() {
      const possibleButtons = [
        {
          key: 'type',
          label: value => this.$root.$options.filters.inflect(value, 'pluralize'),
        },
        {
          key: 'hasContract',
          label: value => value ? this.$t('asset.in_contract') : this.$t('assets_filter-buttons.not_in_contract'),
        },
        {
          key: 'hasContact',
          label: value => value ? this.$t('asset.has_owner') : this.$t('assets_filter-buttons.no_owner'),
        },
        {
          key: 'offering',
          label: value => value,
        }
      ];
      const buttons = possibleButtons
        .filter(({ key }) => this.filter[key] != null)
        .map(({ key, label }) => ({
          key,
          label: label(this.filter[key]),
        }));
      return buttons;
    },
  },

  methods: {
    /** Changes url on filter change */
    update(key) {
      const { query } = this.$route;
      let newQuery = {};
      Object.assign(newQuery, query);
      delete newQuery[key];
      this.$router.push({ query: newQuery });
      this.filter.update(key, null);
    },

    clear() {
      this.$router.push({ query: null});
      this.filter.resetToDefaults();
    },
  },
};
</script>

<template>
  <div v-if="filterButtons.length" class="View--filters">
    <div class="FilterRow">
      <button v-for="(button, index) in filterButtons"
        :key="index"
        class="Button--tableFilter"
      >
        {{button.label}}
        <i
          class="Icon-FilterClose"
          @click="update(button.key)"
        >close</i>
      </button>
    </div>
    <div class="Filter-Meta">
      <div class="Filter-Close" @click="clear">
        <i class="Icon-FilterClose">close</i>
      </div>
    </div>
  </div>
</template>
