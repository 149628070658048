import BaseModel from './base.model';
import AccountApi from '@/api/account.api';

export default class Account extends BaseModel {

  getModelName() {
    return 'Account';
  }

  updateOwn(data) {
    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return AccountApi
      .updateOwn(json)
      .then(data => this.fromJSON(data));
  }

  updateOwnPlan(data) {
    return AccountApi
      .updateOwnPlan(data)
      .then(data => this.fromJSON(data));
  }

  updateOwnSettings(data) {
    return AccountApi
      .updateOwnSettings(data)
      .then(data => this.fromJSON(data));
  }

  updateOwnBilling(data) {
    return AccountApi
      .updateOwnBilling(data)
      .then(data => this.fromJSON(data));
  }

  get readyForContracts() {
    return this.contactsCount > 0 && this.offeringsCount > 0;
  }
}
