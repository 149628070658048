import BaseModel from './base.model';
import ContactApi from '@/api/contact.api';

export default class Contact extends BaseModel {
  getModelName() {
    return 'Contact';
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return ContactApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    return ContactApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;
    return ContactApi
      .remove(id);
  }

  archive() {
    return this.update({ archived: true });
  }

  unArchive() {
    return this.update({ archived: false });
  }
}
