<template>
  <div class="Container">
    <div class="NotFound-Wrap">
      <div class="PortalForm">
        <div class="FormLogoWrap">
          <img
            class="Sponsor-logo Sponsor-logoIcon"
            src="@/assets/logo.svg"
            alt="Controlla"
          >
        </div>
        <h1>Contr(uh-oh)lla!</h1>
        <div class="Section">
          <span>{{$t('error_not-found.no_resource_here')}}</span>
        </div>

        <!-- Back button -->
        <div class="Group">
          <button class="Button--primary" @click="back">
            {{ $t("error_not-found.go_back") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.NotFound-Wrap {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
}
</style>