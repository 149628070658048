import http from '@/services/http';
import AssetType from '@/models/asset-type.model';

export default {
  query(filter) {
    return http
      .get('/assetType', filter)
      .then(data => AssetType.createFrom(data, null, 'items'));
  },

  list(filter) {
    return http
      .get('/assetType/list', filter)
      .then(assetTypes => AssetType.createFrom(assetTypes));
  },

  summary(filter) {
    return http
      .get('/assetType/summary', filter);
  },

  findById(id) {
    return http
      .get(`/assetType/${id}`)
      .then(data => AssetType.createFrom(data));
  },

  create(data) {
    return http
      .post(`/assetType`, data);
  },

  update(id, data) {
    return http
      .put(`/assetType/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/assetType/${id}`);
  },
};
