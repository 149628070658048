// MHJB: Borrowed from https://dev.to/ismail9k/use-svg-icons-with-vue-js-2km7

<script>
import icons from './icons';

export default {
  name: 'ControllaIcon',

  props: {
    name: String,
  },

  computed: {
    fallbackIcon() {
      let icon;
      // if we can't find an svg icon in the JSON, use the below
      if (!icons[this.name]) {
        switch (this.name) {
          case 'Domain name':
            icon = 'http';
            break;
          case 'website':
            icon = 'web';
            break;
          default:
            icon = this.name;
        }
      }
      return icon;
    },

    svgCode() {
      return icons[this.name];
    },
  },
};
</script>

<template>
  <div>
    <i class="Icon-Base" v-if="fallbackIcon">{{fallbackIcon}}</i>
    <svg
      v-else
      class="Icon-Svg"
      viewBox="0 0 24 24"
      v-html="svgCode"
      fill-rule="evenodd"
    />
  </div>
</template>
