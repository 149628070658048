<script>
import InvoiceApi from '@/api/invoice.api';

export default {
  props: {
    query: { type: Object },
    modalFromLink: { type: String },
  },

  data() {
    return {
      invoices: [],
      invoicesLoading: false,
    };
  },

  async created() {
    this.invoicesLoading = true;
    const res = await InvoiceApi.query(this.query);
    this.invoices = res.invoices;
    this.invoicesLoading = false;
  },
};
</script>

<template>
  <div>
    <spinner v-if="invoicesLoading" class="Spinner--general" />

    <div v-else-if="!invoicesLoading && invoices.length > 0" >
      <div class="ModalList">
        <div class="ModalList-header">
          <div class="ModalList-title Modal-col--XL">{{ $t('contracts_modals_view.invoice')}}</div>
          <div class="ModalList-title Modal-col--M">{{ $t('contracts_modals_view.invoice_sent')}}</div>
          <div class="ModalList-title Modal-col--M">{{ $t('contracts_modals_view.invoice_due')}}</div>
          <div class="ModalList-title Modal-col--M Modal-col--alignRight">{{$t('contracts_modals_view.invoice_total')}}</div>
        </div>
        <div class="ModalList-Row" v-for="(invoice, index) in invoices" :key="index">

          <modal-link
            class="ModalList-value Modal-col--XL"
            :to="`/billing/invoices/view/${invoice.id}`"
            :from="modalFromLink"
            fromName="contract"
          >
            <span class="">{{invoice.number}}</span>
            <div class="StatusPill-wrap">
              <span :class="{
                'Pill--info': invoice.isSent,
                'Pill--ok': invoice.isPaid,
                'Pill--warning': invoice.isDraft,
              }">
                {{invoice.status | invoiceStatus}}
              </span>
            </div>
          </modal-link>
          <div class="ModalList-value Modal-col--M">
            {{ invoice.date | moment("ll") }}
          </div>
          <div class="ModalList-value Modal-col--M">
            {{ invoice.dueDate | moment("ll") }}
          </div>
          <div class="ModalList-value Modal-col--M">
            <div class="TotalAmount-wrap">
              {{ invoice.totalExclTax | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="Modal--item">
      <div class="Row-0">
        <div class="ModalList Modal-Panel">
          <div class="ModalList--noItems">
          {{ $t('global.no_invoices')}}
        </div>
      </div>
    </div>
   </div>
  </div>
</template>
