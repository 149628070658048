<script>
import ModalEdit from '@/components/shared/modal-edit';
import { required } from 'vuelidate/lib/validators';
import IconSearch from '@/components/shared/icon-search';

export default {
  name: 'AssetTypeEdit',

  components: { ModalEdit },

  data() {
    return {
      model: {
        name: null,
        id: null,
        notes: null,
        icon: null,
        integration: null,
      },
    };
  },

  validations: {
    model: { name: { required } },
  },

  props: {
    value: Object,
    onSave: { type: Function },
    isEdit: { type: Boolean },
  },

  created() {
    //Create model subset
    if (this.value && Object.values(this.value).filter(x => typeof (x) !== 'undefined').length > 0) {
      this.model = this.value.extract(Object.keys(this.model));
    }
  },

  methods: {
    onInput(value, {field/*, event*/}) {
      // let {name, description} = data;
      this.model[field.name] = value;
    },

    pickIcon() {
      this.$modal.show(IconSearch,
        { value: this.model.icon },
        { clickToClose: true },
        { input: icon => this.model.icon = icon },
      );
    },

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.onSave(this.model);
      }
    }
  },
};
</script>

<template>
  <modal-edit
    singular="Asset Type"
    class="Edit-Modal-asset-type"
    :model="model"
    :validation="$v"
    :is-edit="isEdit"
    :on-save="onSave"
    @close="$emit('close')"
  >
  <template #default>
    <div class="Form type-edit">
      <label :for="$htmlID('name')" class="Label">Asset type name</label>
      <input :id="$htmlID('name')" class="Input-100" type="text" v-model="model.name">
      <div class="InputHint" v-if="$v.model.name.$error">
        Type name is required
      </div>

      <div class="CurrentIcon-Wrap">
        <icon class="Icon-Thumb" :name="model.icon" />
        <button v-if="!model.integration" @click.prevent="pickIcon" class="Button--small">
          {{$t('asset-types_modals_add-type-options.select_icon')}}
        </button>
      </div>

      <label :for="$htmlID('note')" class="Label">Note</label>
      <textarea :id="$htmlID('note')" class="Input-100" type="text" v-model="model.notes"></textarea>
    </div>
    </template>
  </modal-edit>
</template>

<style lang="scss">

.CurrentIcon-Wrap {
  display: flex;
  align-items: center;
  margin: $spacingL 0;
  .Icon-Thumb {
    margin-right: $spacingM;
  }
}

.Icon--asset-type {
  width:  auto;
  min-height: 50px;
  margin-right: $spacingXL;
  margin-bottom: $spacingS;
  .Icon {
    font-size: 36px;
    padding: 0.5rem;
    margin-right: $spacingS;
    color: $colorPrimary;
    font-size: 70px;
    color: #DD245B;
  }
}
</style>
