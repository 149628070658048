<script>
import auth from '@/services/auth';
import NotAuthenticatedError from '@/errors/not-authenticated';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'UserLogin',

  data() {
    return {
      isErrorInvalidLogin: false,
      isLoggingIn: false,
      provider: null,
      credentials: {
        email: '',
        password: '',
      },
      error: '',
      registerProvider: '',
    };
  },

  validations: {
    credentials: {
      email: {
        required,
        email,
      },
      password: { required },
    },
  },

  beforeRouteEnter(to, from, next) {
    if (auth.hasToken()) {
      return next({ name: 'dashboard' });
    }
    next();
  },

  computed: {
    fromFrill() {
      return this.$route.name == 'frillLogin';
    },
  },

  created() {
    if (this.$route.params && this.$route.params.error) {
      this.error = this.$route.params.error;
      this.registerProvider = this.$route.params.provider;
      // reset error in 5s
      // setTimeout(() => {
      //   this.error = null;
      // }, 5000);
    }
  },

  methods: {
    async login(event) {
      event.preventDefault();

      //Validate form
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      //Get data
      const { email, password } = this.credentials;
      const { redirect } = this.$route.query;

      //Reset flags
      this.isErrorInvalidLogin = false;
      this.isLoggingIn = true;
      this.provider = null;

      //Login
      try {
        await this.$auth.loginWithCredentials(
          email, password, redirect,
          this.fromFrill
        );
      }
      catch (error) {
        // Archived user
        if (error.name === 'NotAuthorizedError') {
          return this.isErrorInvalidLogin = true;
        }
        if (error instanceof NotAuthenticatedError) {
          return this.isErrorInvalidLogin = true;
        }
      }
      finally {
        this.isLoggingIn = false;
      }
    },

    async loginWithOAuth(provider) {
      const { redirect } = this.$route.query;
      this.provider = provider;
      this.isLoggingIn = true;
      await this.$auth.loginWithOAuth(provider, 'login', {
        redirect, fromFrill: this.fromFrill,
      });
    },
  },
};
</script>

<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm  PortalForm--login">
      <div class="FormLogoWrap">
        <img class="Sponsor-logo Sponsor-logoIcon" src="@/assets/logo.svg" alt="Controlla">
      </div>
      <h1>{{ $t('login_login.title') }}</h1>

      <button class="Button--oauth" @click="loginWithOAuth('google')" :disabled="isLoggingIn">
        <i class="IconProvider"><img src="../../../assets/images/oauth/google.png" alt=""></i>
        {{ $t('login_login.sign_in_with_google') }}
        <spinner />
      </button>
      <div class="InputNotice" v-if="error">{{ $t('login_login.duplicate_google') }}</div>
      <div class="SpacerM" />
      <div class="SpacerTextHr">
        <hr />
        <span>{{ $t('login_login.or') }}</span>
        <hr />
      </div>

      <form id="password-login-form">
        <div class="Group--inline" :class="{ 'Group--error': $v.credentials.email.$error }">
          <input v-model.trim="credentials.email" type="text" class="Input-100"
            :placeholder="$t('login_login.login_placeholder_email')">
          <div class="InputHint" v-if="$v.credentials.email.$error">
            <!-- Are the following two needed?  I'm not sure, DFM -->
            <div v-if="!$v.credentials.email.required">{{ $t('login_login.email_required') }}</div>
            <div v-if="!$v.credentials.email.email">{{ $t('login_login.invalid_email') }}</div>
          </div>
        </div>
        <div class="Group" :class="{ 'Group--error': $v.credentials.password.$error }">
          <input v-model="credentials.password" type="password" class="Input-100"
            :placeholder="$t('login_login.login_placeholder_password')">
          <div class="InputHint" v-if="$v.credentials.password.$error">
            <div v-if="!$v.credentials.password.required">{{ $t('login_login.password_required') }}</div>
          </div>
        </div>
        <div class="InputHint" v-if="isErrorInvalidLogin">{{ $t('login_login.invalid_login_details') }}</div>
        <div class="Group">
          <button class="Button--login" @click="login($event)" :disabled="isLoggingIn">
            <i class="Icon-Lock">lock</i> {{ $t('login_login.login') }}
            <spinner v-if="!provider" />
          </button>
          <div class="Button--justText Button--small">
            <router-link to="/forgot-password">{{ $t('login_login.forgot_password') }}</router-link>
          </div>
        </div>

        <div class="Alternate-UserOption">
          {{ $t('login_login.no_account_yet') }} <router-link to="/register">{{ $t('login_login.create_one_here') }}
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.Container-login {
  display: flex;
  flex-direction: column;
}

.FormLogoWrap {
  display: inline-block;
}

.Button--login {
  margin-bottom: $spacingXS;
}
</style>
