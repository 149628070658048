
/**
 * Get logged in user
 */
export const me = state => state.user;

export const account = state => state.account;

export const frillWidgetUnreadCount = state => state.frillWidgetUnreadCount;

export const frillSSOToken = state => state.user.frillSSOToken;

export const dropMenuOpen = state => state.dropMenuOpen;

export const fromLink = state => state.fromLink;
