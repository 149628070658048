<!-- Component to add extra functionality to router-link, so that it includes
a history item recording where the link came from, to use in breadcrumbs -->

<script>

export default {
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    // Route to use for the breadcrumb
    from: {
      type: [String, Object],
    },
    fromName: {
      type: String,
    },
  },

  methods: {
    // MHJB: note @click.native is deprecated in Vue 3
    onClick() {
      if(this.from) {
        this.$store.commit('session/setFromLink', {
          link: this.from,
          name: this.fromName,
        });
      }
      this.$modal.hideAll();
    }
  }
}
</script>

<template>
  <router-link :to="to" @click.native="onClick">
    <slot />
  </router-link>
</template>