import BaseModel from './base.model';
import IntegrationApi from '@/api/integration.api';

export default class Integration extends BaseModel {
  getModelName() {
    return 'Integration';
  }

  update(data) {
    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {type} = this;

    return IntegrationApi
      .update(type, json)
      .then(data => this.fromJSON(data));
  }

  connect(data) {
    const { type } = this;
    return IntegrationApi
      .connect(type, data)
      .then(data => {
        if (data.redirect) {
          window.location.href = data.redirect;
        }
      });
  }

  disconnect() {
    const {type} = this;

    //Update
    return IntegrationApi
      .disconnect(type);
  }
}
