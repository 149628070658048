<script>

export default {
  name: 'SendInvoice',

  data() {
    return {

      //Flags
      isError: false,
      isSending: false,
    };
  },
  props: {
    invoice: {
      type: Object,
    },
    onSend: {
      type: Function,
    },
  },

  /**
   * Methods
   */
  methods: {

    /**
     * Confirm
     */
    confirm() {

      //Toggle flags
      this.isSending = true;
      this.isError = false;

      //Call handler
      this
        .onSend()
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isSending = false);
    },
  },
};
</script>

<template>
  <div class="Modal">
    <div class="Modal-Header">
      <h1>Send invoice</h1>
      <i class="Icon-ModalsClose" @click="$emit('close')">close</i>
    </div>
    <div class="ModalBody">
      <p>Send invoice <strong>{{ invoice.number }}</strong> to <strong>{{ invoice.contact.name }}</strong>?</p>
    </div>
    <div class="ModalFooter">
      <button-bar :is-busy="isSending" label-confirm="Send" @cancel="$emit('close')" @confirm="confirm"></button-bar>
    </div>
  </div>
</template>
