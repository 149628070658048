<script>

export default {
  data() {
    return {
      isError: false,
      isRemoving: false,
      confirmation: this.confirmationText ? false : true,
    };
  },

  props: {
    singular: { type: String },
    onRemove: { type: Function },
    text: { type: String },
    confirmationText: { type: String },
  },

  methods: {
    remove() {
      this.isRemoving = true;
      this.isError = false;

      this
        .onRemove()
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isRemoving = false);
    },
  },
};
</script>

<template>
  <div class="Modal--dialog">
    <div class="Modal-Header">
      <h1>{{ $t('shared_modal-remove.delete') }} {{ singular }}</h1>
      <i
        class="Icon-ModalsClose"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <slot>
        <div v-html="text" />
      </slot>
      <div v-if="confirmationText" class="Confirmation-Wrapper">
        <input type="checkbox" class="Input-Checkbox" v-model="confirmation" />
        <span v-html="confirmationText" />
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isRemoving"
        :label-confirm="$t('shared_modal-remove.delete_this') + ' ' + singular"
        type-confirm="danger"
        @cancel="$emit('close')"
        @confirm="remove"
        :isDisabledConfirm="!confirmation"
      >
      </button-bar>
    </div>
  </div>
</template>
