<script>

export default {
  name: 'UserNotice',

  props: {
    message: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    hasSpinner: {
      type: Boolean,
      default: false,
    },
    hideOnClick: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    translatedMessage() {
      const translationAttempt = this.$t(this.message);
      return translationAttempt === this.message ? this.message : translationAttempt;
    }
  },

  methods: {
    click() {

      //Hide on click if needed
      if (this.hideOnClick) {
        return this.$emit('hide');
      }

      this.onClick();
    },
  },
};
</script>

<style lang="scss">
.Notice {
  float: left;
  clear: left;
  margin-top: $spacingXL;
  padding: $spacingS;
  border-radius: $borderRadiusM;
  background: $colorText;
  color: $colorWhite;
  font-size: $fontSizeM;
  box-shadow: 2px 2px 5px rgba(0,0,0,.3);
  display: flex;
  align-items: center;
}
.Notice--danger {
  background: $colorDanger;
}
.Notice-spinner {
  margin-right: $spacingS;
}
.Notice-icon {
  margin-right: $spacingS;
}
.Notice-message {
  display: inline-block;
  padding: $spacingM $spacingXL;
}
</style>

<template>
  <div
    class="Notice"
    :class="{pointer: hideOnClick}"
    @click="click"
  >
    <spinner
      v-if="hasSpinner"
      class="Notice-spinner"
    />
    <i
      class="Icon Icon--s Notice-icon"
      v-if="icon"
    >
      {{icon}}
    </i>
    <div class="Notice-message">
      {{translatedMessage}}
    </div>
  </div>
</template>

