<script>
import UserLinks from './user-links';
import MenuBar from './menu-bar';
import { mapState } from 'vuex';

export default {
  name: 'ControllaHeader',

  components: { UserLinks, MenuBar },

  data() {
    return {
      isMobileMenuVisible: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),
  },

  mounted() {
    this.boundClickHandler = this.clickHandler.bind(this);
    document.addEventListener('click', this.boundClickHandler);
  },

  destroyed() {
    document.removeEventListener('click', this.boundClickHandler);
  },

  methods: {
    toggleMobileMenu() {
      this.isMobileMenuVisible = !this.isMobileMenuVisible;

      if (this.isMobileMenuVisible) {
        document.body.classList.add('mobile-menu-visible');
      }
      else {
        document.body.classList.remove('mobile-menu-visible');
      }
    },

    /**
     * Global click handler
     */
    clickHandler(event) {
      const { mobileMenuTrigger: trigger } = this.$refs;
      const { target } = event;
      if (target !== trigger && target.parentNode !== trigger && this.isMobileMenuVisible) {
        this.toggleMobileMenu();
      }
    },
  },
};
</script>

<template>
  <div class="NavWrap" v-if="user">
    <div class="Main-Nav Main-Nav--top" v-if="user">
      <nav class="AppHeader" >
        <menu-bar v-if="user" />
        <user-links v-if="user" />
      </nav>
    </div>
  </div>
</template>

<style lang="scss">

.NavWrap {
  background: $colorBgMainMenu;
}

.Main-Nav {
  @include breakPointMedium {
  }
}

.Authenticated .Main-Nav {
  display: block;
}

.AppHeader {
  display: flex;
  justify-content: space-between;
  padding: $spacingM $spacingS 55px;
  flex-direction: column;
  height: 100vh;
  @include breakPointMedium {

  }
}
</style>
