export default {
  heart: `<path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z">`,
  agency_analytics: `<rect x="0" y="0" width="24" height="24" style="fill-opacity:0;"/>
  <g transform="matrix(0.581395,0,0,0.581395,2,3.22093)">
      <path d="M14.9,0L15.1,0C18,0 20.8,0.9 23.2,2.5C25.8,4.2 27.9,6.8 29,9.8C29.212,10.318 29.379,10.853 29.5,11.4L32.9,11.8C33.2,11.8 33.6,11.9 33.9,12C34.2,12.2 34.4,12.5 34.4,12.9C34.3,13.3 34.2,13.6 34,13.9L30.7,21.7C30.6,21.9 30.5,22.2 30.3,22.4C29.886,22.691 29.308,22.602 29,22.2C28.8,22 28.7,21.7 28.6,21.5L27.7,23.2L29.2,27.2C29.3,27.4 29.4,27.6 29.3,27.9C29.3,28.2 29,28.4 28.8,28.5C28.6,28.5 28.5,28.6 28.3,28.6L22.3,28.6C22.1,28.6 21.9,28.5 21.8,28.6C19.8,29.6 17.6,30.2 15.4,30.2L14.7,30.2C12.5,30.1 10.3,29.6 8.3,28.6C8.2,28.5 8,28.6 7.8,28.6L1.8,28.6C1.5,28.6 1.1,28.5 0.9,28.2C0.8,28 0.7,27.8 0.7,27.7C0.7,27.3 0.9,27 1,26.7L2.3,23.2C0.8,20.9 0,18.2 0,15.5L0,15.1C0,12.5 0.7,9.9 2.1,7.6C3.6,5 6,2.9 8.7,1.6C10.6,0.5 12.7,0 14.9,0M11.5,1.6L1.5,27.5L7.8,27.5L13,24.5L14.4,27C18.449,24.76 22.35,22.371 26.359,20.09C27.003,19.724 27.649,19.36 28.3,19C28.8,19.9 29.3,20.7 29.8,21.6L33.5,12.8L24,11.6C24.5,12.5 25,13.3 25.5,14.2L16.5,19.4C16,18.6 15.5,17.7 15,16.9C13.5,17.7 12.1,18.6 10.6,19.4C11.9,15.3 13.5,11.2 15,7.2L18.6,17.2L23.4,14.5C21.7,10.3 20.1,6 18.5,1.7C16.2,1.6 13.8,1.6 11.5,1.6M21,24.2L22.2,27.5L28.5,27.5L26.1,21.3L21,24.2Z" style="fill-rule:nonzero;"/>
  </g>`,
  dnsimple: `<g transform="matrix(0.0416667,0,0,0.0416667,2,2)">
  <path d="M240,0C372.46,0 480,107.54 480,240C480,372.46 372.46,480 240,480C107.54,480 0,372.46 0,240C0,107.54 107.54,0 240,0ZM295.77,96.601C287.862,96.601 279.955,97.017 272.464,98.681L272.464,176.089C261.644,171.927 249.159,169.846 232.512,169.846C173.416,169.846 125.14,210.215 125.14,275.137C125.14,341.724 169.254,378.347 234.177,378.347C266.222,378.347 299.099,369.607 319.491,358.787L319.491,98.681C311.584,97.017 303.261,96.601 295.77,96.601ZM233.344,337.146C197.554,337.146 173,313.008 173,274.305C173,236.017 199.218,211.463 234.177,211.463C248.326,211.463 261.228,213.128 272.464,220.203L272.464,330.904C259.979,335.481 247.078,337.562 233.344,337.146Z" style="fill:rgb(26,94,198);"/>
</g>`,
  digitalocean: `<g transform="matrix(0.312498,0,0,0.312498,2,1.99986)">
    <g id="A">
        <g>
            <path d="M32.004,64.001L32.004,51.595C45.14,51.595 55.336,38.595 50.292,24.759C48.417,19.623 44.363,15.57 39.226,13.697C25.402,8.693 12.376,18.847 12.376,31.977L0,31.977C0,11.051 20.248,-5.267 42.2,1.587C51.8,4.597 59.436,12.213 62.41,21.787C69.27,43.787 52.976,64.001 32.004,64.001Z" style="fill:rgb(0,128,255);fill-rule:nonzero;"/>
            <path d="M19.7,51.629L19.7,39.299L32.036,39.299L32.036,51.629L19.7,51.629ZM10.194,61.129L10.194,51.629L19.7,51.629L19.7,61.129L10.194,61.129ZM10.194,51.629L2.248,51.629L2.248,43.689L10.194,43.689L10.194,51.629Z" style="fill:rgb(0,128,255);fill-rule:nonzero;"/>
        </g>
    </g>
  </g>`,
  google_ads: `    <g transform="matrix(0.080546,0,0,0.080546,1.88706,2.8604)">
  <path d="M85.9,28.6C88.3,22.3 91.6,16.5 96.5,11.8C116.1,-7.3 148.5,-2.5 161.8,21.5C171.8,39.7 182.4,57.5 192.7,75.5C209.9,105.4 227.3,135.3 244.3,165.3C258.6,190.4 243.1,222.1 214.7,226.4C197.3,229 181,221 172,205.4C156.9,179.1 141.7,152.8 126.6,126.6C126.3,126 125.9,125.5 125.5,125C123.9,123.7 123.2,121.8 122.2,120.1C115.5,108.3 108.6,96.6 101.9,84.9C97.6,77.3 93.1,69.8 88.8,62.2C84.9,55.4 83.1,48 83.3,40.2C83.6,36.2 84.1,32.2 85.9,28.6" style="fill:rgb(60,139,217);fill-rule:nonzero;"/>
  <path d="M85.9,28.6C85,32.2 84.2,35.8 84,39.6C83.7,48 85.8,55.8 90,63.1C101,82 112,101 122.9,120C123.9,121.7 124.7,123.4 125.7,125C119.7,135.4 113.7,145.7 107.6,156.1C99.2,170.6 90.8,185.2 82.3,199.7C81.9,199.7 81.8,199.5 81.7,199.2C81.6,198.4 81.9,197.7 82.1,196.9C86.2,181.9 82.8,168.6 72.5,157.2C66.2,150.3 58.2,146.4 49,145.1C37,143.4 26.4,146.5 16.9,154C15.2,155.3 14.1,157.2 12.1,158.2C11.7,158.2 11.5,158 11.4,157.7C16.2,149.4 20.9,141.1 25.7,132.8C45.5,98.4 65.3,64 85.2,29.7C85.4,29.3 85.7,29 85.9,28.6"  class="Svg-Transparency" style="fill:rgb(250,188,4);fill-rule:nonzero;"/>
  <path d="M11.8,158C13.7,156.3 15.5,154.5 17.5,152.9C41.8,133.7 78.3,147.6 83.6,178C84.9,185.3 84.2,192.3 82,199.3C81.9,199.9 81.8,200.4 81.6,201C80.7,202.6 79.9,204.3 78.9,205.9C70,220.6 56.9,227.9 39.7,226.8C20,225.4 4.5,210.6 1.8,191C0.5,181.5 2.4,172.6 7.3,164.4C8.3,162.6 9.5,161 10.6,159.2C11.1,158.8 10.9,158 11.8,158" style="fill:rgb(52,168,82);fill-rule:nonzero;"/>
  <path d="M11.8,158C11.4,158.4 11.4,159.1 10.7,159.2C10.6,158.5 11,158.1 11.4,157.6L11.8,158" style="fill:rgb(250,188,4);fill-rule:nonzero;"/>
  <path d="M81.6,201C81.2,200.3 81.6,199.8 82,199.3C82.1,199.4 82.3,199.6 82.4,199.7L81.6,201" style="fill:rgb(225,192,37);fill-rule:nonzero;"/>
</g>`,
  webflow: `<path d="M22,4.6C22,3.165 20.835,2 19.4,2L4.6,2C3.165,2 2,3.165 2,4.6L2,19.4C2,20.835 3.165,22 4.6,22L19.4,22C20.835,22 22,20.835 22,19.4L22,4.6ZM15.91,10.163C15.91,10.163 14.772,13.727 14.688,14.03C14.655,13.733 13.825,7.327 13.825,7.327C11.886,7.327 10.855,8.706 10.306,10.163C10.306,10.163 8.921,13.738 8.809,14.035C8.804,13.755 8.596,10.196 8.596,10.196C8.479,8.408 6.848,7.327 5.525,7.327L7.117,17.028C9.145,17.023 10.238,15.649 10.81,14.187C10.81,14.187 12.026,11.031 12.077,10.891C12.088,11.026 12.951,17.028 12.951,17.028C14.985,17.028 16.084,15.745 16.672,14.338L19.525,7.327C17.513,7.327 16.454,8.7 15.91,10.163Z" style="fill:rgb(67,83,255);"/>`,
  wix: `    <g transform="matrix(0.0643087,0,0,0.0643087,11.2283,11.2317)">
  <g transform="matrix(1,0,0,1,-143.5,-48.5)">
      <path d="M178,2.3C172,5.3 169.4,10.9 169.4,26.1C169.4,26.1 172.4,23.1 177.2,21.3C180.7,20 183.2,18.3 185,17C190.2,13.1 191,8.4 191,0.2C190.9,0.2 182.7,-0.3 178,2.3Z" style="fill:rgb(251,189,113);fill-rule:nonzero;"/>
  </g>
  <g transform="matrix(1,0,0,1,-143.5,-48.5)">
      <path d="M141.3,5.8C136.1,10.1 134.8,17.5 134.8,17.5L118,81.9L104.2,29.2C102.9,23.6 100.3,16.7 96.4,11.9C91.6,5.8 81.6,5.4 80.4,5.4C79.5,5.4 69.6,5.8 64.4,11.9C60.5,16.7 57.9,23.6 56.6,29.2L43.6,81.9L26.8,17.5C26.8,17.5 25.5,10.6 20.3,5.8C12.1,-1.6 0,0.2 0,0.2L32,120.7C32,120.7 42.4,121.6 47.6,119C54.5,115.5 58,113 61.9,96.5C65.8,81.8 76.2,39 77,36C77.4,34.7 78.3,30.8 80.9,30.8C83.5,30.8 84.4,34.3 84.8,36C85.7,39 96,81.8 99.9,96.5C104.2,112.9 107.2,115.5 114.2,119C119.4,121.6 129.8,120.7 129.8,120.7L161.6,0.2C161.6,0.2 149.5,-1.5 141.3,5.8Z" style="fill-rule:nonzero;"/>
  </g>
  <g transform="matrix(1,0,0,1,-143.5,-48.5)">
      <path d="M190.9,19.6C190.9,19.6 188.7,22.6 184.4,25.2C181.4,26.9 178.8,27.8 175.8,29.5C170.6,32.1 169.3,34.7 169.3,38.6L169.3,120.3C169.3,120.3 177.5,121.2 182.7,118.6C189.6,115.1 190.9,111.7 190.9,97L190.9,19.6Z" style="fill-rule:nonzero;"/>
  </g>
  <g transform="matrix(1,0,0,1,-143.5,-48.5)">
      <path d="M270.4,60.7L311,0.6C311,0.6 294.2,-2.4 285.5,5.4C279.9,10.2 274.3,19.2 274.3,19.2L259.6,40.8C258.7,42.1 257.9,43 256.6,43C255.3,43 254,41.7 253.6,40.8L238.9,19.2C238.9,19.2 232.9,10.6 227.7,5.4C219.1,-2.4 202.2,0.6 202.2,0.6L241.5,60.6L201.3,120.6C201.3,120.6 219,122.8 227.7,115C233.3,110.2 238.5,102 238.5,102L253.2,80.4C254.1,79.1 254.9,78.2 256.2,78.2C257.5,78.2 258.8,79.5 259.2,80.4L273.9,102C273.9,102 279.5,110.2 284.7,115C293.3,122.8 310.6,120.6 310.6,120.6L270.4,60.7Z" style="fill-rule:nonzero;"/>
  </g>
</g>`
}