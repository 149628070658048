<script>
export default {
  props: {
    period: {
      type: String,
      required: true,
    },
  },
  computed: {
    periodLetter() {
      switch (this.period?.toLowerCase()) {
        case 'weeks': return 'W';
        case 'two_weeks': return '2W';
        case 'months': return 'M';
        case 'quarters': return 'Q';
        case 'years': return 'Y'; 
        default: return '–'; // Default case
      }
    },
    // Compute the class based on the transformed period for consistency
    periodClass() {
      // Use the computed `periodLetter` to determine the class
      const letter = this.periodLetter.toLowerCase();
      switch (letter) {
        case 'w': return 'weekly';
        case '2w': return 'two_weekly'; // Assuming 'T' stands for 'Two Weekly'
        case 'm': return 'monthly';
        case 'q': return 'quarterly';
        case 'y': return 'yearly'; // 'Y' for both 'annually' and 'years'
        default: return 'default'; // A default class if no match
      }
    },
  },
}
</script>

<template>
  <!-- Use both computed properties for displaying the letter and setting the class -->
  <span :class="`PeriodIcon PeriodIcon--${periodClass}`">{{ periodLetter }}</span>
</template>
