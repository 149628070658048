import Vue from 'vue';

/**
 * Create mixin to load the config service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.stripe) {
      this.$stripe = options.stripe;
    }
    else if (options.parent && options.parent.$stripe) {
      this.$stripe = options.parent.$stripe;
    }
  },
});

class StripeService {
  constructor() {
    //Set placeholder for instantiated services per public key
    this.instances = {};
  }

  /**
   * Get a Stripe service instance for the given public key
   */
  service(publicKey) {
    const { instances } = this;

    //Must have global Stripe variable at this stage
    if (!window.Stripe) {
      throw new Error('Global `Stripe` variable missing');
    }

    if (!publicKey) {
      throw new Error('No public key specified');
    }

    //Already instantiated
    if (typeof instances[publicKey] !== 'undefined') {
      return instances[publicKey];
    }

    //Instantiate service
    return (instances[publicKey] = window.Stripe(publicKey));
  }
}

/**
 * Export singleton instance
 */
export default new StripeService();
