<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      user: state => state.session.user,
    }),

    isAdmin() {
      return this.user?.role === 'Admin';
    },
  },
};
</script>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderSettings",
  computed: {
    ...mapState({
      accountName: (state) => state.session.account.name,
    }),
  },
};
</script>

<template>
  <div class="MainWrap">
    <div class="HeaderBar">
          <div class="SectionTitleAndTabs Container">
          <menu class="PageHeaderTabs">
            <router-link class="PageHeader-tab--first" to="/directory/clients">
              <i class="Icon-Header">people</i>{{$t('global.clients')}}
            </router-link>
            <router-link class="PageHeader-tab" to="/directory/users">
              <i class="Icon-Header">touch_app</i>{{$t('users.users')}}
            </router-link>
          </menu>
          <div class="AccountMeta--wrap">
            <div class="AccountTitle">{{ accountName }}</div>
          </div>
        </div>
      </div>
    <div>
      <router-view />
    </div>
  </div>
</template>
