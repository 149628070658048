<script>
import ModalArchive from '@/components/shared/modal-archive';

export default {
  name: 'ArchiveAsset',

  components: { ModalArchive },

  props: {
    item: { type: Object },
    onArchive: { type: Function },
  },
};
</script>

<template>
  <modal-archive
    singular="asset"
    :on-archive="onArchive"
    @close="$emit('close')"
  >
    <p>{{$t('confirmation.confirm_archive_asset')}} <strong>{{item.name}}</strong>?</p>
  </modal-archive>
</template>

