<script>
import { mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import CountryApi from '@/api/country.api';
import PlanApi from '@/api/plan.api';
import BillingApi from '@/api/billing.api';
import AccountMenu from './account-menu';
import SubscriptionStatus from '../shared/subscription-status';

export default {
  name: 'AccountOverview',

  components: { AccountMenu, SubscriptionStatus },

  data() {
    return {
      model: {
        name: '',
        email: '',
        countryCode: '',
        timezone: '',
        currency: {},
        billing: {
          contact: '',
          email: '',
        },
        plan: null,
      },
      country: {
        timezones: [],
      },
      countries: [],
      isSaving: false,
      plan: null,
      upcomingInvoice: null,
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),
  },

  validations: {
    model: {
      name: { required },
      countryCode: { required },
      timezone: { required },
      currency: { required },
      billing: {
        contact: { required },
        email: { required, email },
      }
    },
  },

  created() {
    this.model = this.account.extract(Object.keys(this.model));

    this.model.billing = {
      contact: this.account.billing?.contact,
      email: this.account.billing?.email,
      entity: this.account.billing?.entity,
    };
    this.loadPlan();
    this.loadUpcomingInvoice();
    this.loadCountries();
  },

  methods: {
    changedCountry(country) {
      this.country = country;
      this.model.countryCode = country.code;
      this.model.timezone = country.timezones[0];
    },

    async loadPlan() {
      this.plan = await PlanApi.findById(this.model.plan);
    },

    async loadUpcomingInvoice() {
      if (this.account.subscriptionStatus === 'active') {
        this.upcomingInvoice = await BillingApi.upcomingInvoice();
      }
    },

    async loadCountries() {
      const { countryCode } = this.account;
      const countries = await CountryApi.query();

      this.countries = countries;
      this.country = countries.find(country => country.code === countryCode);
    },

    async save() {
      //Get data
      const { $v, account, model } = this;

      //Validate
      $v.$touch();
      if ($v.$invalid) {
        return;
      }

      this.isSaving = true;

      await account.updateOwn(model)
      this.isSaving = false;
      this.$notice.show('notices.account_updated');
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--sidemenu">
      <div class="Menu--col">
        <account-menu />
      </div>
      <div class="Content--mediumWidth">
        <h1 class="PageTitle">{{$t('account_overview.h1')}}</h1>

        <subscription-status />

        <div v-if="plan" class="Message-Info">
          <div class="Message-Header--compact">
            <div class="Message-Title">
              <div class="Message-Emphasis">
                {{$t('account_overview.account_is_on_the')}}
                <router-link to="/account/billing">{{ $t(`global.account_plans.${plan.name}.name`) }} Plan</router-link>
                {{$t('account_overview.and_has')}} 
              <router-link to="/directory/users">{{ account.activeUsersCount }} {{$tc('global.user_plural', account.activeUsersCount)}}</router-link>
              <span v-if="account.activeUsersCount > plan.limits.freeUsers">
                ({{ plan.limits.freeUsers }} {{$t('account_overview.included_and')}}
                {{ account.activeUsersCount - plan.limits.freeUsers }} {{$t('account_overview.paid')}})
              </span>
              <span v-else>
                (included)
              </span>
              </div>
            </div>
          </div>
          <div class="Spacer2XS"></div>

          <div v-if="upcomingInvoice" class="SubMessage">
            <div v-if="upcomingInvoice.ending_balance">
              {{$t("account_billing-history.credit_balance_of")}} {{
                -upcomingInvoice.ending_balance / 100 | currency
              }}
              {{ upcomingInvoice.currency.toUpperCase() }}
            </div>
            <div v-else>
              {{$t("account_billing-history.next_charge")}}
              {{ upcomingInvoice.amount_remaining / 100 | currency }}
              {{ upcomingInvoice.currency.toUpperCase() }} {{$t("global.on")}}
              <span class="nobr">{{
                (upcomingInvoice.next_payment_attempt * 1000)
                  | moment("D MMMM YYYY")
              }}</span
              >.
            </div>
          </div>
        </div>

        <div class="GroupSet">
          <div class="Group Group--inline" :class="{'Group--error': $v.model.name.$error}">
            <div class="Label Label--inline">{{$t('account_overview.account_name')}}</div>
            <input v-model="model.name" autocomplete="off" type="text" class="Input-M" :placeholder="$t('account_overview.name_of_account')">
            <div class="InputHint" v-if="$v.model.name.$error">
              <div v-if="!$v.model.name.required">{{$t('account_overview.account_name_required')}}</div>
            </div>
          </div>
        </div>
        <!-- NOTE: Probably don't need to let people change their country, as it could affect their
        Stripe currency which cannot be changed for an account and lead to other complications -->
        <div class="Group Group--inline" :class="{'Group--error': $v.model.countryCode.$error}">
          <div class="Label Label--inline">{{$t('account_overview.country')}}</div>
          <select-box class="SelectBox" :model="model.countryCode" :options="countries" label-by="name" track-by="code"
            :as-object="true" :editable="false" @change="changedCountry($event.value)" />
          <div class="InputHint" v-if="$v.model.countryCode.$error">
            <div v-if="!$v.model.countryCode.required">{{$t('account_overview.country_required')}}</div>
          </div>
        </div>

        <div class="Group Group--inline" :class="{'Group--error': $v.model.currency.$error}">
          <div class="Label Label--inline">{{$t('account_overview.currency')}}</div>
          <div class="InputWrapper">
            <input v-model="model.currency.code" autocomplete="off" type="text" class="Input-S" :placeholder="$t('account_overview.currency')"
              disabled>
          </div>
          <div class="InputHint" v-if="$v.model.currency.$error">
            <div v-if="!$v.model.name.required">{{$t('account_overview.currency_required')}}</div>
          </div>
        </div>

        <div class="Group Group--inline" :class="{'Group--error': $v.model.timezone.$error}">
          <div class="Label Label--inline">{{$t('account_overview.timezone')}}</div>
          <select-box class="SelectBox Select--small" :model="model.timezone" :options="country.timezones"
            @change="model.timezone = $event.value" />
          <div class="InputHint" v-if="$v.model.timezone.$error">
            <div v-if="!$v.model.timezone.required">{{$t('account_overview.currency_required')}}</div>
          </div>
        </div>

        <div>
          <div class="GroupSet">
            <div class="Group Group--inline" :class="{'Group--error': $v.model.billing.contact.$error}">
              <div class="Label Label--inline">{{$t('account_billing.billing_contact_name')}}</div>
              <input v-model="model.billing.contact" autocomplete="off" type="text" class="Input-M"
                :placeholder="$t('account_billing.billing_contact_name')">
              <div class="InputHint" v-if="$v.model.billing.contact.$error">
                <p v-if="!$v.model.billing.contact.required">{{$t('errors.required', {field:
                  $t('account_billing.billing_contact_name_required')})}}</p>
              </div>
            </div>
            <div class="Group Group--inline" :class="{'Group--error': $v.model.billing.email.$error}">
              <div class="Label Label--inline">{{$t('account_billing.billing_email')}}</div>
              <input v-model="model.billing.email" autocomplete="off" type="text" class="Input-M"
                :placeholder="$t('account_billing.billing_email')">
              <div class="InputHint" v-if="$v.model.billing.email.$error">
                <p v-if="!$v.model.billing.email.required">{{$t('errors.required', {field:
                  $t('account_billing.billing_email')})}}</p>
                <p v-if="!$v.model.billing.email.email">{{$t('errors.invalid_email', {field:
                  $t('account_billing.billing_email')})}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="Group-inline Group--button">
          <button-bar :is-busy="isSaving" :show-cancel="false" :label-confirm="$t('account_overview.save')" @confirm="save"></button-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.InputWrapper input:disabled {
  cursor: not-allowed;
}
</style>
