<script>

export default {
  props: {
    label: { type: String },
    postfix: { type: String },
    prefix: { type: String },
    type: { type: String },
    value: { type: [String, Object] },
  },
}

</script>

<template>
  <div :class="`display-field display-field--${type} Item--spaced Item--display-field`">
    <label class="Label" v-if="label">{{label}}</label>

    <template v-if="type === 'image'">
      <img v-if="value" :alt="label" :src="value" :v-bind="$attrs" />
      <div v-else :alt="label" class="no-placeholder-img">
        <div class="no-placeholder-img">
          <i class="Icon Icon-No-PreviewImage">no_photography</i>
          <p>{{$t('asset.no_preview')}}</p>
        </div>
      </div>
    </template>

    <template v-else-if="type === 'html'">
      <div v-html="value" :v-bind="$attrs" />
    </template>

    <template v-else-if="type === 'date'">
      <div :title="value|moment('ll')" :v-bind="$attrs">{{value|moment('ll')}}</div>
    </template>

    <template v-else-if="type === 'boolean'">
      <div :v-bind="$attrs">{{value ? 'Yes' : 'No'}}</div>
    </template>

    <template v-else>
      <div :v-bind="$attrs">
        <span v-if="prefix">{{prefix}}</span>{{value}}<span v-if="postfix">{{postfix}}</span>
      </div>
    </template>
  </div>
</template>

<style scoped>
  .no-placeholder-img {
    max-width: 100%;
    height: 10rem;
    background: white;
    border-radius: 0.35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>
