<template>
  <div class="ButtonBar">
    <div class="ButtonBar-primary">
      <button class="" v-if="showSave" :class="classesConfirm" :disabled="isDisabledConfirm || isBusy"
        @click="$emit('confirm')">
        {{ labelConfirm }}
        <spinner v-if="hasSpinnerConfirm && isBusy" />
      </button>
      <button class="" v-if="showSaveNext" :class="classesConfirm" :disabled="isDisabledConfirm || isBusy"
        @click="$emit('confirm')">
        {{ $t('shared_button-bar.save_and_next') }}
        <spinner v-if="hasSpinnerConfirm && isBusy" />
      </button>
      <button class="" v-if="showConfirm" :class="classesConfirm" :disabled="isDisabledConfirm || isBusy"
        @click="$emit('confirm')">
        {{ $t('shared_button-bar.confirm') }}
        <spinner v-if="hasSpinnerConfirm && isBusy" />
      </button>
      <button class="Button--ok" v-if="showActivate" :class="classesConfirm" :disabled="isDisabledConfirm || isBusy"
        @click="$emit('confirm')">
        {{ $t('shared_button-bar.activate') }}
        <spinner v-if="hasSpinnerConfirm && isBusy" />
      </button>
      <button class="Button--plain" v-if="showSaveDraft" :class="classesSaveDraft"
        :disabled="isDisabledSaveDraft || isBusy" @click="$emit('confirm')">
        {{ $t('shared_button-bar.save_draft') }}
        <spinner v-if="hasSpinnerConfirm && isBusy" />
      </button>
      <a class="Button--cancel" v-if="showCancel" :disabled="isDisabledCancel && isBusy" @click="$emit('cancel')">
        {{ $t('shared_button-bar.cancel') }}
      </a>
    </div>
    <div class="ButtonBar-secondary">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: {

    //Buttons visibility
    showCancel: {
      type: Boolean,
      default: true,
    },
    showSaveDraft: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    showSave: {
      type: Boolean,
      default: true,
    },
    showSaveNext: {
      type: Boolean,
      default: false,
    },
    showActivate: {
      type: Boolean,
      default: false,
    },
    //Buttons disabled state
    isDisabledCancel: {
      type: Boolean,
      default: false,
    },
    isDisabledSaveDraft: {
      type: Boolean,
      default: false,
    },
    isDisabledConfirm: {
      type: Boolean,
      default: false,
    },

    //Button type
    typeConfirm: {
      type: String,
      default: 'primary',
    },
    typeCancel: {
      type: String,
      default: 'cancel',
    },

    labelConfirm: {
      type: String,
      default: 'Confirm',
    },

    //Spinner state
    hasSpinnerConfirm: {
      type: Boolean,
      default: false,
    },

    //Convenience property to indicate standard business
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classesConfirm() {
      return {
        'Button--primary': this.typeConfirm === 'primary',
        'Button--ok': this.typeConfirm === 'ok',
        'Button--danger': this.typeConfirm === 'danger',
        'Button--warning': this.typeConfirm === 'warning',
      };
    },
  },
};
</script>

<style lang="scss">
.ButtonBar {
  width: 100%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &[-secondary] {
    .Button+.Button {
      margin-left: $spacingXS;
    }
  }
}

.Modal .ButtonBar {
  max-width: 100%;
}

.ButtonBar-primary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin: 0 -4px;

  button,
  .Button--cancel, 
  .Button--cancel-warning {
    margin: 0 5px;
  }
}
</style>
