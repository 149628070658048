import BaseModel from './base.model';
import CreditCardApi from '@/api/credit-card.api';
import Contact from './contact.model';

export default class CreditCard extends BaseModel {

  getModelName() {
    return 'CreditCard';
  }

  /**
   * From JSON conversion
   */
  fromJSON(json) {

    //Call parent method
    super.fromJSON(json);

    //Convert models
    this.convertToModel('contact', Contact);

    return this;
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return CreditCardApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    return CreditCardApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;

    return CreditCardApi.remove(id);
  }
}
