/**
 * A plugin to provide a convenience method on all vue instances.
 *
 * This method is a simple way to get a standardized HTML `id` attribute for any
 * element.
 *
 * Usage:
 *
 *  ```
 *    <label :for="$htmlID('namefield')">Your name</label>
 *    <input :id="$htmlID('namefield')" name="namefield" />
 *  ```
 *
 */

let uniqueID = 0;

const HtmlID = {

  install(Vue/*, options*/) {

    Vue.mixin({
      beforeCreate() {
        this.uuid = (++uniqueID).toString();
      },
    });

    // add an instance method
    Vue.prototype.$htmlID = function(fieldName = '') {
      return 'id_' + this.uuid + (fieldName ? '_' + fieldName : '');
    };

  },

};

export default HtmlID;
