<script>
import _ from 'lodash';
import ModalEdit from '@/components/shared/modal-edit';
import {required, email} from 'vuelidate/lib/validators';
import RoleTypes from '@/constants/role-types';

export default {
  components: { ModalEdit },

  data() {
    return {
      model: {
        role: null,
        name: null,
        email: null,
      },
      original: {},
      RoleTypes,
    };
  },

  props: {
    user: { type: Object },
    onRemove: { type: Function },
    onSave: { type: Function },
    isEdit: { type: Boolean },
  },

  validations: {
    model: {
      name: { required },
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    isDisabledConfirm() {
      return _.isEqual(this.model, this.original);
    },
  },

  created() {
    //Create model subset
    this.model = this.user.extract(Object.keys(this.model));
    this.original = _.cloneDeep(this.model);
  },
};
</script>

<template>
  <modal-edit
    singular="user"
    :model="model"
    :validation="$v"
    :is-edit="isEdit"
    :on-save="onSave"
    OFF:on-remove="onRemove"
    @close="$emit('close')"
    :is-disabled-confirm="isDisabledConfirm"
  >
    <div class="Form">
      <div class="Group">
        <label :for="$htmlID('role')" class="Label">{{$t('user_edit.role')}}</label>
        <select-box
          :id="$htmlID('role')"
          :model="model.role"
          :options="RoleTypes"
          trackBy="label"
          @change="model.role = $event.value"
        />
      </div>
      <div class="Group" :class="{'Group--error': $v.model.name.$error}">
        <label :for="$htmlID('name')" class="Label">{{$t('user_edit.name')}}</label>
        <input
          :id="$htmlID('name')"
          class="Input-100"
          type="text"
          v-model.trim="model.name"
        >
        <div class="InputHint" v-if="$v.model.name.$error">
          <div v-if="!$v.model.name.required">{{$t('user_edit.name_is_required')}}</div>
        </div>
      </div>
      <div class="Group" :class="{'Group--error': $v.model.email.$error}">
        <label :for="$htmlID('email')" class="Label">{{$t('user_edit.email')}}</label>
        <input
          :id="$htmlID('email')"
          class="Input-100"
          type="email"
          v-model.trim="model.email"
        >
        <div class="InputHint" v-if="$v.model.email.$error">
          <div v-if="!$v.model.email.required">{{$t('user_edit.email_address_is_required')}}</div>
          <div v-if="!$v.model.email.email">{{$t('user_edit.invalid_email_address')}}</div>
        </div>
      </div>
    </div>
  </modal-edit>
</template>
