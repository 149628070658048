/**
 * Define values
 */
export const CONFIRMED = 'confirmed';
export const ON_HOLD = 'onHold';
export const COMPLETED = 'completed';

/**
 * Define array
 */
export default [
  {
    value: CONFIRMED,
    label: `Confirmed`,
  },
  {
    value: ON_HOLD,
    label: `On hold`,
  },
  {
    value: COMPLETED,
    label: `Completed`,
  },
];
