<script>
import ContextMenu from '@/components/shared/context-menu';

export default {
  components: { ContextMenu },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    emitEvent(e) {
      this.$emit(e);
    },
  },
  
};
</script>

<template>
  <context-menu>
    <slot />
    <li v-if="options.includes('view')">
      <a @click="emitEvent('view')"> {{$t('shared_settings-options.view')}}</a>
    </li>
    <li v-if="options.includes('edit')">
      <a @click="emitEvent('edit')">{{$t('shared_settings-options.edit')}}</a>
    </li>
    <li v-if="options.includes('send')">
      <a @click="emitEvent('send')">{{$t('shared_settings-options.send')}}</a>
    </li>
    <li v-if="options.includes('markPaid')">
      <a @click="emitEvent('markPaid')">{{$t('shared_settings-options.mark_paid')}}</a>
    </li>
    <li class="ContextMenu-item--danger" v-if="options.includes('remove')">
      <a @click="emitEvent('remove')">{{$t('shared_settings-options.remove')}}</a>
    </li>
    <li class="ContextMenu-item--danger" v-if="options.includes('archive')">
      <a @click="emitEvent('archive')">{{$t('shared_settings-options.archive')}}</a>
    </li>
    <li class="ContextMenu-item--warning" v-if="options.includes('stop_archive')">
      <a @click="emitEvent('stop_archive')">{{$t('shared_settings-options.stop_and_archive')}}</a>
    </li>
    <li :title="$t('shared_settings-options.disabled_title')" class="disabled" v-if="options.includes('archive-disabled')">
      <a class="disabled">{{$t('shared_settings-options.archive')}}</a>
    </li>
    <li v-if="options.includes('unArchive')">
      <a @click="emitEvent('un-archive')">{{$t('shared_settings-options.unarchive')}}</a>
    </li>

  </context-menu>
</template>
