/**
 * Dependencies
 */
import contactTypes from '@/constants/contact-types';

/**
 * Contact type filter
 */
export default function(value) {

  //Find corresponding item
  const item = contactTypes.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
