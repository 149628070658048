<script>
import { mapState } from 'vuex';

export default {
  props: {
    plans: { type: Array, required: true },
    model: { type: [Object, String] },
    originalPlan: { type: String },
    originalInterval: { type: String },
    choose: { type: Function },
    interval: { type: String },
  },

  data() {
    return {
      selectedPeriod: null
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),
  },

  created() {
    this.selectedPeriod = this.interval;
  },

  methods: {
    isSelected(product) {
      return this.model === product.id && this.interval === this.selectedPeriod;
    },

    isCurrent(product) {
      return this.originalPlan === product.id && this.originalInterval === this.selectedPeriod;
    },

    toggleSwitch() {
      this.selectedPeriod = this.selectedPeriod === 'month' ? 'year' : 'month';
      this.$emit('periodChanged', this.selectedPeriod);
    },

    isDisabled(plan) {
      return this.account.activeUsersCount > plan.limits?.allUsers;
    }
  },
};
</script>

<template>
  <div>
    <div class="SpacerL"></div>
    <div class="PeriodToggle">
      <div class="PeriodToggle-Monthly">{{ $t('global.account_plans.monthly_plans') }}</div>
      <div class="Toggle-Switch" :class="{ active: selectedPeriod === 'year' }" @click="toggleSwitch"></div>
      <div class="PeriodToggle-Yearly">{{ $t('global.account_plans.yearly_plans') }} 
        <span class="yearly_plans_reason">
          <span class="yearly_plans_bonus">{{ $t('global.account_plans.yearly_plans_bonus') }}</span> – 
            {{ $t('global.account_plans.yearly_plans_reason') }}
        </span>
      </div>
    </div>
    <div class="SpacerXL"></div>
    <div class="PlanSelect--monthly">
      <div
        v-for="plan in plans"
        :key="plan.id"
        @click="isDisabled(plan) ? null : choose(plan, selectedPeriod)"
        class="PlanContainer"
      >
      <div v-if="isCurrent(plan)" class="Plan-Tab--current">
          <span>{{$t('global.account_plans.current_plan')}}</span>
        </div>
        <div v-else-if="plan.name === 'professional' && !isCurrent(plan)" class="Plan-Tab--popular">
          <span>{{$t('global.account_plans.popular')}}</span>
        </div>
        <div v-else-if="plan.name === 'advanced' && !isCurrent(plan)" class="Plan-Tab--affordable">
          <span>{{$t('global.account_plans.affordable')}}</span>
        </div>
        <div v-else class="Plan--topSpacer"></div>
        <div :class="[
          'Plan',
          {
            'disabled': isDisabled(plan),
            'selected': isSelected(plan),
            'current': isCurrent(plan),
          }
        ]">
          <h3 class="Plan-Name">{{ $t(`global.account_plans.${plan.name}.name`) }}</h3>
          <div class="Plan-Price">
            <div class="Plan-PriceNumber">{{ $t(`global.account_plans.${plan.name}.price_${selectedPeriod}`) }}</div>
            <div>
              <div class="Plan-Currency">USD<br /> {{$t(`global.account_plans.per_${selectedPeriod}`)}}</div>
            </div>
          </div>
          <div class="Plan-Blurb">{{ $t(`global.account_plans.${plan.name}.blurb`) }}</div>
            <div class="Plan-Limits">
              <div class="Plan-Limit">
                <i class="Icon-Base">check</i>
                <div>
                  <strong>
                    {{ $t(`global.account_plans.${plan.name}.active_asset_number`) }}
                  </strong> {{$t('global.account_plans.active_assets')}}
                </div>
              </div>
              <div class="Plan-Limit">
                <i class="Icon-Base">check</i>
                <div>
                  <strong>{{$t(`global.account_plans.${plan.name}.annual_revenue_cap_amount`)}}</strong> {{$t('global.account_plans.annual_revenue_cap')}}
                </div>
              </div>
              <div class="Plan-Limit">
                <i class="Icon-Base">check</i>
                <div>
                  <strong>{{$t('global.account_plans.unlimited')}}</strong> {{$t('global.account_plans.app_integrations')}}
                </div>
              </div>
              <div class="Plan-Limit">
                <i class="Icon-Base">check</i>
                <div>
                  <strong>2 {{$t('global.account_plans.included_users')}}</strong> –<br />
                  <strong>{{$t(`global.account_plans.${plan.name}.price_extra_users`)}}</strong>
                </div>
              </div>
            </div>
            <div class="SpacerL"></div>
            <div class="Button Button--current" v-if="isCurrent(plan)">
              <i class="Icon-Base">star</i>{{$t('global.account_plans.current_plan')}}
            </div>
            <div v-else-if="isDisabled(plan)" class="planUnailable-Wrap">
              <div class="Button Button--planUnavailable" disabled="disabled">
                <i class="Icon-Base">block</i>{{$t('global.account_plans.not_available')}}
              </div>
              <div class="SpacerXS"></div>
              <div class="Description">{{$t('global.account_plans.plan_limits_exceeded')}}</div>
            </div>
            <div class="Button Button--selected" v-else-if="isSelected(plan)">
              {{$t('global.account_plans.selected')}}
            </div>
            <div v-else class="Button">
              {{$t('global.account_plans.select_plan')}}
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.PeriodToggle {
  display: flex;
  gap: $gapXL;
  align-items: center;
  justify-content: center;
  font-weight: $fontWeightMedium;
  margin-bottom: $spacing2XL;
  margin-top: $spacingL;
  .PeriodToggle-Monthly,
  .PeriodToggle-Yearly {
    display: flex;
    font-size: $fontSizeS;
    text-align: center;
  }
  .yearly_plans_reason {
    font-weight: $fontWeightMedium;
    color: $colorTextFaded2;
    margin-left: $spacingXS;
    .yearly_plans_bonus {
      color: $colorPrimary;
    }
  }
}

.PlanSelect--monthly,
.PlanSelect--yearly {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  gap: $spacingXL;
}

.PlanContainer {
  display: flex;
  flex-direction: column;
}

.Plan-Tab--current,
.Plan-Tab--popular,
.Plan-Tab--affordable {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.Plan-Tab--current span,
.Plan-Tab--popular span,
.Plan-Tab--affordable span {
  font-size: $fontSizeXS;
  padding: $spacingXS $spacingXL;
  border-radius: $borderRadiusM $borderRadiusM 0 0;
  min-width: 8rem;
  text-align: center;
}

.Plan-Tab--current span {
  background: $colorText;
  color: $colorDarkButtonText;
  border: 1px solid $colorTextFaded1;
  border-bottom: none;
}

.Plan-Tab--popular span {
  color: $colorPrimary;
  background: $colorDarkButtonText;
  border: 1px solid $colorPrimary;
  border-bottom: none;
}
.Plan-Tab--affordable span {
  color: $colorPrimary;
  background: $colorDarkButtonText;
  border: 1px solid $colorPrimary;
  border-bottom: none;
}
.Plan--topSpacer,
.Plan-Tab--current,
.Plan-Tab--affordable,
.Plan-Tab--popular  {
  height: 1.5rem;
  margin-top: 0.5rem;
}

.Plan {
  @include shadows;
  @include shadows;
  border: 1px solid $colorBordersLight;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding:  $spacing3XL $spacing3XL $spacingL ;
  cursor: pointer;
  border-radius: $borderRadiusXL;

  .period .Input-Button {
    margin-left: -10px;
  }

  &.selected {
    border: 1px solid $colorAdminBlue;
    cursor: inherit;
    background-color: $colorBgInfo;
  }

  &.current {
    border: 1px solid $colorText;
    cursor: inherit;
    background-color: $colorBgPanels;
  }

  .Plan .current {
    color: $colorPrimary;
  }

  .Plan-Name {
    font-size: $fontSizeM;
    font-weight: $fontWeightMedium;
    margin-bottom: $spacingL;
  }

  .Plan-Price {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: $spacingL;

    .Plan-PriceNumber {
      font-size: $fontSizeXL;
      margin-right: $spacingS;
    }
    .Plan-Currency {
      font-size: $fontSizeXS;
      line-height: $lineHeightS;
    }
  }

  .Plan-Blurb {
    color: $colorTextFaded2;
    margin-bottom: $spacingXL;
    font-size: $fontSizeS;
  }
 
  .Plan-Limit {
    display: flex;
    margin-bottom: $spacingS;
    color: $colorTextFaded1;
    font-size: $fontSizeXS;
    font-weight: $fontWeightNormal;
    .Icon-Base {
      margin-right: $spacingS;
      color: $colorPrimary;
    }
  }

  .planUnailable-Wrap {
    text-align: center;
    margin-top: auto;
  }

  .Button--planUnavailable {
    color: $colorValueNotSet;
    background: none;
    margin-top: auto;
    border: 1px dashed $colorValueNotSet;
    .Icon-Base {
      color: $colorValueNotSet;
    }
  }

  .Button {
    width: 100%;
    margin-top: auto;
    background: $colorPrimary;
    color: $colorDarkButtonText;
    &:hover {
      background: $colorPrimaryHover;
    }
    &.Button--selected {
      color: $colorPrimary;
      background: $colorButton;

      .Icon-Base {
        color: $colorPrimary;
        margin: -2px 0;
        font-weight: $fontWeightBold;
      }
    }
    .Icon-Base {
      margin: -2px 0;
    }
  }

  .Button--current {
    background: none;
    color: $colorText;
    &:hover {
      cursor: default;
      background: none;
    }
    border: 1px dashed $colorText;
  }

  .Radio-Wrap {
    display: flex;
    align-items: flex-start;
    .Headline {
      font-weight: $fontWeightBold;
      font-size: $fontSizeL;
      text-transform: capitalize;    }
    input {
      position: relative;
      left: -10px;
      top: 10px;
    }
  }
}

.PlanCurrent {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: $fontSizeS;
}
</style>
