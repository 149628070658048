<script>
import _ from 'lodash';
import ContactTypes from '@/constants/contact-types';
import ModalEdit from '@/components/shared/modal-edit';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: { ModalEdit },

  data() {
    return {
      model: {
        type: null,
        name: null,
        email: null,
        contactPerson: null,
      },
      original: {},
      types: ContactTypes,
    };
  },

  props: {
    contact: { type: Object },
    onSave: { type: Function },
    isEdit: { type: Boolean },
  },

  validations() {
    if (this.model.type === 'organisation') {
      return {
        model: {
          type: { required },
          name: { required },
          email: { required, email },
          contactPerson: { required },
        }
      }
    } else {
      return {
        model: {
          type: { required },
          name: { required },
          email: { required, email },
        }
      }
    }
  },

  computed: {
    isDisabledConfirm() {
      return _.isEqual(this.model, this.original);
    },
  },

  created() {

    //Create model subset
    this.model = this.contact.extract(Object.keys(this.model));
    this.original = _.cloneDeep(this.model);
  },
};
</script>

<template>
  <modal-edit
    singular="client"
    :model="model"
    :validation="$v"
    :is-edit="isEdit"
    :on-save="onSave"
    @close="$emit('close')"
    :is-disabled-confirm="isDisabledConfirm"
  >
    <div class="Form">
      <div class="Row-M" :class="{ 'Group--error': $v.model.type.$error }">
          <label :for="$htmlID('select-type')" class="Label">{{ $t('directory_modals_client-edit.client_type') }}</label>
          <select-box :id="$htmlID('select-type')" :model="model.type" :options="types"
            @change="model.type = $event.value" />
          <div class="InputHint" v-if="$v.model.type.$error">
            <div v-if="!$v.model.type.required">{{ $t('directory_modals_client-edit.client_type_required') }}</div>
        </div>
      </div>

      <div class="Row-M" :class="{ 'Group--error': $v.model.name.$error }">
          <label :for="$htmlID('name')" class="Label">{{ $t('directory_modals_client-edit.client_name') }}</label>
          <input :id="$htmlID('name')" class="Input-100" type="text" v-model.trim="model.name">
          <div class="InputHint" v-if="$v.model.name.$error">
            <div v-if="!$v.model.name.required">{{ $t('directory_modals_client-edit.client_name_required') }}</div>
          </div>
      </div>
      <div v-if="model.type === 'organisation'" class="Row-M">
        <div class="Group">
          <label :for="$htmlID('contactPerson')" class="Label">{{ $t('directory_modals_client-edit.billing_contact_name')
          }}</label>
          <input :id="$htmlID('contactPerson')" class="Input-100" type="text" v-model.trim="model.contactPerson">
          <div class="InputHint" v-if="$v.model.contactPerson.$error">
            <div v-if="!$v.model.contactPerson.required">{{ $t('directory_modals_client-edit.billing_contact_name_required') }}</div>
          </div>
        </div>
      </div>
      <div class="Row-M" :class="{ 'Group--error': $v.model.email.$error }">
        <label :for="$htmlID('email')" class="Label">{{ $t('directory_modals_client-edit.client_email') }}</label>
        <input :id="$htmlID('email')" class="Input-100" type="email" v-model.trim="model.email">
        <div class="InputHint" v-if="$v.model.email.$error">
          <div v-if="!$v.model.email.required">{{ $t('directory_modals_client-edit.client_email_required') }}</div>
          <div v-if="!$v.model.email.email">{{ $t('directory_modals_client-edit.client_email_invalid') }}</div>
      </div>
    </div>
    </div>
  </modal-edit>
</template>
