<script>
export default {
  data() {
    return {
      currentOn: '',
    };
  },

  props: {
    totalItems: { type: Number },
    page: { type: Number },
    numPages: { type: Number },
    type: { type: String },
    title: { type: String },
    filter: { type: Object },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    loadPage: { type: Function },
  },

  computed: {
    searchPlaceholder() {
      return `Search ${this.pluralTitle}`;
    },

    pluralTitle() {
      let title = this.$te(this.type)
        ? this.$tc(this.type, this.totalItems)
        : this.type;

      title = this.$root.$options.filters.inflect(title, 'pluralize');

      return title.toLowerCase();
    },

    addButtonTitle() {
      if (this.$te(this.type)) {
        return `Add ${this.$t(this.type)}`;
      }
      return `Add ${this.type}`;
    },
  },

  methods: {
    search() {
      this.filter.wasUpdated('search');
    },

    advanceSearch() {
      this.currentOn = !this.currentOn;
      this.filter.wasUpdated('search');
    },

    /**
     * Mark currentOn tracking who is open
     */
    updateCurrentOn($event) {
      this.currentOn = $event;
    },
  },
};
</script>

<template>
  <div class="PageMeta">
    <div class="PageMeta-topRow">
      <div class="Container">
        <div class="PageMeta-wrap">
          <div class="PageMetaDetails">
            <div class="PageMetaTitleWrap">
              <h1 class="PageMeta-Title">
                {{ pluralTitle }}
              </h1>
            </div>
            <slot name="settings" />
          </div>
          <div class="PageMetaSearch" v-if="showSearch">
            <i class="Icon-Base">search</i>
            <input class="Input-100 Input-Search" type="search" :placeholder="searchPlaceholder" v-model="filter.search"
              v-debounce:500ms="search" @search="search" />
          </div>
          <div class="ButtonWrap">
            <button v-if="showAddButton" @click="$emit('add')" class="Button--cta">
              {{ addButtonTitle }}
            </button>
            <slot name="actions" />
          </div>
        </div>

        <div class="Overview-Notice" v-if="$slots.warning">
          <div class="Message-Warning">
            <div class="Message-Header--compact">
              <i class="Icon-Base--warning">error_outline</i>
              <div class="Message-Title">
                <slot name="warning" />
              </div>
          </div>
        </div>
        <div class="SpacerXL"></div>
      </div>

        <div class="PageMeta-bottomRow">
          <menu class="PageMetaTabs">
            <slot name="tabs" />
          </menu>
          <pagination
            :show-all="totalItems < 500"
            :page="page"
            class="Pagination--higher"
            :num-pages="numPages"
            @change="loadPage($event.page)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.PageMeta-bottomRow {
  background: $colorBgPageMetaTabs;
  margin: 0 0 $spacingS $spacingXL;
  display: flex;
  justify-content: space-between;
}

.PageMetaTitleWrap {
  padding-left: $spacing3XL;
  min-width: 145px;
}

.PageMetaTabs {
  display: flex;
  align-items: center;
  z-index: 1;

  @include breakPointMedium {}

  .PageMeta-configure {
    border-right: 2px solid $colorBordersNormal;
    padding-right: 1rem;
    color: $colorBordersNormal;

    &:hover {
      color: $colorBlackFaded1;
      cursor: pointer;
    }
  }

  .PageMeta-tab,
  .PageMeta-tab--first {
    border-radius: $borderRadiusM;
    color: $colorText;
    font-weight: $fontWeightMedium;
    padding: $spacingS $spacingL;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: $fontSizeS;

    i {
      margin-right: 0.3rem;
      font-size: 1.1rem;
    }

    &:hover {
      color: $colorText;
      cursor: pointer;
    }

    &.active,
    &.is-active {
      color: $colorText;
      background: $colorActiveTabs;
      cursor: default;
    }
  }

  a {
    color: $colorText;

    &:hover {
      color: $colorLinkHover;
    }
  }

  .active:hover,
  .is-active:hover {
    color: $colorText;
  }
}

.PageMeta-tab {
  margin: 0 $spacingM;
}

.PageMeta-tab--first {
  margin: 0 $spacingM 0 0;
}

.PageMeta-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0 $spacingXL;

  .PageMetaDetails {
    display: flex;
    flex: 0.8;
    align-items: center;
    padding-top: $spacingS;

    h1.PageMeta-Title {
      padding: 0;
      margin-bottom: 0;
      margin: 0;
      text-transform: capitalize;

      @include breakPointMedium {}
    }

    .PageMeta-Title--descriptor {
      text-align: right;
    }
  }

  .ButtonWrap {
    flex: 1;
    display: flex;
    justify-content: right;
    padding-top: $spacingS;
    margin-right: $spacingXL;

    .Button--primary {
      @include breakPointMedium {
        margin: 0 1.6rem 0 0;
        // order: 3;
      }
    }
  }
}

.PageMetaSearch {
  display: flex;
  flex: 1;
  padding-top: $spacingXL;

  .Input-Search {
    padding: $spacingL $spacingL $spacingL 42px;
    position: relative;
    left: -24px;
  }

  .Icon-Base {
    position: relative;
    top: 10px;
    left: 14px;
    z-index: 500;
    font-size: 27px;
    font-weight: 500;
    color: #ABB1BE;
  }

  .SearchFilter {
    width: 3rem;
    padding: $spacingS;
    background: $colorBgInput;
    font-size: $fontSizeL;
    border-top-right-radius: $borderRadiusM;
    border-bottom-right-radius: $borderRadiusM;
    display: flex;
    justify-content: center;
    align-items: center;

    .SearchFilterButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 3rem;
      color: $colorTextFaded2;

      &:hover {
        cursor: pointer;
        color: $colorText;
        background: $colorBlackFaded3;
      }

      .Icon {
        margin-right: 0;
      }
    }

    .SearchFooter {
      display: flex;
      justify-content: flex-end;
      padding: 0 0 $spacingL 0;
    }
  }

  .SearchFilter-content {
    min-width: 40rem;
    width: 100%;
    padding: $spacingXL;
  }

  @include breakPointMedium {
    padding-top: 0;
    width: 20rem;
  }

  @include breakPointLarge {
    width: 25rem;
  }

  input {
    background: $colorBgInput;
    width: 100%;
  }
}
</style>
