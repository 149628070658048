import auth from '@/services/auth';
import store from '@/store';

/**
 * Authentication guard
 */
async function authentication(to, from, next) {

  //Load token
  await auth.loadToken();

  //Clear page data
  store.dispatch('page/clear');

  //Check if route requires authentication
  if (!to.matched.some(record => record.meta.auth)) {
    store.dispatch('page/loaded', true);
    return next();
  }

  //Have token but expired? Try to refresh
  if (auth.hasToken() && auth.isTokenExpired()) {
    await auth.refresh();
  }

  //Load user and account if we're authenticated
  if (auth.hasToken() && !auth.isTokenExpired()) {
    if (!store.state.session.user || !store.state.session.account) {

      //Load user and account
      await Promise.all([
        store.dispatch('session/loadUser'),
        store.dispatch('session/loadAccount'),
      ]);

      //Mark page as loaded
      store.dispatch('page/loaded', true);
    }
    document.getElementsByTagName('body')[0].classList.add('authenticated');
    return next();
  }

  //Unload user and account
  store.dispatch('session/unloadUser'),
  store.dispatch('session/unloadAccount'),
  document.getElementsByTagName('body')[0].classList.remove('authenticated');

  //Go to login
  next({
    name: 'login',
    query: {redirect: to.name},
  });
}

/**
 * Export
 */
export default authentication;
