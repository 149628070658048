import http from '@/services/http';
import Account from '@/models/account.model';

export default {

  /**
	 * Get data for logged in account
	 */
  own() {
    return http
      .get('/account/own')
      .then(data => Account.createFrom(data));
  },

  updateOwn(data) {
    return http
      .put('/account/own', data);
  },

  updateOwnSettings(data) {
    return http
      .put('/account/own/settings', data);
  },

  updateOwnPlan(data) {
    return http
      .put('/account/own/plan', data);
  },

  updateOwnBilling(data) {
    return http
      .put('/account/own/billing', data);
  },

  archive() {
    return http
      .post('/account/own/archive');
  }
};
