import moment from 'moment-timezone';

//Get prototype
const proto = Object.getPrototypeOf(moment());

/**
 * Set base time
 */
moment.setBaseTime = function(baseTime) {
  let offset = new Date(baseTime).getTime() - Date.now();
  moment.now = function() {
    return Date.now() + offset;
  };
};

/**
 * Redefine weekdays functions to be in ISO format
 */
moment.wkDays = function() {
  const days = moment.weekdays();
  days.push(days.splice(0, 1)[0]);
  return days;
};
moment.wkDaysShort = function() {
  const days = moment.weekdaysShort();
  days.push(days.splice(0, 1)[0]);
  return days;
};
moment.wkDaysMin = function() {
  const days = moment.weekdaysMin();
  days.push(days.splice(0, 1)[0]);
  return days;
};

/**
 * Use iso weeks by default
 */
const {startOf, endOf} = proto;
proto.startOf = function(period) {
  if (period === 'week') {
    period = 'isoWeek';
  }
  return startOf.call(this, period);
};
proto.endOf = function(period) {
  if (period === 'week') {
    period = 'isoWeek';
  }
  return endOf.call(this, period);
};

//Export again
export default moment;
