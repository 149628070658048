<script>
import DecorativeOption from './decorative-option.vue';

export default {
  inheritAttrs: false,

  components: { DecorativeOption },

  data() {
    return {
      isShowingOptions: false,
      hasOptions: false,
      selectedLabel: '',
      selectionIndex: -1,
    };
  },

  props: {
    model: {
      type: [String, Number, Object],
      default: null,
      validator: prop => (
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        typeof prop === 'object' ||
        prop === null
      ),
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: 'label',
    },
    specialDisplay: {
      type: String,
      default: '',
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    asObject: {
      type: Boolean,
      default: false,
    },
    isNullable: {
      type: Boolean,
      default: false,
    },
    nullValue: {
      type: null,
      default: null,
    },
    nullLabel: {
      type: String,
      default: '...',
    },
    bindKey: {
      type: String,
      default: 'value',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disabledOptionLabel: {
      type: String,
      default: '',
    },
  },

  created() {
    this.boundClickHandler = this.documentClickHandler.bind(this);
    this.body = document.getElementsByTagName('body')[0];

    //Apply global click handler
    //NOTE: applied on body, so that it can prevent global document handlers
    this.body.addEventListener('click', this.boundClickHandler);

    this.onChanges();
  },

  destroyed() {
    this.body.removeEventListener('click', this.boundClickHandler);
  },

  watch: {
    model() {
      this.onChanges();
    },

    options() {
      this.onChanges();
    },
  },

  methods: {
    onChanges() {
      const { model, options } = this;
      const option = this.findOption(model, options);

      //Determine selected label and check if we have options
      this.selectedLabel = this.getLabelValue(option);
      this.hasOptions = options.length > 0;
    },

    showOptions() {
      this.isShowingOptions = true;
    },

    hideOptions() {
      this.isShowingOptions = false;
    },

    toggleOptions() {
      if (!this.editable) {
        return false;
      }
      if (this.isShowingOptions) {
        this.hideOptions();
      }
      else {
        this.showOptions();
      }
    },

    selectOption(option, index) {
      this.hideOptions();

      //Get the new model value and call on change handler
      const value = this.getModelValue(option, index);
      this.$emit('change', {value, option, index});
    },

    isSelection(index) {
      return (this.selectionIndex === index);
    },

    setSelection(index) {
      this.selectionIndex = index;
    },

    confirmSelection(index, disabled = false) {
      const {isNullable, hasOptions, options, selectionIndex} = this;

      if (disabled) {
        return false;
      }

      //If index not given, use current selection index
      if (typeof index === 'undefined') {
        index = selectionIndex;
      }

      //Initialize option
      let option;

      //Nullable and -1 index given?
      if (isNullable && index === -1) {
        option = null;
      }

      //Otherwise, take from given options
      else {

        //Validate index
        if (
          !hasOptions ||
          typeof index === 'undefined' ||
          typeof options[index] === 'undefined'
        ) {
          return;
        }

        //Get option
        option = options[index];
      }

      //Select option now
      this.selectOption(option, index);
    },

    /**
     * Helper to get the tracking value of an option
     */
    getTrackingValue(option, index) {
      const {trackBy, nullValue} = this;

      if (option === null) {
        return nullValue;
      }

      if (trackBy === '$index') {
        return index;
      }

      //Non object? Track by its value
      if (typeof option !== 'object') {
        return option;
      }

      if (!trackBy) {
        throw new Error('Missing track-by property for select box');
      }

      //Validate property
      if (typeof option[trackBy] === 'undefined') {
        throw new Error(`Unknown property '${trackBy}' for select box tracking`);
      }

      return option[trackBy];
    },

    getModelValue(option, index) {
      const {isNullable, nullValue, asObject} = this;

      //If nullable and null option given, return null value
      if (isNullable && option === null) {
        return nullValue;
      }

      //If returning as object, return the selected option
      if (asObject) {
        return option;
      }

      //Otherwise, return the tracking value of the given option
      return this.getTrackingValue(option, index);
    },

    getLabelValue(option) {
      const { labelBy, nullLabel } = this;

      if (option === null || typeof option === 'undefined') {
        return nullLabel;
      }

      //Non object? Use its value
      if (typeof option !== 'object') {
        return option;
      }

      //Must have label property
      if (!labelBy) {
        throw new Error('Missing label-by property for select box');
      }

      //Validate property
      if (typeof option[labelBy] === 'undefined') {
        throw new Error(`Unknown property '${labelBy}' for select box label`);
      }

      return option[labelBy];
    },

    /**
     * Find the selected option based on the model value
     */
    findOption(model, options) {
      const {trackBy, nullValue} = this;

      //Nothing selected or null value selected?
      if (typeof model === 'undefined' || model === nullValue) {
        return null;
      }

      //Tracking by index?
      if (trackBy === '$index') {
        if (typeof options[model] !== 'undefined') {
          return options[model];
        }
        return null;
      }

      //Get the model value
      let modelValue = this.getTrackingValue(model, model);

      //Find matching option
      return options
        .find((option, index) => {
          let optionValue = this.getTrackingValue(option, index);
          return (modelValue === optionValue);
        });
    },

    documentClickHandler(event) {
      //Not showing options or clicked on the selectbox?
      if (!this.isShowingOptions || this.$el.contains(event.target)) {
        return;
      }

      this.hideOptions();
      event.preventDefault();
      event.stopPropagation();
    },
  },
};
</script>

<template>
  <div class="SelectBox">
    <div
      class="InputWrapper"
      :class="editable ? 'is-clickable' : ''"
      @click="toggleOptions"
    >
      <div class="Caret"
        v-if="editable"
        @click.stop="toggleOptions()"
      ></div>
      <input
        v-bind="$attrs"
        readonly
        class="Input-100"
        type="text"
        :disabled="!editable"
        v-model="selectedLabel"
      >
    </div>
    <ul class="SelectBox-options" v-if="isShowingOptions">
      <li
        v-if="isNullable || !hasOptions"
        :class="{selected: isSelection(-1)}"
        @mouseover="setSelection(-1)"
        @click.prevent="confirmSelection(-1)"
      >{{nullLabel}}</li>
      <li
        v-for="(option, index) in options"
        :key="option[bindKey]"
        :class="{selected: isSelection(index), disabled: option.disabled}"
        @mouseover="setSelection(index)"
        @click.prevent="confirmSelection(index, option.disabled)"
      >
        <DecorativeOption
          v-if="specialDisplay !== ''"
          :option="option"
          :labelBy="labelBy"
          :special-display="specialDisplay"
        />
        <span v-else>{{getLabelValue(option)}}</span>
        <span v-if="option.disabled">{{disabledOptionLabel}}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">

$caretSize: 5px;

.SelectBox {
  position: relative;
  .Input {
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
}
.SelectBox-options {
  @include dropdownBox;
  background-color: $colorWhite;
  -webkit-box-shadow: 0px $spacingS  $spacingL 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px $spacingS  $spacingL 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.35rem;
  border: 1px solid #d2d5de;
  padding: 10px 0;
  width: 100%;
  max-height: 440px;
  li {
    @include dropdownBoxItem;
    padding: 10px 15px;
    &.disabled {
      cursor: not-allowed;
    }
  }
}
.Pagination--lower .SelectBox-options {
  @include dropdownBox-Up;
}

.Caret {
  display: block;
  position: absolute;
  cursor: pointer;
  right: $spacingXL;
  top: 50%;
  margin-top: -1px;
  width: 0;
  height: 0;
  border-top: $caretSize solid $colorBlack;
  border-right: $caretSize solid transparent;
  border-left: $caretSize solid transparent;
}
</style>
