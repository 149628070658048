// import momentFilter from './moment';
import inflectFilter from './inflect';
import jsonFilter from './json';
import contactTypeFilter from './contact-type';
import contractStatusFilter from './contract-status';
import billingOptionFilter from './billing-option';
import recurrenceIntervalFilter from './recurrence-interval';
import recurrenceEndsFilter from './recurrence-ends';
import invoiceStatusFilter from './invoice-status';
import projectStatusFilter from './project-status';
import paymentMethodFilter from './payment-method';
import paymentStatusFilter from './payment-status';
import saleStageFilter from './sale-stage';
import changeCaseFilter from './change-case';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

export default function init(Vue) {
  Vue.use(VueMoment, { moment });
  Vue.filter('inflect', inflectFilter);
  Vue.filter('json', jsonFilter);
  Vue.filter('contactType', contactTypeFilter);
  Vue.filter('contractStatus', contractStatusFilter);
  Vue.filter('billingOption', billingOptionFilter);
  Vue.filter('recurrenceInterval', recurrenceIntervalFilter);
  Vue.filter('recurrenceEnds', recurrenceEndsFilter);
  Vue.filter('invoiceStatus', invoiceStatusFilter);
  Vue.filter('projectStatus', projectStatusFilter);
  Vue.filter('paymentMethod', paymentMethodFilter);
  Vue.filter('paymentStatus', paymentStatusFilter);
  Vue.filter('saleStage', saleStageFilter);
  Vue.filter('changeCase', changeCaseFilter);
}
