import BaseModel from './base.model';
import OfferingApi from '@/api/offering.api';
import Contact from './contact.model';

export default class Offering extends BaseModel {

  getModelName() {
    return 'Offering';
  }

  fromJSON(json) {

    //Call parent method
    super.fromJSON(json);

    //Convert models
    this.convertToModel('contact', Contact);

    //Return self
    return this;
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {
    const json = this.toJSON(data);

    return OfferingApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {
    const json = this.toJSON(data);
    const {id} = this;

    return OfferingApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;
    return OfferingApi.remove(id);
  }

  archive() {
    return this.update({ archived: true });
  }

  unArchive() {
    return this.update({ archived: false });
  }
}
