<template>
  <div class="Modal">
    <div class="Modal-Header">
      <h1>Mark invoice as paid</h1>
      <i
        class="Icon-ModalsClose"
        @click="$emit('close')"
      >close</i>
    </div>

    <div class="ModalBody">
      <div class="Group">
        <div class="Group Group--1of2">
          <label class="Label">Invoice no.</label>
          <p>{{invoice.number}}</p>
        </div>
        <div class="Group Group--2of2">
          <label class="Label">Amount</label>
          <p>{{invoice.totalInclTax | currency}}</p>
        </div>
      </div>
      <div class="Group">
        <div class="Group Group--1of2">
          <label class="Label">Payment method</label>
          <select-box
            :model="model.method"
            :options="paymentMethods"
            @change="model.method = $event.value"
          />
        </div>
        <div class="Group Group--2of2">
          <label class="Label">Date paid</label>
          <date-picker
            :date="model.date"
            :can-clear="true"
            @change="model.date = $event.date"
          />
        </div>
      </div>

      <div class="Group">
        <label class="Label">Reference</label>
        <input
          class="Input-100"
          type="text"
          v-model="model.reference"
        >
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isSaving"
        @cancel="$emit('close')"
        @confirm="confirm"
      ></button-bar>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import {offline as paymentMethods} from '@/constants/payment-methods';

export default {
  data() {
    return {
      paymentMethods,

      model: {
        method: paymentMethods[0].value,
        date: moment().startOf('day'),
        reference: '',
      },

      isError: false,
      isSaving: false,
    };
  },
  props: {
    invoice: { type: Object },
    onMarkPaid: { type: Function },
  },

  methods: {
    confirm() {
      const { model } = this;
      model.invoice = this.invoice;
      model.contact = this.invoice.contact;

      //Toggle flags
      this.isSaving = true;
      this.isError = false;

      //Call handler
      this
        .onMarkPaid(model)
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isSaving = false);
    },
  },
};
</script>
