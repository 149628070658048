
export default function(obj, type = 'title') {
  const str = String(obj);

  switch (type) {
    case 'title':
    default:
      return str.substring(0, 1).toUpperCase() + str.substring(1);
  }
}
