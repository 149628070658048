import UserApi from '@/api/user.api';
import AccountApi from '@/api/account.api';
import { SET_USER, SET_ACCOUNT } from './types';

/**
 * Load session user
 */
export const loadUser = async ({ commit }) => {
  //Get user
  const user = await UserApi.me();
  if (window.analytics) {
    window.analytics.identify(user.id, {
      name: user.name,
      email: user.email,
      role: user.role,
    });
  }

  //Commit to store
  commit(SET_USER, user);
};

/**
 * Unload session user
 */
export const unloadUser = async ({ commit }) => {
  //Clear from store
  commit(SET_USER, null);
};

/**
 * Load session account
 */
export const loadAccount = async ({ commit }) => {
  const account = await AccountApi.own();
  commit(SET_ACCOUNT, account);
};

/**
 * Unload session account
 */
export const unloadAccount = async ({ commit }) => {
  //Clear from store
  commit(SET_ACCOUNT, null);
};

export const setFrillWidgetUnreadCount = async ({ commit }, count) => {
  commit('setFrillWidgetUnreadCount', count);
};

export const setDropMenuOpen = async ({ commit }, open) => {
  commit('setDropMenuOpen', open);
};

export const setFromLink = async ({ commit }, fromLink) => {
  commit('setFromLink', fromLink);
};

export const clearFromLink = async ({ commit }) => {
  commit('clearFromLink');
};