import http from '@/services/http';
import Contact from '@/models/contact.model';

export default {
  query(filter) {
    return http
      .get('/contact', filter)
      .then(data => Contact.createFrom(data, null, 'contacts'));
  },

  list(filter) {
    return http
      .get('/contact/list', filter)
      .then(contacts => Contact.createFrom(contacts));
  },

  summary(filter) {
    return http
      .get('/contact/summary', filter);
  },

  findById(id) {
    return http
      .get(`/contact/${id}`)
      .then(data => Contact.createFrom(data));
  },

  create(data) {
    return http
      .post('/contact', data);
  },

  update(id, data) {
    return http
      .put(`/contact/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/contact/${id}`);
  },

  archive(id) {
    return http
      .put(`/contact/${id}/archive`);
  }
};
