<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderSettings',
  computed: {
    ...mapState({
      accountName: state => state.session?.account?.name,
    }),
  },
};
</script>

<template>
  <div class="ContentWrap">
    <div class="HeaderBar">
      <div class="SectionTitleAndTabs Container">
        <menu class="PageHeaderTabs">
          <div class="AdminMenu-Spacer"></div>
          <router-link 
            class="PageHeader-tab--first" 
            to="/settings/overview"
            :class="{
              'PageHeader-tab': true,
              'router-link-active': $route.path.match(/settings/),
            }"
          >
            <i class="Icon-Header">settings</i>
            {{ $t("global.settings") }}
          </router-link>
          <router-link class="PageHeader-tab" to="/account">
            <i class="Icon-Header">account_balance_wallet</i>
            {{$t('global.account')}}
          </router-link>
          <router-link class="PageHeader-tab" to="/profile">
            <i class="Icon-Header">face</i>
            {{$t('global.my_profile')}}
          </router-link>
        </menu>
        <div class="AccountMeta--wrap">
          <div class="AccountTitle">{{ accountName }}</div>
        </div>
      </div>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
.AdminMenu-Spacer {
  width: 220px;
}
</style>