<script>
import TypeAhead from '@/components/shared/type-ahead';

export default {
  inheritAttrs: false,

  components: { TypeAhead },

  props: {
    api: { type: Object },
    model: {
      type: [Object, String],
      default: null,
    },
    asObject: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    minLength: {
      type: Number,
      default: 2,
    },
    fields: {
      type: String,
      default: 'name',
    },
    newable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    searchHandler(name) {
      const { fields } = this;
      return this.api.list({ name, fields });
    },
  },
};
</script>

<template>
  <type-ahead
    v-bind="$attrs"
    label-by="name"
    track-by="id"
    bind-key="id"
    :isDisabled="disabled"
    :disabledLabel="disabledLabel"
    :min-length="minLength"
    :debounce="350"
    :placeholder="placeholder"
    :as-object="asObject"
    :newable="newable"
    :model="model"
    :search-handler="searchHandler"
    @change="$emit('change', $event)"
  ></type-ahead>
</template>
