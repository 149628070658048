import http from '@/services/http';
import Invoice from '@/models/invoice.model';

export default {
  query(filter) {
    return http
      .get('/invoice', filter)
      .then(data => Invoice.createFrom(data, null, 'invoices'));
  },

  list(filter) {
    return http
      .get('/invoice/list', filter)
      .then(invoices => Invoice.createFrom(invoices));
  },

  summary(filter) {
    return http
      .get('/invoice/summary', filter);
  },

  findById(id) {
    return http
      .get(`/invoice/${id}`)
      .then(data => Invoice.createFrom(data));
  },

  create(data) {
    return http
      .post('/invoice', data);
  },

  update(id, data) {
    return http
      .put(`/invoice/${id}`, data);
  },

  remove(id) {
    return http
      .delete(`/invoice/${id}`);
  },

  findByIdForPayment(id, accessToken) {
    return http
      .get(`/invoice/${id}/pay`, { accessToken })
      .then(data => Invoice.createFrom(data));
  },

  send(id) {
    return http
      .get(`/invoice/${id}/send`);
  },

  markPaid(id, data) {
    data.status = 'paid';
    return http
      .put(`/invoice/${id}/markPaid`, data)
      .then(data => Invoice.createFrom(data));
  },

  pay(id, method, data) {
    return http
      .post(`/payment/${method}/invoice/${id}`, data);
  },
};
