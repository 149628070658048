/**
 * Define values
 */
export const DRAFT_INVOICE = 'draftInvoice';
export const SEND_INVOICE = 'sendInvoice';
export const PAY_AND_SEND_INVOICE = 'payAndSendInvoice';

/**
 * Define array
 */
export default [
  {
    value: DRAFT_INVOICE,
    label: `Create draft invoice only`,
  },
  {
    value: SEND_INVOICE,
    label: `Send invoice automatically`,
  },
  // {
  //   value: PAY_AND_SEND_INVOICE,
  //   label: `Take payment and send invoice automatically`,
  // },
];
