<script>
import { mapState } from 'vuex';
import DashboardCol1 from './dashboard-col-1';
import DashboardCol2 from './dashboard-col-2';
import DashboardCol3 from './dashboard-col-3';
import ModalConfirm from '@/components/shared/modal-confirm';
import SubscriptionStatus from '../shared/subscription-status';

export default {
  components: { DashboardCol1, DashboardCol2, DashboardCol3, SubscriptionStatus },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),
  },

  methods: {
    async close(panel) {
      const onConfirm = async () => {
        if (panel === 'main') {
          this.user.experiences.onboarding_panel = false;
        }
        else if (panel === 'stripe') {
          this.user.experiences.onboarding_panel_stripe = false;
        }
        await this.user.updateOwn(this.user);
      };

      this.$modal.show(
        ModalConfirm,
        {
          // *** IMPORTANT *** This uses same text from shared_overview-onboarding.hide_guide_title
          _modalTitle: this.$t('shared_overview-onboarding.hide_guide_title'),
          _modalMessage: this.$t('shared_overview-onboarding.hide_guide_message'),
          _buttonText: this.$t('shared_overview-onboarding.hide_guide_button'),
          onConfirm,
        }
      );
    },
  },
};
</script>

<template>
  <div class="Page Dash Container Container--centered">
    <div class="DashWrap">
      <div class="DashMain">
        <div v-if="user && user.role === 'Admin' && (user.onboardingPanels.main || user.onboardingPanels.stripe)" class="Onboarding-Wrap">
          <div v-if="user.onboardingPanels.main" class="Dash--col--onboarding" >
            <div class="Onboarding-Getting-Started ">
              <div @click="close('main')" class="Click-hide--Onboarding"><i class="Icon-Hide">close</i></div>

              <div class="Welcome--panel">
                <div class="Welcome-Message">
                  <span v-if="user.stepsDone.clients && user.stepsDone.assets && user.stepsDone.clients">
                    {{ $t('onboarding_dashboard.welcome_message_activated') }}
                  </span>
                  <span v-else>{{ $t('onboarding_dashboard.welcome_message') }}</span>
                </div>
                <div class="Welcome--firstSentence">
                <span v-if="user.stepsDone.clients || user.stepsDone.assets || user.stepsDone.clients">
                  {{ $t('onboarding_dashboard.first_sentence_activated') }}
                </span>
                <span v-else>{{ $t('onboarding_dashboard.first_sentence') }}</span>
                  <div class="Helplink--inline">
                    <div class="Onboarding-Spacer">|</div>
                    <a href="https://guide.controlla.io/article/78-controlla-overview" target="_blank">
                      <span>{{$t('onboarding_dashboard.need_overview')}}</span> <div class="Helpspot">?</div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="OnboardingItemsWrap">

                <div :class="user.stepsDone.clients ? 'OnboardingItem--completed' : 'OnboardingItem'">
                  <div class="OnboardingItem--content">
                      <router-link to="/directory/clients" class="Button">
                        <div class="Onboarding-CheckIcons">
                          <div class="Onboarding-Icon-Wrap"><i class="Icon-OnboardTodo">people</i></div>
                          <div class="Onboarding-Icon-Wrap"><i class="Icon-OnboardDone">thumb_up</i></div>
                        </div>
                      <span v-if="!user.stepsDone.clients">{{ $t('onboarding_dashboard.step1_todo') }}</span>
                      <span v-else>{{ $t('onboarding_dashboard.step1_completed') }}</span>
                      </router-link>
                  </div>
                </div>

                <div :class="user.stepsDone.assets ? 'OnboardingItem--completed' : 'OnboardingItem'">
                  <div class="OnboardingItem--content">
                    <router-link to="/workspace/assets" class="Button">
                      <div class="Onboarding-CheckIcons">
                        <div class="Onboarding-Icon-Wrap"><i class="Icon-OnboardTodo">widgets</i></div>
                        <div class="Onboarding-Icon-Wrap"><i class="Icon-OnboardDone">thumb_up</i></div>
                      </div>
                      <span v-if="!user.stepsDone.assets">{{ $t('onboarding_dashboard.step2_todo') }}</span>
                      <span v-else>{{ $t('onboarding_dashboard.step2_completed') }}</span>
                    </router-link>
                  </div>
                </div>

                <div :class="user.stepsDone.offerings ? 'OnboardingItem--completed' : 'OnboardingItem'">
                  <div class="OnboardingItem--content">
                      <router-link to="/workspace/offerings" class="Button">
                        <div class="Onboarding-CheckIcons">
                          <div class="Onboarding-Icon-Wrap"><i class="Icon-OnboardTodo">sell</i></div>
                          <div class="Onboarding-Icon-Wrap"><i class="Icon-OnboardDone">thumb_up</i></div>
                        </div>
                      <span v-if="!user.stepsDone.offerings">{{ $t('onboarding_dashboard.step3_todo') }}</span>
                      <span v-else>{{ $t('onboarding_dashboard.step3_completed') }}</span>
                      </router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div v-if="(user.onboardingPanels.stripe) && (user.stepsDone.offerings && user.stepsDone.clients)" class="Onboarding-Stripe">
            <div @click="close('stripe')" class="Click-hide--Onboarding"><i class="Icon-Hide">close</i></div>
            <div class="Section-Onboarding--Content--stripe">
              <div class="Onboarding--StripeWrap" v-if="!user.experiences.stripe_connected">
                <router-link class="Button Button--stripe" to="/integrations/stripe">
                  <span>{{$t('onboarding_dashboard.connect_stripe')}}</span> 
                  <img src="@/assets/images/integrations/stripe-dark.svg" alt="Stripe" class="Onboarding--StripeImg" />
                </router-link>
                <div class="Onboarding--StripeText">
                  <span>{{ $t('onboarding_dashboard.connect_stripe_reason') }}</span>
                  <div class="Helplink">
                    <div class="Onboarding-Spacer">|</div>
                    <a href="https://guide.controlla.io/article/22-connect-stripe-account" target="_blank">
                      <span>{{$t('onboarding_dashboard.about_stripe')}}</span> <div class="Helpspot">?</div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="Onboarding--StripeWrap" v-else>
                <div class="Button Button--stripeEnabled">
                  <div class="Onboarding-Icon-Wrap"><i class="Icon-OnboardDone Icon-Stripe-fix">thumb_up</i></div>
                  <img src="@/assets/images/integrations/stripe-text.svg" alt="Stripe" class="Onboarding--StripeImg-Enabled" />
                </div>
                <div class="Onboarding--StripeText">
                  <span>{{ $t('onboarding_dashboard.connected_stripe_state') }}</span>
                  <div class="Helplink">
                    <div class="Onboarding-Spacer">|</div>
                    <a href="https://guide.controlla.io/article/22-connect-stripe-account" target="_blank">
                      <span>{{$t('onboarding_dashboard.about_stripe')}}</span> <div class="Helpspot">?</div>
                    </a>
                  </div>
                </div>
                <div class="Stripe-Spacer"></div>
              </div>
            </div>
          </div>
        </div>
      <div class="PanelsWrap">
        <DashboardCol1 />
        <DashboardCol2 />
      </div>
      </div>
      <div class="DashExtra">
        <subscription-status />
        <DashboardCol3 />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.DashWrap {
  display: grid;
  grid-template-columns: 2.25fr 1fr;
  align-items: start;
  gap: $gapL;
}

.DashMain {  
  @include app-panel;
  @include shadows-subtle;
  padding: $spacingL;
  margin-bottom: 0;
  border-radius: $borderRadius2XL;
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: min-content;
  gap: $spacingL;
}

.Dash--col {
  flex: 1;
  padding: 0;
  display: grid;
  gap: $gapXL;
}

.Dash--col-1 {
  margin: 0
}

.Dash--col-2 {
  margin: 0;
}

.PanelsWrap {
  display: flex;
  align-items: flex-start;
  flex: 1;
  gap: $spacingL;
}
</style>
