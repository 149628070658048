<script>
import { mapState } from 'vuex';
export default {
  name: 'DashboardPanel',

  props: {
    panel_link: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
    stats: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    addNewable: {
      type: Boolean,
      default: true,
    },
    experience: {
      type: String,
    },
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),
  },
};
</script>

<template>
  <div class="Panel">
    <div class="PanelHeader--Stats">
      <div class="PanelHeader-title" :class="{'add-new': addNewable}">
        <router-link v-if="panel_link" :to="panel_link" :title="'View '+title">
          <i class="Icon-Base">{{icon}}</i> <span>{{title}}</span>
        </router-link>
        <span v-else>{{title}}</span>
      </div>
      <div class="PanelHeader-options">
        <slot name="options" />
      </div>
    </div>

    <div class="PanelStats">
      <spinner v-if="isLoading" />
        <router-link v-for="(stat, index) in stats" :key="index" v-else :to="stat.query" v-bind:class="{ disabled: !clickable }" class="PanelStat">
          <div class="PanelStat-value">
              <i v-if="!stat.status" class="Icon-Base--blank">circle</i>
              <i v-else-if="stat.status==='active'" class="Icon-Base--okFilled">play_arrow</i>
              <i v-else-if="stat.status==='scheduled'" class="Icon-Base--ok">schedule</i>
              <i v-else-if="stat.status==='finished'" class="Icon-Base--danger">error_outline</i>
              <i v-else-if="stat.status==='normal'" class="Icon-Base--info">notifications</i>
              <i v-else-if="stat.status==='missing'" class="Icon-Base--danger">error_outline</i>
              <i v-else :class="'Icon-Base--' + stat.status">info</i>
              <div v-if="stat.value > 0" >{{stat.value}}</div>
              <div v-else-if="stat.value === 0" class="StatEmpty">...</div>

            </div>
            <div class="PanelStat-label">
              <i v-if="!stat.query">{{stat.label}}</i>
              <div class="Label-Text">{{stat.label}}</div>
            </div>
        </router-link>
      </div>
    </div>
</template>

<style lang="scss">
</style>
