import Vue from 'vue';
import moment from 'moment-timezone';

/**
 * Create mixin to load the authentication service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.filter) {
      this.$filter = options.filter;
    }
    else if (options.parent && options.parent.$filter) {
      this.$filter = options.parent.$filter;
    }
  },
});

class Filter {
  constructor(type) {
    //Define non enumerable private properties
    Object.defineProperty(this, '_defaults', {
      writable: true,
    });
    Object.defineProperty(this, '_hasDefaults', {
      writable: true,
    });
    Object.defineProperty(this, '_type', {
      writable: true,
    });
    Object.defineProperty(this, '_changeHandlers', {
      writable: true,
    });

    //Save type and initialize on change handlers
    this._type = type;
    this._changeHandlers = [];
  }

  /**************************************************************************
   * Defaults handling
   ***/

  hasDefaults() {
    return this._hasDefaults;
  }

  getDefaults() {
    return this._defaults;
  }

  /**
   * Set multiple default values
   */
  setDefaults(values, overwrite = false) {
    //Don't overwrite if already set
    if (this._defaults && !overwrite) {
      return;
    }

    //Initialize and set default values
    this._defaults = this._defaults || {};
    Object.assign(this._defaults, values);

    //Reset now
    this.resetToDefaults();
  }

  /**
   * Change or set a default value
   */
  setDefault(key, value) {
    this._defaults[key] = value;
  }

  /**
   * Ensure a key is present in default values, if not, add it with null value
   */
  ensureInDefaults(key) {
    if (typeof this._defaults[key] === 'undefined') {
      this.setDefault(key, null);
    }
  }

  /**
   * Check if has all default values set
   */
  checkHasDefaults() {
    for (const key in this._defaults) {
      if (this._defaults.hasOwnProperty(key)) {
        if (moment.isMoment(this[key])) {
          if (!this[key].isSame(this._defaults[key])) {
            return (this._hasDefaults = false);
          }
        }
        else if (this._defaults[key] !== this[key]) {
          return (this._hasDefaults = false);
        }
      }
    }
    return (this._hasDefaults = true);
  }

  resetToDefaults(silent) {
    for (const key in this._defaults) {
      if (this._defaults.hasOwnProperty(key)) {
        const value = this._defaults[key];
        if (moment.isMoment(value)) {
          this[key] = value.clone();
        }
        else {
          this[key] = value;
        }
      }
    }
    this.checkHasDefaults();
    this.triggerChange(null, null, silent);
  }

  /**
   * Load initial values based on existing keys
   */
  loadValues(data) {
    const keys = Object.keys(this);
    for (const key of keys) {
      if (typeof data[key] !== 'undefined') {
        let value = data[key];

        switch (value) {
          case 'true': value = true; break;
          case 'false': value = false; break;
          case 'null': value = null; break;
        }
        this.update(key, value);
      }
    }
  }

  /**************************************************************************
   * Change handling
   ***/

  update(key, value, silent, forceChange = false) {
    const isChanged = (this[key] !== value);
    this[key] = value;
    if (isChanged || forceChange) {
      this.wasUpdated(key, silent);
    }
  }

  /**
   * Indicate an update happened (could be external)
   */
  wasUpdated(key, silent) {
    const value = this[key];
    this.ensureInDefaults(key);
    this.checkHasDefaults();
    this.triggerChange(key, value, silent);
  }

  /**
   * Trigger change handlers
   */
  triggerChange(key, value, silent) {
    if (silent) {
      return;
    }
    for (const handler of this._changeHandlers) {
      handler(key, value);
    }
  }

  /**
   * Add change handler
   */
  onChange(handler) {
    if (this._changeHandlers.indexOf(handler) === -1) {
      this._changeHandlers.push(handler);
    }
  }

  /**
   * Remove change handler (last one if none given)
   */
  offChange(handler) {
    if (handler) {
      const i = this._changeHandlers.indexOf(handler);
      if (i > -1) {
        this._changeHandlers.splice(i, 1);
      }
    }
    else if (this._changeHandlers.length > 0) {
      this._changeHandlers.pop();
    }
  }

  /**
   * To JSON converter
   */
  toJSON(data) {

    //Initialize json
    const json = (data && typeof data === 'object') ? data : {};

    //Process properties
    for (const key in this) {

      //Check type
      const isUndefined = (typeof this[key] === 'undefined');
      const isFunction = (typeof this[key] === 'function');
      const isObject = (typeof this[key] === 'object');

      //Ignore if invalid
      if (!this.hasOwnProperty(key) || isFunction || isUndefined) {
        continue;
      }

      //Ignore null values
      if (this[key] === null) {
        continue;
      }

      //Process
      if (isObject && typeof this[key].toJSON === 'function') {
        json[key] = this[key].toJSON();
      }
      else {
        json[key] = this[key];
      }
    }
    return json;
  }
}

class FilterService {
  /**
   * Get filter of a certain type
   */
  get(type) {
    if (!this[type]) {
      this[type] = new Filter(type);
    }
    return this[type];
  }
}

/**
 * Export singleton instance
 */
export default new FilterService();
