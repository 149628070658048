<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/shared/modal-edit';
import recurrenceIntervals from '../../../constants/recurrence-intervals';
import CurrencyInput from '../../shared/currency-input.vue';

export default {
  components: { ModalEdit, CurrencyInput },

  data() {
    return {
      model: {
        name: null,
        invoicePrices: {
          months: null,
          years: null,
        },
        description: null,
        assetTypes: [],
      },
      original: {},
      newPriceOption: {
        value: null,
        price: null,
      },
      showPriceOption: true,
      recurrenceIntervals,
    };
  },

  props: {
    item: { type: Object },
    onSave: { type: Function },
    onRemove: { type: Function },
    isEdit: { type: Boolean },
    assetTypes: { type: Array },
  },

  validations: {
    model: {
      name: { required },
      invoicePrices: {
        atLeastOnePrice: (value) => {
          return Object.values(value).some(price => price !== null);
        }
      }
    },
  },

  computed: {
    isDisabledConfirm() {
      return _.isEqual(this.model, this.original);
    },

    unusedRecurrenceIntervals() {
      return recurrenceIntervals.filter(interval => this.model.invoicePrices[interval.value] === null);
    },

    usedRecurrenceIntervals() {
      return recurrenceIntervals.filter(interval => this.model.invoicePrices[interval.value] !== null);
    },

    checkedAssetTypes: {
      get() {
        return this.model.assetTypes.map(a => a._id);
      },
      set(value) {
        this.model.assetTypes = this.assetTypes.filter(a => value.includes(a._id));
      }
    }
  },

  async created() {
    //Create model subset
    this.model = this.item.extract(Object.keys(this.model));
    this.original = _.cloneDeep(this.model);

    // MHJB: needed otherwise first setting of assetTypes doesn't work. This should ideally
    // be implemented in base.model.js
    if (!this.model.assetTypes) {
      this.model.assetTypes = [];
    }
    // MHJB: I've not yet understood why I need to do this here; is base.model:extract not working?
    if (!this.model.invoicePrices) {
      this.model.invoicePrices = {
        months: null,
        years: null,
      };
    }
  },

  methods: {
    addPriceOption() {
      this.showPriceOption = true;
      this.newPriceOption = {};
    },

    addPrice() {
      if(this.newPriceOption?.value && this.newPriceOption?.price) {
        this.model.invoicePrices[this.newPriceOption.value] = this.newPriceOption.price;
        this.showPriceOption = false;
        this.newPriceOption = {};
      }
    },

    removePrice(interval) {
      if(interval?.value) {
        this.model.invoicePrices[interval.value] = null;
      }
      else {
        this.showPriceOption = true;
        this.newPriceOption = {};
      }
    },
  },
};
</script>

<template>
  <modal-edit
    singular="offering"
    :model="model"
    :validation="$v"
    :is-edit="isEdit"
    :on-save="onSave"
    :is-disabled-confirm="isDisabledConfirm"
    @close="$emit('close')"
  >
    <div class="Form">

      <div class="Modal-Splits">
        <div class="Split">
        <div class="Row-0">
          <div class="Modal-col--M">
            <div class="Modal--item" :class="{ 'Group--error': $v.model.name.$error }">
              <label :for="$htmlID('name')" class="Label">{{ $t('offerings_modals_edit.offering_name') }}</label>
              <input :id="$htmlID('name')" class="Input-100" type="text" v-model="model.name"
                :placeholder="$t('offerings_modals_edit.offering_name_example')">
              <div class="InputHint" v-if="$v.model.name.$error">
                <div v-if="!$v.model.name.required">{{ $t('offerings_modals_edit.offering_name_required') }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="Row-0">
          <div class="Modal-col--M">
            <div class="Modal--item">
              <label :for="$htmlID('description')" class="Label">{{ $t('offerings_modals_edit.description') }}</label>
              <textarea :id="$htmlID('description')" class="Input-100" v-model="model.description" />
            </div>
          </div>
        </div>

        <div class="Row-0">
          <div class="Modal-col--M">
            <div class="Modal--item">
              <div class="Row-0">
                <div class="Modal-col--M">
                  <div class="Modal--item">
                    <label class="Label">{{ $t('offerings_modals_edit.price_timeframes') }}</label>
                    <div class="OfferingEdit-PricesWrap">
                      <div v-for="interval in usedRecurrenceIntervals" class="OfferingEdit-PriceRow">
                        <div class="OfferingEdit-Interval">{{ interval.label }}</div>
                        {{ model.invoicePrices[interval.value] | currency }}
                        <div class="AdminIcons-Wrap">
                          <i class="Icon-Base" @click="removePrice(interval)" :title="$t('offerings_modals_edit.remove_price_option')">delete</i>
                        </div>
                      </div>
                      <div class="SpacerS"></div>
                      <div v-if="showPriceOption && unusedRecurrenceIntervals.length > 0" class="OfferingEdit-FormRow">
                        <div class="InputWrapper">
                          <select-box
                            :model="newPriceOption.value"
                            label-by="label"
                            track-by="value"
                            :options="unusedRecurrenceIntervals"
                            @change="$set(newPriceOption, 'value', $event.value)"
                            />
                        </div>
                        <currency-input
                          placeholder="$ –"
                          class="Input-S"
                          v-model="newPriceOption.price"
                        />
                        <!-- should be conditional on both newPriceOption.value & price set -->
                        <div
                        :class="{ 'your-active-class': newPriceOption.value && newPriceOption.price }"
                          class="AdminIcons-Wrap AdminIcons-Wrap--active"
                        >
                          <i v-if="newPriceOption.value && newPriceOption.price" class="Icon-Base--valid">check</i>
                          <button
                            @click="addPrice()"
                            class="Button--small"
                            :disabled="!(newPriceOption.value && newPriceOption.price)"
                          >
                            {{ $t('global.add') }}
                          </button>
                        </div>
                      </div>
                      <div class="SpacerL"></div>
                      <button
                        v-if="unusedRecurrenceIntervals.length !== 0"
                        @click="addPriceOption"
                        class="Button--tiny"
                        :disabled="unusedRecurrenceIntervals.length === 0 || showPriceOption"
                      >
                        {{$t('offerings_modals_edit.add_price_option')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="InputHint" v-if="$v.model.invoicePrices.$error">
                {{ $t('offerings_modals_edit.timeframe_required') }}
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="Split">
        <div class="Row-0">
          <label class="Label Label-OfferingEdit-AssetCheckbox">{{ $t('offerings_modals_edit.select_asset_types') }}</label>

          <div class="Modal-col--M">

              <div v-if="!assetTypes.length" class="Message-Warning">
                <div class="Message-Header">
                  <i class="Icon-Base--warning">info</i>
                  <div class="Message-Header--textWrap">
                    <div class="Message-Title">{{ $t('offerings_modals_edit.no_asset_types') }}</div><span> – </span>
                    <router-link to="/workspace/assets/assetTypes">
                      {{ $t('offerings_modals_edit.add_asset_types') }}
                    </router-link>
                  </div>
                </div>
                <div class="SubMessage">{{ $t('offerings_modals_edit.save_without_asset_types') }}</div>
              </div>
              <div v-if="assetTypes.length" class="Row-M">
                <div class="Modal--item OfferingEdit-AssetsWrap">
                  <!-- <div class="Toggles-Wrap">
                    <div class="Toggle">Require Service<i class="Icon-Toggle--on">toggle_on</i> <i class="Icon-Toggle--off">toggle_off</i>
                      <div class="Description">All selected asset types require this offering</div>
                    </div>
                    <div class="Toggle">Allow exceptions <i class="Icon-Toggle--on">toggle_on</i> <i class="Icon-Toggle--off">toggle_off</i></div>
                    <div class="Helplink"><a href="javascript:void(0)" onclick="Beacon('article', '70')"><span>more info</span><div class="Helpspot">?</div></a></div>
                  </div> -->
                    <div v-for="assetType in [...assetTypes].sort((a, b) => a.name.localeCompare(b.name))" :key="assetType._id" class="OfferingEdit-AssetCheckbox">
                    <input
                      :id="$htmlID(assetType._id)"
                      v-model="checkedAssetTypes"
                      type="checkbox"
                      class="Input-Checkbox OfferingsCheckBox"
                      :value="assetType._id"
                    />
                    <label :for="$htmlID(assetType._id)" class="OfferingEdit-Asset">
                      <!-- <icon :name="assetType.icon" class="Icon-Checklist" /> -->
                      {{ assetType.name }}
                    </label>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    </div>
  </modal-edit>
</template>

<style lang="scss" scoped>

.Modal-Splits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacingXL;
  .Split {
  }
}
.OfferingEdit-PricesWrap {

  .OfferingEdit-PriceRow {
    padding: $spacingL $spacingM;
    display: flex;
    gap: $spacingL;
    align-items: center;
    width: 100%;
    align-content: center;
    border-bottom: 1px solid $colorBordersLight;
  }
  .OfferingEdit-FormRow {
    display: flex;
    align-items: center;
    gap: $spacingM;
    margin-top: $spacingXS;
    .Input-100,
    .Input-XS {
      padding-top: $spacingS  !important;
      padding-bottom: $spacingS  !important;
    }
  }
  .OfferingEdit-Interval {
      min-width: 120px;
    }
    .AdminIcons-Wrap {
      display: flex;
      gap: $spacingM;
      margin-left: auto;
      align-items: center;
      .Icon-Base {
        cursor: pointer;
      }
    }
}

.OfferingEdit-AssetsWrap {
  @include app-panel;
  background: $colorBgInfo;
  padding: $spacingL;
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacingM;
  margin-bottom: $spacingM;
  font-size: $fontSizeS;
  width: 100%;
}

.OfferingEdit-AssetCheckbox {
  display: flex;
  flex: 1;
  flex-basis: 50%;
}

.OfferingEdit-Asset {
  margin-left: $spacingXS;
  display: flex;
  align-items: center;

  .Icon-Checklist {
    display: flex;
    align-items: center;
    margin-right: $spacingXS;
    color: $colorTextFaded1;
  }
}
</style>