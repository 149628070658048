import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/**
 * Custom store modules
 */
import page from './modules/page';
import session from './modules/session';

/**
 * Export Vuex store instance
 */
export default new Vuex.Store({
  modules: {
    page,
    session,
  },
});
