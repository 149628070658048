<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import ModalView from '@/components/shared/modal-view';
import assetDisplayName from '@/helpers/asset-display-name';
import calcInclAmount from '@/helpers/calc-incl-amount';
import InvoiceList from '@/components/shared/invoice-list';
import ContactApi from '@/api/contact.api';

export default {
  components: { ModalView, InvoiceList },

  data() {
    return {
      assetDisplayName,
    };
  },

  props: {
    contract: { type: Object },
    onEdit: { type: Function },
    onArchive: { type: Function },
  },

  computed: {
    ...mapState({
      taxPercentage: state => state.session?.account?.taxPercentage,
    }),

    byline() {
      let byline = `${this.$t('global.service')}`;
      if (this.contract.items[0]?.asset?.type?.name) {
        byline += ` - ${this.contract.items[0].asset.type.name}`;
      }
      return byline;
    },

    nextCycleCountdown: function() {
      const nextDate = moment(this.contract.nextInvoiceDate);
      const nextDatePlusOne = nextDate.add(1, 'day');
      const now = moment();
      const diff = nextDatePlusOne.diff(now, 'days');
      return diff === 0 ? 'Today' :
        diff === 1 ? 'Tomorrow' :
          'in ' + diff + ' days';
    },

    nextCycleDate: function() {
      const nextCycleDate = moment(this.contract.nextInvoiceDate);
      return nextCycleDate;
    },

    singularInterval: function() {
      return this.$root.$options.filters.inflect(this.contract.recurrence.interval, 'singularize');
    },

    totalValueInclTax: function() {
      return calcInclAmount(this.contract.totalValue, this.taxPercentage);
    },
  },

  async created() {
    // Populate contact so that we can see if a credit card is enabled
    this.contract.contact = await ContactApi.findById(this.contract.contact.id);
  }
};
</script>

<template>
  <modal-view :item="contract" itemName="contract" :singular="contract.name" :byline="byline"
    :on-edit="contract.archived ? null : onEdit" :on-archive="contract.archived ? null : onArchive" :saveable="false"
    @close="$emit('close')">
    <div v-if="contract.archived" class="Message-Danger">
      <div class="Message-Header">
        <i class="Icon-Base--danger">archive</i>
        <span>{{$t('contracts_modals_view.contract_archived')}}</span>
      </div>
    </div>
    <div class="SpacerXL" />
    <div class="Section">
      <div class="Row-M">
        <div class="Modal-col--M">
          <div class="Modal--item">
            <label class="Label">{{$t('global.client')}}</label>
            <div class="Modal-Value">
              <modal-link :to="`/directory/clients/view/${contract.contact.id}`"
                :from="`/workspace/services/view/${contract.id}`" fromName="service">
                {{contract.contact.name}}
              </modal-link>
            </div>
          </div>

          <div class="Modal--item">
            <label class="Label">{{$t('contracts_modals_view.status')}}</label>
            <div class="Modal--status">
              <span v-if="contract.recurrence.ended" class="Modal--Status-danger">
                <i class="Icon-Base">stop</i>
                <span>{{$t('contracts_modals_view.finished')}}</span>
              </span>
              <span v-else-if="contract.recurrence.scheduled" class="Modal--Status-ok">
                <i class="Icon-Base">schedule</i>
                <span>{{$t('contracts_modals_view.scheduled')}}</span>
              </span>
              <span v-else-if="contract.recurrence.ends==='never'" class="Modal--Status-ok">
                <span>{{$t('contracts_modals_view.active')}}</span>
              </span>
              <span v-else-if="contract.recurrence.ends!=='never'" class="Modal--Status-ok">
                <span>{{$t('contracts_modals_view.active')}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="Modal-col--S">
          <div class="Modal--item" v-if="contract.recurrence">
            <div class="Label">{{$t('contracts_modals_view.schedule')}}</div>
            <div class="Modal-Value Modal-Value-Interval">
              {{singularInterval}}ly
            </div>
          </div>
          <div class="Modal--item">
            <label class="Label">{{$t('contracts_modals_view.starts')}}</label>
            <div class="Modal-Value">{{contract.recurrence.startDate | moment('ll')}}</div>
          </div>
          <div class="Modal--item-last">
            <div class="Label">{{ contract.recurrence.ended ? $t('contracts_modals_view.finished') :
              $t('contracts_modals_view.ends')}}
            </div>
            <div class="Modal-Value">
              <span v-if="contract.recurrence.ended">
                {{ contract.recurrence.endDate | moment('ll')}}
              </span>
              <span v-else-if="contract.recurrence.numCycles">
                {{$t('contracts_modals_view.after')}} {{contract.recurrence.numCycles}}
                {{$t('contracts_modals_view.cycles')}}
              </span>
              <span v-else-if="contract.recurrence.ends==='endDate'">
                {{contract.recurrence.endDate | moment('ll')}}
              </span>
              <span v-else class="Modal-Value--notSet">
                {{$t('contracts_modals_view.never')}}
              </span>
            </div>
          </div>
        </div>
        <div class="Modal-col--M">
          <div class="Modal--item-leftBorder">
            <label class="Label">{{$t('contracts_modals_view.next_cycle')}}</label>
            <div v-if="contract.recurrence.ended==1" class="Contract-Ended--message">—</div>
            <div v-else>
              <div class="Modal-Value Modal-Value-NextCyle">
                {{nextCycleCountdown}}
              </div>
              <div class="Modal-Value Modal-Value-NextCyleInfo">
                {{nextCycleDate | moment('ll')}}
              </div>
              <div class="SpacerL" />
              <div class="Modal-Value Modal-Value-NextCyle">
                {{contract.totalValue | currency}} + {{$t('global.tax')}}
              </div>
              <div class="Modal-Value Modal-Value-NextCyleInfo">
                {{totalValueInclTax | currency}} {{$t('contracts_modals_view.inc_tax')}}
              </div>
            </div>
            <div v-if="contract.contact?.creditCard?.automaticPaymentEnabled" class="InputNotice">
              {{ $t('contracts_modals_edit.card_payments_enabled_auto') }}<i class="Icon-Base">smart_toy</i>
            </div>
            <div v-else-if="contract.contact?.creditCard" class="InputNotice">
              {{ $t('contracts_modals_edit.card_payments_enabled_manual') }}<i class="Icon-Base">ads_click</i>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="SpacerXL" />
    <div class="Section">
      <div class="Row-M">
        <div class="Modal-Panel--info Modal-Panel--service" >
          <div class="Service-col--asset">
            <!-- <label class="Label">{{ $t('contracts_modals_view.serviced_asset') }}</label> -->
            <div class="Modal-Value">
              <div v-if="contract.items[0].asset" class="ItemM">
                <div class="Item-Icon">
                  <icon :name="contract.items[0].asset.type.icon" class="Icon-Base" />
                  </div>
                <div class="Item-TextWrap">
                  <modal-link :to="`/workspace/assets/view/${contract.items[0].asset._id}`" class="Item-Headline">
                    {{ contract.items[0].asset.name }}
                  </modal-link>
                  <div class="Description">
                    {{ assetDisplayName(contract.items[0].asset)}}
                  </div>
                </div>
              </div>
              <span v-else class="Modal-Value--notSet">—</span>
            </div>
          </div>
          <div class="Service-col--service">
            <!-- <label class="Label">{{ $t('contracts_modals_view.linked_offering') }}</label> -->
            <div class="Modal-Value">
              <div v-if="contract.items[0].asset" class="ItemM">
                <div class="Item-TextWrap">
                  <modal-link :to="`/workspace/offerings/view/${contract.items[0].offering.id}`" class="Item-Headline">
                    {{ contract.items[0].offering.name }}
                  </modal-link>
                  <div class="Description">
                    {{ $t('contracts_modals_view.linked_offering') }}
                  </div>
                </div>
              </div>
              <span v-else class="Modal-Value--notSet">—</span>
            </div>
          </div>
          <div class="Service-col--service"></div>
        </div>

        <div class="Row-M">
          <div class="Modal-col--M">
            <div class="Modal-Panel--wrap">
              <div v-if="contract.items">
                <div class="Modal-Panel" v-for="(item, index) in contract.items" :key="index">
                  <label class="Label">{{$t('contracts_modals_view.invoice_description')}}</label>
                  <div class="Modal-Value--description">
                    {{item.description}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="Section">
      <div class="Modal-col--M">
        <invoiceList :query="{
            contract: this.contract.id,
            limit: 5,
            sort: '-number',
            status: 'sent',
          }" :modalFromLink="`/workspace/services/view/${contract.id}`" />
      </div>
    </div>
  </modal-view>
</template>
<style lang="scss">

.Modal-Panel--info.Modal-Panel--service {
  justify-content: flex-start;
  padding: $spacing2XL;
}

.Service-col--asset {
  padding-right: $spacingXL;
  border-right: 1px solid $colorBordersInfo;
}
.Service-col--service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: $spacingXL;
}

.Contract-Price {
  margin-left: auto;
}

.ContractItem-Asset {
  display: flex;
  align-items: center;
  margin: $spacingXS 0;
}
.item-list {
  list-style: none;
}
.TotalAmount-wrap {
  margin-left: auto;
}
</style>
