<script>
export default {
  name: 'SortComponent',

  props: {
    sort: { type: String },
    field: { type: String },
    defaultDirection: {
      type: String,
      default: '',
    },
  },

  computed: {
    isCurrentSortField() {
      if (!this.sort) {
        return false;
      }
      return this.sort.replace(/^-/, '') === this.field;
    },
    isDescending() {
      return this.isCurrentSortField && this.sort.match(/^-/);
    },
    direction() {

      //This field is not currently being sorted on, use the default direction
      if (!this.isCurrentSortField) {
        return this.defaultDirection;
      }

      //Check if currently descending
      return this.isDescending ? '' : '-';
    },
  },

  methods: {
    toggle() {

      //Prepare sort string
      const {field, direction} = this;
      const sort = `${direction}${field}`;

      //Emit event
      this.$emit('toggle', {sort});
    },
  },
};
</script>

<template>
  <span class="Sort" @click="toggle">
    <slot />
    <div :class="{ CurrentSort: isCurrentSortField}"  class="IconSort-Wrap" >
      <i class="Icon-Sort Sort-Hover-Active" :class="{ SortActive: isCurrentSortField && !isDescending}">north</i>
      <i class="Icon-Sort" :class="{ SortActive: isCurrentSortField && isDescending}">south</i>
      <i class="Icon-Swap" :class="{ SortActive: !isCurrentSortField}">swap_vert</i>
    </div>
  </span>
</template>

<style lang="scss">
.Sort {
  cursor: pointer;
  display: flex;
 .IconSort-Wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: $spacingXS;
    width: 20px;
    .Icon-Sort {
      display: none;
    }
    &.CurrentSort {
      .Icon-Sort.SortActive {
        color: $colorText;
        font-size: 15px;
        display: block;
        height: auto;
        font-weight: $fontWeightBold;
      }
    }
    .Icon-Swap {
      display: none;
    }
  }
}

.Sort:hover {
  .Icon-Swap {
    display: block;
    font-size: 18px;
    font-weight: $fontWeightNormal;
    color: $colorTextFaded3;
  }
  .CurrentSort .Icon-Swap {
    display: none;
  }
}
</style>
