'use strict';

const Decimal = require('decimal.js');

/**
 * Helper to calculate tax inclusive amount
 */
module.exports = function(exclAmount, taxPercentage) {

  //Zero tax
  if (taxPercentage === 0 || exclAmount === 0) {
    return 0;
  }

  //Calculate tax inclusive amount
  return new Decimal(exclAmount)
    .times(100 + taxPercentage)
    .dividedBy(100)
    .toDecimalPlaces(2)
    .toNumber();
};
