<script>
export default {
  name: 'FrillWidget',

  data: () => ({
    widget: undefined,
    config: undefined,
  }),

  props: {
    frill_key: String,
    selector: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.setupFrillWidget();
  },

  beforeUnmount() {
    if (this.widget) {
      this.widget.destroy();
      this.widget = undefined;
    }

    if (this.config) {
      window.Frill_Config = window.Frill_Config.filter((c) => c !== this.config);
    }
  },

  methods: {
    setupFrillWidget() {
      this.config = {
        key: this.frill_key,
        ssoToken: this.$store.getters['session/frillSSOToken'],
        callbacks: {
          onReady: frillWidget => {
            this.widget = frillWidget;
          },
          onBadgeCount: ({ count }) => {
            this.$store.dispatch('session/setFrillWidgetUnreadCount', count);
          },
        },
      };

      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(this.config);

      if ('Frill' in window) {
        this.widget = window.Frill.widget(this.config);
      }
    },
  },
};
</script>

<template>
  <a :class="'frill-container ' + selector"><slot /></a>
</template>

<style lang="scss">

.Frill_Badge {
  background-color: $colorNotifyLight !important;
  color: $colorText !important;
  position: relative !important;
  left: -8px  !important;
  top: -5px !important;
  margin-bottom: -12px !important;
  font-size: 12px !important;
  font-weight: $fontWeightNormal !important;
  height:  $spacingL !important;
  width:  $spacingL !important;
}

</style>
