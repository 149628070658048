/**
 * Define values
 */
export const FRESH = 'fresh';
export const DISCUSSING = 'discussing';
export const QUOTED = 'quoted';
export const WON = 'won';
export const ON_HOLD = 'onHold';
export const LOST = 'lost';

/**
 * Define array
 */
export default [
  {
    value: FRESH,
    label: `Fresh`,
  },
  {
    value: DISCUSSING,
    label: `Discussing`,
  },
  {
    value: QUOTED,
    label: `Quoted`,
  },
  {
    value: WON,
    label: `Won`,
  },
  {
    value: ON_HOLD,
    label: `On hold`,
  },
  {
    value: LOST,
    label: `Lost`,
  },
];
