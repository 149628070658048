/**
 * Dependencies
 */
import saleStages from '@/constants/sale-stages';

/**
 * Sale stage filter
 */
export default function(value) {

  //Find corresponding item
  const item = saleStages.find(item => item.value === value);

  //Not found
  if (!item) {
    return '';
  }

  //Return label
  return item.label;
}
