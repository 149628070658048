
/**
 * Event emitter base class
 */
export default class EventEmitter {

  /**
   * Constructor
   */
  constructor() {
    this.listeners = {};
  }

  /**
   * Register an event listener
   */
  on(event, listener) {
    if (typeof listener !== 'function') {
      throw new Error('Invalid listener provided, not a function');
    }
    if (!Array.isArray(this.listeners[event])) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(listener);
    return () => this.removeListener(event, listener);
  }

  /**
   * Remove an event listener
   */
  removeListener(event, listener) {
    if (!Array.isArray(this.listeners[event])) {
      return;
    }
    const i = this.listeners[event].indexOf(listener);
    if (i > -1) {
      this.listeners[event].splice(i, 1);
    }
  }

  /**
   * Emit event
   */
  emit(event, ...args) {
    if (!Array.isArray(this.listeners[event])) {
      return;
    }
    for (const listener of this.listeners[event]) {
      listener.apply(this, ...args);
    }
  }

  /**
   * Listen for an event only once
   */
  once(event, listener) {
    const remove = this.on(event, (...args) => {
      remove();
      listener.apply(this, ...args);
    });
  }
}
