import BaseModel from './base.model';
import NotificationApi from '@/api/notification.api';

export default class Notification extends BaseModel {

  getModelName() {
    return 'Notification';
  }

  /**
   * Mark notification as read or unread
   */
  markRead(isRead = true) {
    const { id } = this;

    //Patch
    return NotificationApi
      .patch(id, 'isRead', isRead)
      .then(data => this.isRead = data.isRead);
  }
}
